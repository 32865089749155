import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";

type UtilStoreState = {
  isDocusignResponsePending: boolean;
};

export const useUtilStore = defineStore(ModuleType.Util, {
  state: (): UtilStoreState => ({
    isDocusignResponsePending: false,
  }),
  getters: {
    getIsDocusignResponsePending(state): boolean {
      return state.isDocusignResponsePending;
    },
  },
  actions: {
    SetDocusignResponsePending(isPending: boolean): void {
      this.isDocusignResponsePending = isPending;
    },
  },
});
