<template>
  <div class="cgu-text">
    <h3 class="header" data-test="title">
      Conditions générales d'utilisation et de confidentialité
    </h3>
    <ol>
      <li>
        <div class="section">Définitions</div>
        <p>
          Les termes et expressions identifiés par une majuscule ont la
          signification indiquée ci-après :
        </p>
        <ul>
          <li>
            <strong>"NEOMI"</strong> désigne la société NEOMI SAS qui met la
            Plateforme à disposition de la Société ;
          </li>
          <li>
            <strong>"Conditions Générales d'Utilisation"</strong> ou
            <strong>"CGU"</strong> désigne le présent document ;
          </li>
          <li>
            <strong>"Identifiant"</strong> désigne la conjonction de
            l’identifiant (login) et du mot de passe d’un Utilisateur,
            nécessaire pour pouvoir accéder à la Plateforme ;
          </li>
          <li>
            <strong>"Plateforme"</strong> désigne la solution innovante
            dématérialisant et simplifiant les échanges entre banques et
            promoteurs immobiliers dans le cadre d'une opération de promotion
            immobilière, mise à disposition à travers une plateforme
            informatique en mode SaaS ;
          </li>
          <li>
            <strong>"Services"</strong> désigne les services fournis par NEOMI à
            la Société, incluant notamment la mise à disposition à distance de
            la Plateforme, l’hébergement de la Plateforme, le stockage de
            données, le service de signature électronique, l’assistance
            technique et la maintenance corrective de la Plateforme ;
          </li>
          <li>
            <strong>"Société"</strong> désigne la banque ou le promoteur
            immobilier, qui a conclu avec NEOMI un contrat lui donnant accès à
            la Plateforme et aux Services ;
          </li>
          <li>
            <strong>"Utilisateur(s)"</strong> désigne toute personne physique
            qui est un employé (permanent ou non-permanent), stagiaire,
            intérimaire ou sous-traitant de la Société, habilitée par la Société
            à accéder à et/ou utiliser tout ou partie de la Plateforme et
            disposant à cet effet d'Identifiants.
          </li>
        </ul>
      </li>
      <li>
        <div class="section">Objet des CGU</div>

        <p>
          Les CGU ont pour objet de définir les conditions dans lesquelles
          l'Utilisateur peut utiliser la Plateforme et les Services.
          L'Utilisateur prend connaissance et accepte les CGU à sa première
          connexion.
        </p>
        <p>
          NEOMI pourra être amené à modifier les présentes CGU pour toutes
          raisons, notamment afin de se conformer à la réglementation en
          vigueur. L'Utilisateur sera informé de toute modification apportée aux
          CGU par une notification lors de sa connexion à la Plateforme. Les CGU
          telles que modifiées entrerons en vigueur 2 jours ouvrés après la
          notification. La poursuite d'utilisation de la Plateforme vaut
          acceptation des CGU modifiées. La date de dernière mise à jour des CGU
          est indiquée en fin de document.
        </p>
      </li>
      <li>
        <div class="section">Accès à la Plateforme et aux services</div>

        <p>
          La Plateforme est accessible à l'adresse suivante :<br />
          <a href="https://app.neomi.immo">https://app.neomi.immo</a>
        </p>
        <ol>
          <li>
            <div class="sub-section">
              Création d'un accès individuel à la Plateforme
            </div>

            <p>
              NEOMI recommande la mise en place d’un Single Sign On pour
              sécuriser et faciliter l’authentification d’un utilisateur sur la
              plateforme. Dans le cas où cette solution n’est pas applicable,
              lors de la création de l'accès individuel, un mot de passe
              temporaire est envoyé par email à l'Utilisateur. Celui-ci doit
              changer ce mot de passe temporaire à sa première connexion selon
              les contraintes de complexité suivantes :
            </p>

            <ul>
              <li>Longueur minimale de 12 caractères ;</li>
              <li>Au moins 1 caractère spécial ;</li>
              <li>Au moins 1 majuscule ;</li>
              <li>Au moins 1 minuscule ;</li>
              <li>Au moins 1 chiffre ;</li>
              <li>
                Ne doit pas correspondre aux 3 derniers mots de passe utilisés ;
              </li>
            </ul>
            <p>
              Ce mot de passe est doit être modifié par l'Utilisateur deux (2)
              mois après sa dernière mise à jour.
            </p>
          </li>
          <li>
            <div class="sub-section">
              Accès aux services d'assistance technique et de maintenance
              corrective
            </div>

            <p>
              Le service d'assistance technique et de maintenance corrective
              peut être contacté à l’adresse email suivante :
              <a href="mailto:support@neomi.immo">support@neomi.immo</a>
            </p>
            <p>
              Le service est disponible du lundi au vendredi de 9h30 à 17h30
              (heure de Paris).
            </p>
            <p>
              Toute demande d'intervention devra contenir les éléments
              nécessaires à l’analyse et la résolution de l’anomalie notamment :
            </p>
            <ul>
              <li>La dénomination de la Société ;</li>
              <li>La description de l’anomalie ;</li>
              <li>
                La période d’observation de l’anomalie et ses circonstances.
              </li>
            </ul>
            <p>
              A réception de la demande, le service d'assistance technique et de
              maintenance corrective confirmera la prise en charge de la demande
              par retour d’email.
            </p>
            <p>
              Le service d'assistance technique et de maintenance corrective
              peut être amené à demander des informations complémentaires pour
              la bonne réalisation du diagnostic.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <div class="section">Confidentialité des identifiants</div>

        <p>
          Les Identifiants sont réservés à l'usage exclusif de l'Utilisateur
          auquel ils sont attribués. L'Utilisateur s'engage à préserver la
          confidentialité de ses Identifiants et à ne les transmettre en aucun
          cas à des tiers.
        </p>
        <p>
          L'Utilisateur est présumé responsable de toute action résultant d'une
          connexion à la Plateforme à partir de ses Identifiants. NEOMI ne
          pourra en aucun cas être tenu responsable des conséquences d'une
          utilisation frauduleuse des Identifiants.
        </p>
        <p>
          En cas de perte ou d'utilisation non autorisée des Identifiants,
          l'Utilisateur pourra en faire la réinitialisation directement via
          l'écran de connexion de la plateforme.
        </p>
        <p>
          Dans tous les cas de vol ou d’utilisation non autorisée de son
          Identifiant, l'Utilisateur en avertira NEOMI dans les meilleurs délais
          afin que celui-ci puisse prendre toute mesure adaptée pour assurer la
          sécurité des données de l'Utilisateur et de la Société.
        </p>
      </li>
      <li>
        <div class="section">Téléchargement de documents et stockage</div>

        <p>
          L'Utilisateur s’engage à n’utiliser et ne charger sur la Plateforme
          que des documents dans des formats standards.
        </p>
        <p>
          L'Utilisateur s’engage à faire un usage raisonnable des possibilités
          de stockage mises à sa disposition dans le cadre de la Plateforme et
          des Services, et de ne stocker que des données à caractère
          professionnel et entrant dans le cadre de l’activité de la Société sur
          la Plateforme.
        </p>
      </li>
      <li>
        <div class="section">Signature des documents</div>

        <p>
          La Plateforme permet de signer les documents soit manuellement, soit
          de manière électronique. A ce titre, la Plateforme met à disposition
          de la Société le service de signature électronique DocuSign.
        </p>
        <p>
          Les Utilisateurs ont la possibilité de choisir, au cas par cas, de
          signer les documents manuellement ou électroniquement.
        </p>
        <p>
          Seuls les Utilisateurs autorisés par la Société peuvent accéder à ce
          service de signature électronique.
        </p>
        <p>
          Les étapes successives sont rappelées sur les différents écrans de la
          Plateforme parcourus par l'Utilisateur. Afin d'utiliser la signature
          électronique, l'Utilisateur coche une case exprimant son acceptation
          du principe de la signature électronique. Une authentification
          complémentaire est alors mise en place. Un code temporaire est envoyé
          à l'Utilisateur au numéro de téléphone mobile renseigné par la
          Société. Ce code temporaire devra être saisi par ce dernier sur la
          Plateforme avant de finaliser l’opération de signature électronique.
        </p>
        <p>
          L'Utilisateur ne signe un document qu’à l’étape finale, au moyen d’un
          clic sur le bouton « SIGNER » générant ainsi une signature
          électronique et le document original sous forme électronique signé. A
          chaque étape précédant la signature du document, l'Utilisateur dispose
          toujours de la possibilité d’abandonner la procédure en cliquant sur
          le bouton prévu à cet effet.
        </p>
      </li>
      <li>
        <div class="section">Propriété intellectuelle</div>

        <p>
          L'architecture de la Plateforme, les marques, noms de domaine, les
          bases de données, les logiciels, les contenus et tous les autres
          éléments composant la Plateforme, sans que cette liste ne soit
          exhaustive, sont la propriété exclusive de NEOMI ou la propriété de
          tiers ayant accordé une licence à celui-ci. Les présentes CGU
          n’emportent aucune cession de droits de propriété intellectuelle
          attachés à la Plateforme ou à ses éléments au bénéfice de la Société
          ou des Utilisateurs.
        </p>
        <p>
          L'Utilisateur s'interdit tout agissement susceptible de porter
          atteinte, directement ou indirectement, aux droits de propriété
          intellectuelle de NEOMI ou de ses donneurs de licence. Ainsi,
          l'utilisation, la reproduction, la modification, la transmission, la
          commercialisation d'éléments de la Plateforme ou la création d'œuvres
          dérivées sur la base d'éléments de la Plateforme sans l'accord écrit
          préalable de NEOMI sont strictement interdites et peuvent être
          sanctionnées au titre de la contrefaçon.
        </p>
      </li>
      <li>
        <div class="section">Utilisation de la plateforme</div>
        <p>
          L'Utilisateur s'engage à respecter toutes les lois et règlementations
          applicables à son utilisation de la Plateforme et des Services et
          notamment :
        </p>
        <ul>
          <li>
            l'Utilisateur s'engage à respecter la règlementation applicable en
            matière de propriété intellectuelle, et à ne pas violer les droits
            de propriété intellectuelle de tiers ;
          </li>
          <li>
            l'Utilisateur s'engage également à ne pas utiliser la Plateforme
            et/ou les Services d'une manière qui enfreindrait l'ordre public ou
            les bonnes mœurs, la règlementation applicable en matière de
            protection des données personnelles, le secret des correspondances,
            le droit à l'image et au respect de la vie privée d'autrui, ou plus
            généralement tous droits de tiers ;
          </li>
          <li>
            l'Utilisateur s'interdit toute utilisation frauduleuse, abusive, ou
            excessive de la Plateforme et/ou des Services ayant notamment pour
            effet de perturber ou d'altérer le fonctionnement de la Plateforme
            ou des Services ou de supprimer, endommager, modifier ou accéder
            illégalement à des données qui y sont stockées.
          </li>
        </ul>
        <p>
          L'Utilisateur est seul responsable de tout préjudice direct ou
          indirect, matériel ou immatériel causé à des tiers du fait de son
          utilisation de la Plateforme et du Service.
        </p>
      </li>
      <li>
        <div class="section">
          Surveillance de la plateforme et suspension des accès
        </div>

        <p>
          L'Utilisateur est seul responsable de tout préjudice direct ou
          indirect, matériel ou immatériel causé à des tiers du fait de son
          utilisation de la Plateforme et du Service.
        </p>
        <p>
          En cas de violation des CGU et/ou de la réglementation applicable,
          NEOMI se réserve le droit de suspendre ou fermer l'accès de
          l'Utilisateur et de la Société, à la Plateforme et aux Services,
          temporairement ou de manière définitive.
        </p>
      </li>
      <li>
        <div class="section">
          Traitement des données personnelles des Utilisateurs
        </div>

        <p>
          Lors de l'utilisation de la Plateforme et des Services par
          l'Utilisateur et aux fins de mettre la Plateforme à disposition de la
          Société et de fournir les Services, NEOMI peut être amené à traiter,
          en tant que responsable de traitement, certaines données personnelles
          concernant les Utilisateurs et notamment leurs données de connexion
          (telles que l'adresse IP) ainsi que les données relatives au trafic
          générées par leur utilisation de la Plateforme et des Services à des
          fins de sécurité, d'analyse des activités sur la Plateforme et
          d'amélioration de la Plateforme.
        </p>
        <p>
          Ce traitement de données personnelles est fondé sur l'intérêt légitime
          de NEOMI à assurer la sécurité et à améliorer sa Plateforme et ses
          Services.
        </p>
        <p>NEOMI conserve ces données pendant une période maximale de 5 ans.</p>
        <p>
          NEOMI pourra être amené à communiquer ces données à ses sous-traitants
          et aux autorités publiques et/ou judiciaires, à leur demande, aux fins
          de prévention, de recherche, de constatation et de poursuite des
          infractions pénales.
        </p>
        <p>
          Conformément à la règlementation applicable en matière de protection
          des données, les Utilisateurs sont informés qu'ils disposent d'un
          droit d'accès, de rectification, d’effacement et de portabilité de
          leurs données ainsi qu'un droit de limitation et d'opposition au
          traitement de leurs données. Ils peuvent également donner des
          instructions quant au sort de leur données personnelles après leur
          décès. Ces droits peuvent être exercés en écrivant à
          <a href="mailto:support@neomi.immo">support@neomi.immo</a>. Les
          Utilisateurs peuvent également saisir la CNIL (
          <a href="https://www.cnil.fr/fr/plaintes"
            >https://www.cnil.fr/fr/plaintes</a
          >) de toute réclamation relative au traitement de leurs données par
          NEOMI.
        </p>
      </li>
    </ol>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "ConditionGeneraleUtilisationModalText",
});
</script>

<style lang="scss" scoped>
.cgu-text {
  display: flex;
  flex-direction: column;
  text-align: left;

  .header {
    margin: 76px auto 64px;
  }

  .section {
    margin: 16px 0;
    text-transform: uppercase;
    font-weight: 600;
  }

  .sub-section {
    margin: 16px 0;
    font-weight: 600;
  }

  ul {
    list-style-type: disc;
    margin-left: 16px;
    padding-left: 0;
  }

  li {
    margin-bottom: 16px;
  }
}
</style>
