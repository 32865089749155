import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { AnnuaireUser } from "@domain/dto/annuaireUser";
import { SureteAndDocuments } from "@domain/dto/sureteAndDocuments";
import { SureteCreationRequest } from "@domain/dto/sureteCreationRequest";
import { SureteSignataireCreateRequest } from "@domain/dto/sureteSignataireCreateRequest";
import { SureteUpdateRequest } from "@domain/dto/sureteUpdateRequest";
import { WorkflowRollbackType } from "@/domain/enum/workflowRollbackType";
import { Surete } from "@/domain/surete";
import { SureteDocument } from "@domain/dto/sureteDocument";
import { SureteHeavy } from "@domain/dto/sureteHeavy";
import { PendingDocument } from "@domain/dto/pendingDocument";
import { Demande } from "@/domain/demande";
import { DocusignConfigurationError } from "@domain/enum/docusignConfigurationError";

export default {
  create(
    surete: SureteCreationRequest,
    idSocieteSupport: string
  ): Promise<ApiResponse<Surete>> {
    return apiRequestThrottle.post(
      `/societes-support/${idSocieteSupport}/suretes`,
      surete,
      {
        validateStatus(status: number) {
          return status === 201;
        },
      }
    );
  },

  update(
    idSocieteSupport: string,
    idSurete: string,
    sureteUpdateRequest: SureteUpdateRequest
  ): Promise<ApiResponse<SureteAndDocuments>> {
    return apiRequestThrottle.patch(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}`,
      sureteUpdateRequest,
      {
        validateStatus(status: number) {
          return status === 200;
        },
      }
    );
  },

  delete(
    idSocieteSupport: string,
    idSurete: string
  ): Promise<ApiResponse<string[]>> {
    return apiRequest.delete(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}`,
      {
        validateStatus(status: number) {
          return status === 200;
        },
      }
    );
  },

  fetchSocieteSupportSurete(
    idSocieteSupport: string,
    idSurete: string
  ): Promise<ApiResponse<SureteHeavy>> {
    return apiRequest.get(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}`,
      {
        validateStatus(status: number) {
          return status === 200;
        },
      }
    );
  },

  fetchSocieteSupportSuretes(
    idSocieteSupport: string
  ): Promise<ApiResponse<Surete[]>> {
    return apiRequest.get(`/societes-support/${idSocieteSupport}/suretes`, {
      validateStatus(status: number) {
        return status === 200;
      },
    });
  },

  fetchSureteDocument(
    idSocieteSupport: string,
    idSurete: string
  ): Promise<ApiResponse<SureteDocument>> {
    return apiRequest.get(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}/surete`,
      {
        validateStatus(status: number) {
          return status === 200;
        },
      }
    );
  },

  fetchSureteSignedSureteDocument(
    idSocieteSupport: string,
    idSurete: string
  ): Promise<ApiResponse<SureteDocument>> {
    return apiRequest.get(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}/signedSurete`,
      {
        validateStatus(status: number) {
          return status === 200;
        },
      }
    );
  },

  fetchSureteCertificateDocument(
    idSocieteSupport: string,
    idSurete: string
  ): Promise<ApiResponse<SureteDocument>> {
    return apiRequest.get(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}/certificate`,
      {
        validateStatus(status: number) {
          return status === 200;
        },
      }
    );
  },

  uploadSureteDocument(
    idSocieteSupport: string,
    idSurete: string,
    formData: FormData
  ): Promise<ApiResponse<PendingDocument[]>> {
    return apiRequestThrottle.post(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}/surete`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        validateStatus(status: number) {
          return status === 200;
        },
      }
    );
  },

  downloadDocument(
    idSocieteSupport: string,
    idSurete: string,
    idDocument: string
  ): Promise<ApiResponse<BlobPart>> {
    return apiRequest.get(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}/documents/${idDocument}/file`,
      {
        responseType: "blob",
        validateStatus(status: number) {
          return status === 200;
        },
      }
    );
  },

  updateSureteSignatureConfiguration(
    idSocieteSupport: string,
    idSurete: string,
    sureteSignataireCreateRequest: SureteSignataireCreateRequest
  ): Promise<ApiResponse<SureteHeavy | null>> {
    return apiRequestThrottle.patch(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}/signataires`,
      sureteSignataireCreateRequest,
      {
        validateStatus: function (status) {
          return status === 201 || status === 409;
        },
      }
    );
  },

  initializeSureteSignatureProcess(
    idSocieteSupport: string,
    idSurete: string
  ): Promise<ApiResponse<SureteHeavy | DocusignConfigurationError[]>> {
    return apiRequest.post(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}/signature/initialize`,
      {},
      {
        validateStatus(status: number) {
          return [200, 400, 409].includes(status);
        },
      }
    );
  },

  startSureteSignatureCeremonie(
    idSocieteSupport: string,
    idSurete: string
  ): Promise<ApiResponse<string>> {
    return apiRequest.post(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}/signature/start-ceremonie`,
      {},
      {
        validateStatus(status: number) {
          return status === 200;
        },
      }
    );
  },

  rollbackWorkflow(
    idSocieteSupport: string,
    idSurete: string,
    rollbackType: WorkflowRollbackType
  ): Promise<ApiResponse<string>> {
    return apiRequestThrottle.patch(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}/workflow/rollback`,
      { rollbackType },
      {
        validateStatus(status: number) {
          return status === 200;
        },
      }
    );
  },

  fetchSureteMembers(
    idSocieteSupport: string,
    idSurete: string
  ): Promise<ApiResponse<AnnuaireUser[]>> {
    return apiRequest.get(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}/users`,
      {
        validateStatus(status: number) {
          return status === 200;
        },
      }
    );
  },

  remindSignatureToRecipient(
    idSocieteSupport: string,
    idSurete: string,
    idRecipient: number
  ): Promise<ApiResponse<Demande>> {
    return apiRequestThrottle.put(
      `/societes-support/${idSocieteSupport}/suretes/${idSurete}/signature/remind/${idRecipient}`,
      {},
      {
        validateStatus: (status) => [201, 400, 403, 409].includes(status),
      }
    );
  },
};
