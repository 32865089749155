import { apiRequest, ApiResponse } from "@/apiRequest";
import type { SignataireExterneUpdateRequest } from "@domain/dto/signataireExterneUpdateRequest";
import { SureteSignataireExterne } from "@domain/dto/sureteSignataireExterne";

export default {
  updateSignataireExterneByOperationId(
    idOperation: string,
    idSurete: string,
    idSignataire: string,
    payload: SignataireExterneUpdateRequest
  ): Promise<ApiResponse<SureteSignataireExterne>> {
    return apiRequest.patch(
      `/operations/${idOperation}/surete/${idSurete}/signataires/externe/${idSignataire}`,
      payload,
      {
        validateStatus: function (status) {
          return status === 201 || status === 409;
        },
      }
    );
  },
  updateSignataireExterneBySocieteSupportId(
    idSocieteSupport: string,
    idSurete: string,
    idSignataire: string,
    payload: SignataireExterneUpdateRequest
  ): Promise<ApiResponse<SureteSignataireExterne>> {
    return apiRequest.patch(
      `/societes-support/${idSocieteSupport}/surete/${idSurete}/signataires/externe/${idSignataire}`,
      payload,
      {
        validateStatus: function (status) {
          return status === 201 || status === 409;
        },
      }
    );
  },
};
