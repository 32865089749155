import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import { Surete } from "@/domain/surete";
import { SureteDocument } from "@domain/dto/sureteDocument";
import { AnnuaireUser } from "@domain/dto/annuaireUser";
import {
  buildSureteNewSignataireBanqueCountCreateRequest,
  buildSureteNewSignataireExterneCountCreateRequest,
  buildSureteNewSignatairePromoteurCountCreateRequest,
  createSurete,
} from "@/utils/sureteUtils";
import { createSureteDocument } from "@/utils/documentUtils";
import { SignataireHeavy } from "@domain/dto/signataireHeavy";
import orderBy from "lodash/orderBy";
import { SortingOrder } from "@domain/enum/sortingOrder";
import sureteApi from "@/api/sureteApi";
import { SureteCreationRequest } from "@domain/dto/sureteCreationRequest";
import { toastError, toastSuccess } from "@/utils/toastUtils";
import {
  computeExceedFileBatchSizeError,
  isFileBatchUploadable,
} from "@/utils/uploadFileUtils";
import { SignMethod } from "@domain/enum/signMethod";
import { SureteUpdateRequest } from "@domain/dto/sureteUpdateRequest";
import { SureteStep } from "@domain/enum/sureteStep";
import { downloadClickLink } from "@/utils/downloadFileUtils";
import useKeycloakStore from "@/store/keycloakModule.pinia";
import { ValidationStatus } from "@domain/enum/validationStatus";
import { WorkflowRollbackType } from "@/domain/enum/workflowRollbackType";
import { useSocieteSupportStore } from "@/store/societeSupportModule.pinia";
import { SureteHeavy } from "@domain/dto/sureteHeavy";
import { SureteSignataireCreateRequest } from "@domain/dto/sureteSignataireCreateRequest";
import { PendingDocument } from "@domain/dto/pendingDocument";
import { formatDocusignConfigurationErrorList } from "@/utils/signatureUtils";
import { isDocusignConfigurationErrorList } from "@domain/utils/docusignUtils";
import { SignataireExterneUpdateRequest } from "@domain/dto/signataireExterneUpdateRequest";
import { useOperationStore } from "./operationModule.pinia";
import sureteSignataireExterneApi from "@/api/sureteSignataireExterneApi";
import { AccessType } from "@/domain/enum/accessType";
import { SocieteSupport } from "@/domain/societeSupport";
import { useRouter } from "vue-router";

type SureteStateType = {
  surete: SureteHeavy;
  societeSupportSuretes: Surete[];
  sureteDocument: SureteDocument;
  sureteSignedSureteDocument: SureteDocument;
  sureteCertificateDocument: SureteDocument;
  sureteUsers: AnnuaireUser[];
};
export const useSureteStore = defineStore(ModuleType.Surete, {
  state: (): SureteStateType => ({
    surete: createSurete(),
    societeSupportSuretes: [],
    sureteDocument: createSureteDocument(),
    sureteSignedSureteDocument: createSureteDocument(),
    sureteCertificateDocument: createSureteDocument(),
    sureteUsers: [],
  }),
  getters: {
    getSurete(state): SureteHeavy {
      return state.surete;
    },

    getSocieteSupportSuretes(state): Surete[] {
      return state.societeSupportSuretes;
    },

    getHasSureteEnvelope(state): boolean {
      return !!state.surete.idEnvelope;
    },

    getSureteDocument(state): SureteDocument {
      return state.sureteDocument;
    },

    getSureteSignedSureteDocument(state): SureteDocument {
      return state.sureteSignedSureteDocument;
    },

    getSureteCertificateDocument(state): SureteDocument {
      return state.sureteCertificateDocument;
    },

    getSureteSignaturePromoteurCount(state): number {
      return state.surete.signatairePromoteurCount;
    },

    getSureteSignatureExterneCount(state): number {
      return state.surete.signataireExterneCount;
    },

    getSureteSignatureBanqueCount(state): number {
      return state.surete.signataireBanqueCount;
    },

    getSureteSignataireList(state): SignataireHeavy[] {
      return state.surete.signataireList;
    },

    hasSurete(state): boolean {
      return state.surete.id !== "";
    },

    hasSureteDocument(state): boolean {
      return !!state.sureteDocument.id;
    },

    getSureteSignatureConfigurationReady(state): boolean {
      return (
        state.surete.signataireList?.length +
          state.surete.signataireExterneList?.length ===
        state.surete.signatairePromoteurCount +
          state.surete.signataireBanqueCount +
          state.surete.signataireExterneCount
      );
    },

    getSureteMembers(state): AnnuaireUser[] {
      return state.sureteUsers;
    },
  },
  actions: {
    SetSureteUsers(sureteUsers: AnnuaireUser[]): void {
      this.sureteUsers = sureteUsers;
    },

    ResetSureteUsers(): void {
      this.sureteUsers = [];
    },

    SetSurete(surete: SureteHeavy): void {
      this.surete = surete;
    },

    SetSocieteSupportSuretes(societeSupportSuretes: Surete[]): void {
      this.societeSupportSuretes = orderBy(
        societeSupportSuretes,
        "createdAt",
        SortingOrder.DESCENDING
      );
    },

    SetSureteDocument(sureteActeDocument: SureteDocument): void {
      this.sureteDocument = sureteActeDocument;
    },

    SetSureteSignedSureteDocument(
      sureteSignedActeDocument: SureteDocument
    ): void {
      this.sureteSignedSureteDocument = sureteSignedActeDocument;
    },

    SetSureteCertificateDocument(
      sureteCertificateDocument: SureteDocument
    ): void {
      this.sureteCertificateDocument = sureteCertificateDocument;
    },

    ResetSureteDocument(): void {
      this.sureteDocument = createSureteDocument();
    },

    ResetSureteSignedSureteDocument(): void {
      this.sureteSignedSureteDocument = createSureteDocument();
    },

    ResetSureteCertificateDocument(): void {
      this.sureteCertificateDocument = createSureteDocument();
    },

    ResetSocieteSupportSurete(): void {
      this.surete = createSurete();
    },

    // Actions
    async fetchSocieteSupportSurete(payload: {
      idSocieteSupport: string;
      idSurete: string;
    }): Promise<void> {
      try {
        const surete = await sureteApi.fetchSocieteSupportSurete(
          payload.idSocieteSupport,
          payload.idSurete
        );
        this.SetSurete(surete.data);
      } catch (error) {}
    },

    async fetchSocieteSupportSuretes(idSocieteSupport: string): Promise<void> {
      try {
        const societeSupportSuretes =
          await sureteApi.fetchSocieteSupportSuretes(idSocieteSupport);

        this.SetSocieteSupportSuretes(societeSupportSuretes.data);
      } catch (error) {}
    },

    async createSurete(payload: {
      idSocieteSupport: string;
      sureteCreationRequest: SureteCreationRequest;
    }): Promise<Surete | null> {
      const toastSuccessSentence = "Sûreté créée avec succès";
      const toastErrorSentence = "Échec lors de la création de la sûreté";
      return sureteApi
        .create(payload.sureteCreationRequest, payload.idSocieteSupport)
        .then((result) => {
          if (result.status === 201) {
            toastSuccess(toastSuccessSentence);
            this.fetchSocieteSupportSuretes(payload.idSocieteSupport);
            return result.data;
          } else {
            toastError(toastErrorSentence);
            return null;
          }
        })
        .catch(() => {
          toastError(toastErrorSentence);
          return null;
        });
    },

    async createOperationSurete(payload: {
      idSocieteSupport: string;
      sureteCreationRequest: SureteCreationRequest;
    }): Promise<Surete | null> {
      const toastSuccessSentence = "Sûreté créée avec succès";
      const toastErrorSentence = "Échec lors de la création de la sûreté";
      try {
        const result = await sureteApi.create(
          payload.sureteCreationRequest,
          payload.idSocieteSupport
        );

        if (result.status === 201) {
          toastSuccess(toastSuccessSentence);
          return result.data;
        } else {
          toastError(toastErrorSentence);
          return null;
        }
      } catch (err) {
        toastError(toastErrorSentence);
        return null;
      }
    },

    async fetchSureteDocument(): Promise<void> {
      try {
        const idSocieteSupport: string =
          useSocieteSupportStore().getSocieteSupport.id;
        const idSurete = this.getSurete.id;
        this.ResetSureteDocument();
        const result = await sureteApi.fetchSureteDocument(
          idSocieteSupport,
          idSurete
        );
        this.SetSureteDocument(result.data);
      } catch (error) {}
    },

    async fetchSureteSignedSureteDocument(): Promise<void> {
      try {
        const idSocieteSupport: string =
          useSocieteSupportStore().getSocieteSupport.id;
        const idSurete = this.getSurete.id;
        this.ResetSureteSignedSureteDocument();
        const result = await sureteApi.fetchSureteSignedSureteDocument(
          idSocieteSupport,
          idSurete
        );
        if (result.data.id) {
          this.SetSureteSignedSureteDocument(result.data);
        }
      } catch (error) {}
    },

    async fetchSureteCertificateDocument(): Promise<void> {
      try {
        const idSocieteSupport: string =
          useSocieteSupportStore().getSocieteSupport.id;
        const idSurete = this.getSurete.id;
        this.ResetSureteCertificateDocument();
        const result = await sureteApi.fetchSureteCertificateDocument(
          idSocieteSupport,
          idSurete
        );
        if (result.data.id) {
          this.SetSureteCertificateDocument(result.data);
        }
      } catch (error) {}
    },

    async uploadSurete(document: File): Promise<PendingDocument | undefined> {
      if (!isFileBatchUploadable([{ file: document }])) {
        toastError(computeExceedFileBatchSizeError([{ file: document }]));
        return Promise.reject();
      }

      try {
        const idSocieteSupport: string =
          useSocieteSupportStore().getSocieteSupport.id;
        const idSurete = this.getSurete.id;

        const formData = new FormData();
        formData.append("project", document);

        const result = await sureteApi.uploadSureteDocument(
          idSocieteSupport,
          idSurete,
          formData
        );
        if (result.status === 201) {
          await this.fetchSocieteSupportSurete({
            idSocieteSupport,
            idSurete,
          });
        }

        return result.data.shift();
      } catch (error) {}
    },

    async updateSureteName(name: string) {
      const idSocieteSupport: string =
        useSocieteSupportStore().getSocieteSupport.id;
      const idSurete = this.getSurete.id;

      const sureteUpdateRequest: SureteUpdateRequest = {
        name,
      };
      return sureteApi
        .update(idSocieteSupport, idSurete, sureteUpdateRequest)
        .then((response) => {
          toastSuccess("Le nom de la sûreté a été mis à jour");
          this.SetSurete(response.data.surete);
        })
        .catch(() => {
          toastError(
            "Une erreur s'est produite lors de la mise à jour du nom de la sûreté"
          );
        });
    },

    async updateSureteAssociationToOperation(
      operationIdListToAdd: string[],
      operationIdListToDelete: string[],
      idSurete?: string,
      idSocieteSupport?: string
    ) {
      if (!idSocieteSupport) {
        idSocieteSupport = useSocieteSupportStore().getSocieteSupport.id;
      }
      if (!idSurete) {
        idSurete = this.getSurete.id;
      }

      const sureteUpdateRequest: SureteUpdateRequest = {
        operationIdListToAdd,
        operationIdListToDelete,
      };

      return sureteApi
        .update(idSocieteSupport, idSurete, sureteUpdateRequest)
        .then((response) => {
          toastSuccess("Les opérations de la sûreté ont été mises à jour");
          this.SetSurete(response.data.surete);
        })
        .catch(() => {
          toastError(
            "Une erreur s'est produite lors de la mise à jour des opérations de la sûreté"
          );
        });
    },

    async updateSureteSignMethod(signMethod: SignMethod): Promise<void> {
      const idSocieteSupport: string =
        useSocieteSupportStore().getSocieteSupport.id;
      const idSurete = this.getSurete.id;

      const sureteUpdateRequest: SureteUpdateRequest = {
        signMethod: signMethod,
      };
      return sureteApi
        .update(idSocieteSupport, idSurete, sureteUpdateRequest)
        .then((response) => {
          toastSuccess("Votre choix de signature a bien été enregistré");
          this.SetSurete(response.data.surete);
        })
        .catch(() => {
          toastError("Votre choix de signature n'a pas pu être enregistré");
        });
    },

    async manuallySignSurete(): Promise<void> {
      const idSocieteSupport: string =
        useSocieteSupportStore().getSocieteSupport.id;
      const idSurete = this.getSurete.id;

      const demandeUpdateRequest: SureteUpdateRequest = {
        step: SureteStep.COMPLETED,
      };
      return sureteApi
        .update(idSocieteSupport, idSurete, demandeUpdateRequest)
        .then((response) => {
          toastSuccess("Votre signature a bien été enregistrée");
          this.SetSurete(response.data.surete);
        })
        .catch(() => {
          toastError("Votre signature n'a pas pu être enregistrée");
        });
    },

    async downloadSureteDocument(payload: {
      idDocument: string;
      idSurete: string;
      idSocieteSupport: string;
      name: string;
      type: string;
    }): Promise<void> {
      await sureteApi
        .downloadDocument(
          payload.idSocieteSupport,
          payload.idSurete,
          payload.idDocument
        )
        .then((response) => {
          const documentStream: BlobPart = response.data;
          downloadClickLink(documentStream, {
            name: payload.name,
            type: payload.type,
          });
        })
        .catch(() => {
          toastError("Erreur lors du téléchargement du document.");
        });
    },

    async validateSureteProject(): Promise<void> {
      const idSocieteSupport: string =
        useSocieteSupportStore().getSocieteSupport.id;
      const idSurete = this.getSurete.id;

      const keycloakStore = useKeycloakStore();

      const isPromoteur: boolean = keycloakStore.isPromoteur;
      const isBanque: boolean = keycloakStore.isBanque;
      const sureteUpdateRequest: SureteUpdateRequest = {};
      if (isPromoteur) {
        sureteUpdateRequest.statusPromoteur = ValidationStatus.ACCEPTED;
      } else if (isBanque) {
        sureteUpdateRequest.statusBanque = ValidationStatus.ACCEPTED;
      }
      const result = await sureteApi.update(
        idSocieteSupport,
        idSurete,
        sureteUpdateRequest
      );
      if (result.status === 200) {
        this.SetSurete(result.data.surete);
        if (result.data.sureteActeDocument) {
          this.SetSureteDocument(result.data.sureteActeDocument);
        }
        toastSuccess("Projet validé avec succès");
      } else {
        toastError("Le projet n'a pas été validée");
      }
    },

    async cancelSureteValidation(): Promise<void> {
      const idSocieteSupport: string =
        useSocieteSupportStore().getSocieteSupport.id;
      const idSurete = this.getSurete.id;

      const keycloakStore = useKeycloakStore();
      const isPromoteur: boolean = keycloakStore.isPromoteur;
      const isBanque: boolean = keycloakStore.isBanque;
      const sureteUpdateRequest: SureteUpdateRequest = {};
      if (isPromoteur) {
        sureteUpdateRequest.statusPromoteur = ValidationStatus.UNCHECKED;
      } else if (isBanque) {
        sureteUpdateRequest.statusBanque = ValidationStatus.UNCHECKED;
      }
      const result = await sureteApi.update(
        idSocieteSupport,
        idSurete,
        sureteUpdateRequest
      );
      if (result.status === 200) {
        this.SetSurete(result.data.surete);
        toastSuccess("Annulation réalisée avec succès");
      } else {
        toastError("L'annulation n'a pas fonctionné");
      }
    },

    async updateSureteSignataireBanqueCountConfiguration(
      signataireBanqueCount: number
    ): Promise<void> {
      try {
        const idSocieteSupport: string =
          useSocieteSupportStore().getSocieteSupport.id;
        const idSurete = this.getSurete.id;

        const sureteSignataireCreateRequest =
          buildSureteNewSignataireBanqueCountCreateRequest(
            this.surete,
            signataireBanqueCount
          );

        const result = await sureteApi.updateSureteSignatureConfiguration(
          idSocieteSupport,
          idSurete,
          sureteSignataireCreateRequest
        );
        if (result.status === 201 && result.data) {
          this.SetSurete(result.data);
        } else if (result.status === 409) {
          toastError(
            "Un autre utilisateur a déjà sélectionné des signataires pour la signature de cet surete !"
          );
        }
      } catch (error) {
        toastError("Erreur lors de l'ajout du signataire");
      }
    },

    async updateSureteSignatairePromoteurCountConfiguration(
      signatairePromoteurCount: number
    ): Promise<void> {
      try {
        const idSocieteSupport: string =
          useSocieteSupportStore().getSocieteSupport.id;
        const idSurete = this.getSurete.id;

        const sureteSignataireCreateRequest =
          buildSureteNewSignatairePromoteurCountCreateRequest(
            this.surete,
            signatairePromoteurCount
          );
        const result = await sureteApi.updateSureteSignatureConfiguration(
          idSocieteSupport,
          idSurete,
          sureteSignataireCreateRequest
        );

        if (result.status === 201 && result.data) {
          this.SetSurete(result.data);
        } else if (result.status === 409) {
          toastError(
            "Un autre utilisateur a déjà sélectionné des signataires pour la signature de cet surete !"
          );
        }
      } catch (error) {
        toastError("Erreur lors de l'ajout du signataire");
      }
    },
    async updateSureteSignataireExterneCountConfiguration(
      signataireExterneCount: number
    ): Promise<void> {
      try {
        const idSocieteSupport: string =
          useSocieteSupportStore().getSocieteSupport.id;
        const idSurete = this.getSurete.id;

        const sureteSignataireCreateRequest =
          buildSureteNewSignataireExterneCountCreateRequest(
            this.surete,
            signataireExterneCount
          );

        const result = await sureteApi.updateSureteSignatureConfiguration(
          idSocieteSupport,
          idSurete,
          sureteSignataireCreateRequest
        );
        if (result.status === 201 && result.data) {
          this.SetSurete(result.data);
        } else if (result.status === 409) {
          toastError(
            "Un autre utilisateur a déjà sélectionné des signataires pour la signature de cet surete !"
          );
        }
      } catch (error) {
        toastError("Erreur lors de l'ajout du signataire");
      }
    },

    async updateSureteSignatureNewSignataireConfiguration(
      signataireCreateRequest: SureteSignataireCreateRequest
    ): Promise<void> {
      try {
        const idSocieteSupport: string =
          useSocieteSupportStore().getSocieteSupport.id;
        const idSurete = this.getSurete.id;

        const result = await sureteApi.updateSureteSignatureConfiguration(
          idSocieteSupport,
          idSurete,
          signataireCreateRequest
        );
        if (result.status === 201 && result.data) {
          this.SetSurete(result.data);
          toastSuccess("Signataire ajouté avec succès");
        } else if (result.status === 409) {
          toastError(
            "Un autre utilisateur a déjà sélectionné des signataires pour la signature de cet surete !"
          );
        }
      } catch (error) {
        toastError("Erreur lors de l'ajout du signataire");
      }
    },
    async updateSignataireExterne(
      idSignataire: string,
      signataireExterne: SignataireExterneUpdateRequest
    ): Promise<boolean> {
      const idOperation = useOperationStore().getOperation.id;
      const idSocieteSupport = useSocieteSupportStore().getSocieteSupport.id;

      const idSurete = this.getSurete.id;

      let result;
      if (idOperation) {
        result =
          await sureteSignataireExterneApi.updateSignataireExterneByOperationId(
            idOperation,
            idSurete,
            idSignataire,
            signataireExterne
          );
      } else if (idSocieteSupport) {
        result =
          await sureteSignataireExterneApi.updateSignataireExterneBySocieteSupportId(
            idSocieteSupport,
            idSurete,
            idSignataire,
            signataireExterne
          );
      } else {
        toastError("Erreur lors de la modification du signataire");
        return false;
      }

      if (result.status === 201) {
        toastSuccess("Signataire modifié avec succès");
        await this.fetchSurete({ idSurete, idOperation, idSocieteSupport });
        return true;
      } else {
        toastError("Erreur lors de la modification du signataire");
        return false;
      }
    },
    async initializeSureteSignature(): Promise<void> {
      try {
        const idSocieteSupport: string =
          useSocieteSupportStore().getSocieteSupport.id;
        const idSurete = this.getSurete.id;

        const response = await sureteApi.initializeSureteSignatureProcess(
          idSocieteSupport,
          idSurete
        );

        if (
          response.status === 200 &&
          !isDocusignConfigurationErrorList(response.data)
        ) {
          this.SetSurete(response.data);
          return toastSuccess("Enveloppe créée avec succès");
        }
        if (response.status === 409) {
          return toastError(
            "Un autre utilisateur a déjà initialisé la signature"
          );
        }

        if (
          response.status === 400 &&
          isDocusignConfigurationErrorList(response.data)
        ) {
          return toastError(
            formatDocusignConfigurationErrorList(response.data)
          );
        }

        toastError("Erreur lors de la création de l'enveloppe");
      } catch (error) {
        toastError("Erreur lors de la création de l'enveloppe");
      }
    },

    async startSureteSignatureCeremonie(): Promise<void> {
      try {
        const idSocieteSupport: string =
          useSocieteSupportStore().getSocieteSupport.id;
        const idSurete = this.getSurete.id;

        const response = await sureteApi.startSureteSignatureCeremonie(
          idSocieteSupport,
          idSurete
        );
        if (response.status === 200) {
          // @ts-ignore
          window.location = response.data;
        } else {
          toastError("Erreur lors de la création de la vue");
        }
      } catch (error) {
        toastError("Erreur lors de la création de la vue");
      }
    },

    async rollbackSureteWorkflow(
      rollbackType: WorkflowRollbackType
    ): Promise<void> {
      const idSocieteSupport: string =
        useSocieteSupportStore().getSocieteSupport.id;
      const idSurete = this.getSurete.id;

      await sureteApi.rollbackWorkflow(
        idSocieteSupport,
        idSurete,
        rollbackType
      );
      await this.fetchSocieteSupportSurete({
        idSocieteSupport,
        idSurete,
      });
    },

    async fetchSureteMembers(): Promise<void> {
      try {
        const idSocieteSupport: string =
          useSocieteSupportStore().getSocieteSupport.id;
        const idSurete = this.getSurete.id;

        const response = await sureteApi.fetchSureteMembers(
          idSocieteSupport,
          idSurete
        );
        this.SetSureteUsers(response.data);
      } catch (error) {
        toastError("Erreur, il n'y a pas d'utilisateur lié à cette surete");
        this.ResetSureteUsers();
      }
    },

    async deleteSurete(): Promise<void> {
      try {
        const idSocieteSupport: string =
          useSocieteSupportStore().getSocieteSupport.id;
        const idSurete = this.getSurete.id;

        await sureteApi.delete(idSocieteSupport, idSurete);
        toastSuccess("Surete supprimée");
      } catch (error) {
        toastError(
          "Une erreur s'est produite lors de la suppression de la surete"
        );
      }
    },

    async updateSignatureOrdered(value: boolean): Promise<void> {
      const idSocieteSupport: string =
        useSocieteSupportStore().getSocieteSupport.id;
      const idSurete = this.getSurete.id;

      const sureteUpdateRequest: SureteUpdateRequest = {
        isSignatureOrdered: value,
      };
      return sureteApi
        .update(idSocieteSupport, idSurete, sureteUpdateRequest)
        .then((response) => {
          toastSuccess("Mise à jour effectuée avec succès");
          this.SetSurete(response.data.surete);
        })
        .catch(() => {
          toastError(
            "Une erreur s'est produite lors de la mise à jour de la sûreté"
          );
        });
    },

    async remindSignatureToRecipient(
      idSocieteSupport: string,
      idSurete: string,
      idRecipient: number
    ): Promise<void> {
      const response = await sureteApi.remindSignatureToRecipient(
        idSocieteSupport,
        idSurete,
        idRecipient
      );
      if (response.status != 201) {
        toastError("Erreur lors de la relance");
        return;
      }
      toastSuccess("Le mail de relance a bien été renvoyé");
    },
    async fetchSurete(payload: {
      idSurete: string;
      idSocieteSupport: string | string[] | undefined;
      idOperation: string | string[] | undefined;
    }) {
      let { idSurete, idSocieteSupport, idOperation } = payload;
      const societeSupportStore = useSocieteSupportStore();
      const router = useRouter();

      // NOTE: Ce composant est utilisé dans 2 différentes routes: opération et societe support
      // Si on est sur la route opération, on doit récupérer l'id de la societe support à partir de la liste des suretés de l'opération

      if (idOperation) {
        await useSocieteSupportStore().fetchAllSocietesSupport();
        await useOperationStore().fetchOperationSureteList(
          idOperation as string
        );
        const sureteOnOperation =
          useOperationStore().getOperationSureteList.find(
            (surete) => surete.id === idSurete
          );
        if (sureteOnOperation) {
          const societeSupport =
            societeSupportStore.getSearchedSocieteSupportList.find(
              (societeSupport) =>
                societeSupport.id === sureteOnOperation.idSocieteSupport
            );
          if (societeSupport) {
            societeSupportStore.SetSocieteSupport({
              ...societeSupport,
              accessType: AccessType.NORMAL,
            } as SocieteSupport);
            idSocieteSupport = sureteOnOperation.idSocieteSupport;
          }
        }
      }

      if (!idSocieteSupport) {
        router.push({ name: "resourceNotFound" });
        return;
      }

      await this.fetchSocieteSupportSurete({
        idSocieteSupport: idSocieteSupport as string,
        idSurete: idSurete,
      });
    },
  },
});
