export enum DocumentStatus {
  PREPARATION = "PREPARATION",
  PROJET = "PROJET",
  ACTE = "ACTE",
  SIGNED_ACTE = "SIGNED_ACTE",
  CERTIFICATE = "CERTIFICATE",
  SURETE = "SURETE",
  ACHEVEMENT_MAINLEVEE = "ACHEVEMENT_MAINLEVEE",
  SIGNED_SURETE = "SIGNED_SURETE",
}
