class EventBus {
  events: {
    [key: string]: [callbacks: Function[], subscribers: string[]];
  };

  constructor() {
    this.events = {};
  }

  // Add an event listener to the eventBus
  public on(eventName: string, callback: Function, subscriber: string): void {
    this.events[eventName] ||= [[], []];
    this.events[eventName][0].push(callback);
    this.events[eventName][1].push(subscriber);
  }

  // remove event from bus
  public off(eventName: string, subscriber: string): void {
    if (this.events[eventName]) {
      for (let i = 0; i < this.events[eventName].length; i++) {
        if (this.events[eventName][1][i] === subscriber) {
          this.events[eventName][0].splice(i, 1);
          this.events[eventName][1].splice(i, 1);
          break;
        }
      }
    }
  }

  //emit event to all event bus listeners
  public trigger(eventName: string, data?: any): void {
    if (this.events[eventName]) {
      this.events[eventName][0].forEach((callback) => {
        callback(data);
      });
    }
  }
}

export default new EventBus();
