import { GroupTreeLite } from "./groupTreeLite";

export interface SuiviTravauxMassifOperation {
  operation: {
    id: string;
    name: string;
    promoteur: GroupTreeLite | null;
  };
  suivis: SuiviTravauxMassif[];
}

export interface SuiviTravauxMassif {
  etatActuel: SuiviTravauxMassifStep;
  etatPrecedent: SuiviTravauxMassifStep;
  trancheName: string;
  dateAvancement?: Date;
  datePrecedenteAvancement?: Date;
  createdAt?: Date;
  previousEtapeCreatedAt?: Date;
}

export interface SuiviTravauxMassifExcelRow {
  groupePromoteur: string;
  sousGroupePromoteur: string;
  operationName: string;
  trancheName: string;
  etatActuel: string;
  etatPrecedent: string;
  dateAvancement?: Date;
  datePrecedenteAvancement?: Date;
  createdAt?: Date;
  previousEtapeCreatedAt?: Date;
}

export enum SuiviTravauxMassifStep {
  AUCUN = "AUCUN",
  DEMOLITION = "DEMOLITION",
  TERRASSEMENT = "TERRASSEMENT",
  FONDATIONS = "FONDATIONS",
  DALLE = "DALLE",
  RDC = "RDC",
  GROS_OEUVRE_INF_50_POURCENT = "GROS_OEUVRE_INF_50_POURCENT",
  GROS_OEUVRE_SUP_50_POURCENT = "GROS_OEUVRE_SUP_50_POURCENT",
  HORS_EAU = "HORS_EAU",
  HORS_AIR = "HORS_AIR",
  CLOISONS = "CLOISONS",
  REVETEMENT_DE_SOLS = "REVETEMENT_DE_SOLS",
  FINITIONS = "FINITIONS",
  ACHEVEMENT = "ACHEVEMENT",
  LIVRAISON = "LIVRAISON",
  ARRET_DE_CHANTIER = "ARRET_DE_CHANTIER",
  INCONNU = "INCONNU",
}
