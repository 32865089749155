import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { TiersUserUpdateRequest } from "@domain/dto/tiersUserUpdateRequest";
import { DemandeUtilisateurTiers } from "@/domain/demandeUtilisateurTiers";
import { TiersUserCreationRequest } from "@domain/dto/tiersUserCreationRequest";
import { UserCreationStatus } from "@domain/dto/userCreationStatus";

export default {
  updateTiersUser(
    tiersUserUpdateRequest: TiersUserUpdateRequest,
    idOperation: string
  ): Promise<ApiResponse<DemandeUtilisateurTiers[]>> {
    return apiRequest.put(
      `/tiers/${tiersUserUpdateRequest.id}/operations/${idOperation}`,
      tiersUserUpdateRequest
    );
  },
  resendEmailInvitation(tierId: string): Promise<void> {
    return apiRequestThrottle.post(`tiers/${tierId}/send-invitation-mail`);
  },
  addTiers(
    tiersList: TiersUserCreationRequest[],
    idDemandeList: string[],
    idOperation: string
  ): Promise<ApiResponse<UserCreationStatus[]>> {
    return apiRequestThrottle.post(`/tiers/operations/${idOperation}`, {
      tiersList,
      idDemandeList,
    });
  },
};
