import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { Group } from "@domain/dto/group";
import { Member } from "@/domain/member";
import { Demande } from "@/domain/demande";
import { GroupLite } from "@domain/dto/groupLite";

export default {
  fetchGroup(idGroup: string): Promise<ApiResponse<Group>> {
    return apiRequest.get(`/groups/${idGroup}`);
  },

  fetchBanqueGroupList(): Promise<ApiResponse<Group[]>> {
    return apiRequest.get("/banque/groups");
  },

  fetchBanqueGroupLiteList(): Promise<ApiResponse<GroupLite[]>> {
    return apiRequest.get("/banque/groups/pool-formalise-data");
  },
  fetchPromoteurGroupsRelatedToCurrentBanque(): Promise<ApiResponse<Group[]>> {
    return apiRequest.get(`/promoteur/groups/linked-by-operation`);
  },

  fetchMyGroupMembers(idOperation: string): Promise<ApiResponse<Member[]>> {
    return apiRequest.get(`/operations/${idOperation}/members`);
  },

  addBanque(
    demande: Demande,
    idGroup: string,
    demandeUnilateraleBanqueName: string
  ): Promise<ApiResponse<any>> {
    return apiRequestThrottle.post(
      `/operations/${demande.idOperation}/demandes/${demande.id}/groups`,
      {
        demandeUnilateraleBanqueName: demandeUnilateraleBanqueName,
        idBanque: idGroup,
      },
      {
        validateStatus(status) {
          return status === 200 || status === 403;
        },
      }
    );
  },
};
