import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import { Group } from "@domain/dto/group";
import poolApi from "@/api/poolApi";
import orderBy from "lodash/orderBy";
import { toastError, toastSuccess } from "@/utils/toastUtils";
import { PoolOperationHeavy } from "@/domain/dto/poolOperationHeavy";
import { useOperationStore } from "./operationModule.pinia";
import { usePoolAccordCommercialStore } from "@/store/poolAccordCommercialModule.pinia";
import { PoolCreationRequest } from "@domain/dto/poolCreationRequest";
import { PoolOperationParticipant } from "@domain/dto/poolOperationParticipant";
import { PoolGroupAndUserListCreationRequest } from "@domain/dto/poolGroupAndUserListCreationRequest";
import { PoolGroupAndUserListUpdateRequest } from "@domain/dto/poolGroupAndUserListUpdateRequest";
import {
  PoolGroupAndUserListErrorWithUserList,
  PoolGroupResponse,
} from "@domain/dto/poolGroupResponse";
import { PoolGroupUpdateRequest } from "@/domain/dto/poolGroupUpdateRequest";
import { PoolGroupAndUserListError } from "@domain/dto/poolGroupAndUserListError";

type PoolState = {
  poolGroupList: Group[];
  poolOperation: PoolOperationHeavy;
  editingPoolGroupId: string;
};

const poolMessage = {
  errorOperationSharedMessage: "Échec lors de l'ajout des membres du pool",
  successDemandeSharedMessage:
    "Les membres du pool sélectionnés ont désormais accès à la demande",
  errorDemandeSharedMessage:
    "Échec lors de la proposition de la demande au pool",
  successDemandeCancelSharedMessage:
    "La demande a été retiré avec succès du pool",
  errorDemandeCancelSharedMessage:
    "Échec lors du retrait de la demande du pool",
};

export const usePoolStore = defineStore(ModuleType.Pool, {
  state: (): PoolState => ({
    poolGroupList: [],
    poolOperation: {
      idOperation: "",
      allGroupHasArchived: false,
      participantList: [],
      demandeList: [],
    },
    editingPoolGroupId: "",
  }),
  getters: {
    getPoolGroupList(state): Group[] {
      return state.poolGroupList;
    },

    getCurrentOperationGroupPoolList(state): PoolOperationParticipant[] {
      return state.poolOperation.participantList;
    },

    getPoolOperation(state): PoolOperationHeavy {
      return state.poolOperation;
    },

    getEditingPoolGroupId(state): string {
      return state.editingPoolGroupId;
    },

    getEditingPoolGroup(state): Group | undefined {
      return state.poolGroupList.find(
        (group) => group.id === state.editingPoolGroupId
      );
    },
  },
  actions: {
    SetPoolGroupList(poolGroupList: Group[]): void {
      this.poolGroupList = orderBy(poolGroupList, "name");
    },

    SetPoolOperation(poolOperation: PoolOperationHeavy): void {
      this.poolOperation = poolOperation;
    },

    SetEditingPoolGroupId(groupId: string): void {
      this.editingPoolGroupId = groupId;
    },

    async fetchPoolGroupList(): Promise<void> {
      const result = await poolApi.fetchPoolGroupList();
      this.SetPoolGroupList(result.data);
    },

    async fetchOperationPool(idOperation: string): Promise<PoolOperationHeavy> {
      const response = await poolApi.fetchOperationPool(idOperation);
      this.SetPoolOperation(response.data);
      return response.data;
    },

    async shareDemande({
      idOperation,
      idDemande,
      poolGroupList,
    }: {
      idOperation: string;
      idDemande: string;
      poolGroupList?: Group[];
    }): Promise<void> {
      let poolGroupIdList: string[];
      if (poolGroupList) {
        poolGroupIdList = poolGroupList.map((poolGroup) => poolGroup.id);
      } else {
        const currentOperationGroupPoolList =
          this.getCurrentOperationGroupPoolList;
        poolGroupIdList = currentOperationGroupPoolList.map(
          (poolGroup) => poolGroup.participant.id
        );
      }

      const response = await poolApi.shareDemande(
        idOperation,
        idDemande,
        poolGroupIdList
      );

      if (response.status === 200) {
        toastSuccess(poolMessage.successDemandeSharedMessage);
        this.SetPoolOperation(response.data);
      } else {
        toastError(poolMessage.errorDemandeSharedMessage);
      }
    },
    async cancelShareDemande({
      idOperation,
      idDemande,
    }: {
      idOperation: string;
      idDemande: string;
    }): Promise<void> {
      const response = await poolApi.cancelShareDemande(idOperation, idDemande);

      if (response.status === 200) {
        toastSuccess(poolMessage.successDemandeCancelSharedMessage);
        this.SetPoolOperation(response.data);
      } else {
        toastError(poolMessage.errorDemandeCancelSharedMessage);
      }
    },
    async shareOperation(
      poolCreationRequest: PoolCreationRequest
    ): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      const response = await poolApi.shareOperation(
        idOperation,
        poolCreationRequest
      );

      if (response.status === 200) {
        this.SetPoolOperation(response.data);
        await useOperationStore().fetchOperationDetailsById(idOperation);
        await usePoolAccordCommercialStore().fetchPoolAccordCommercialListForOperation(
          idOperation
        );
        toastSuccess("Modification du pool enregistrée");
      } else {
        toastError(poolMessage.errorOperationSharedMessage);
      }
    },

    async createPoolGroupWithUsers(
      poolGroupAndUserListCreationRequest: PoolGroupAndUserListCreationRequest
    ): Promise<{ email: string }[] | null> {
      const response = await poolApi.createPoolGroupWithUsers(
        poolGroupAndUserListCreationRequest
      );

      if (response && response.status === 201) {
        await this.fetchPoolGroupList();
        const data = response.data as PoolGroupResponse;
        return data.members;
      } else {
        const errorResponse =
          response.data as unknown as PoolGroupAndUserListErrorWithUserList;
        switch (errorResponse.errorType) {
          case PoolGroupAndUserListError.POOL_NAME_ALREADY_EXIST:
            toastError("Le nom a déja été attribué à un groupe.");
            break;
          case PoolGroupAndUserListError.ONE_EMAIL_IS_IN_AUTHOR_GROUP:
            toastError(
              `Vous ne pouvez pas renseigner l'email d'un membre de votre groupe. ${errorResponse.emailListInError}`
            );
            break;
          case PoolGroupAndUserListError.ONE_EMAIL_IS_PROMOTEUR:
            toastError(
              `Un promoteur ne peut pas être invité en tant que partenaire bancaire. ${errorResponse.emailListInError}`
            );
            break;
          case PoolGroupAndUserListError.USER_ALREADY_PART_PARTNER_BANQUE:
            toastError(
              `Un des utilisateurs à ajouter est déjà présent dans une de vos banques partenaires. ${errorResponse.emailListInError}`
            );
            break;
        }
        return null;
      }
    },

    async updatePoolGroup(
      idPoolGroup: string,
      poolGroupUpdateRequest: PoolGroupUpdateRequest
    ): Promise<boolean> {
      const result = await poolApi.updatePoolGroup(
        idPoolGroup,
        poolGroupUpdateRequest
      );
      if (result?.status !== 200) {
        toastError("Une erreur est survenue lors de la mise à jour du groupe");
        return false;
      }
      toastSuccess("Le groupe a été mis à jour");
      return true;
    },

    async updatePoolGroupWithUsers(
      idPoolGroup: string,
      poolGroupAndUserListUpdateRequest: PoolGroupAndUserListUpdateRequest
    ): Promise<PoolGroupResponse | null> {
      const response = await poolApi.updatePoolGroupWithUsers(
        idPoolGroup,
        poolGroupAndUserListUpdateRequest
      );

      if (response && response.status === 200) {
        await this.fetchPoolGroupList();
        return response.data as PoolGroupResponse;
      } else {
        const errorResponse =
          response.data as unknown as PoolGroupAndUserListErrorWithUserList;
        switch (errorResponse.errorType) {
          case PoolGroupAndUserListError.ONE_EMAIL_IS_IN_AUTHOR_GROUP:
            toastError(
              `Vous ne pouvez pas renseigner l'email d'un membre de votre groupe. ${errorResponse.emailListInError}`
            );
            break;
          case PoolGroupAndUserListError.ONE_EMAIL_IS_PROMOTEUR:
            toastError(
              `Un promoteur ne peut pas être invité en tant que partenaire bancaire. ${errorResponse.emailListInError}`
            );
            break;
          case PoolGroupAndUserListError.USER_ALREADY_PART_PARTNER_BANQUE:
            toastError(
              `Un des utilisateurs à ajouter est déjà présent dans une de vos banques partenaires. ${errorResponse.emailListInError}`
            );
            break;
          case PoolGroupAndUserListError.UNKNOWN_POOL_GROUP:
          case PoolGroupAndUserListError.UNKNOWN_POOL_GROUP_BANQUE:
            toastError(`Erreur lors de la sélection du groupe à éditer`);
            break;
        }

        return null;
      }
    },
  },
});
