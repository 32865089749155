import { AnnuaireGroupTree } from "@domain/dto/annuaireGroupTree";
import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import annuaireApi from "@/api/annuaireApi";
import { Role } from "@domain/enum/role";

type AnnuaireStateType = {
  groupTreeList: AnnuaireGroupTree[];
  lastRefresh: number;
};

const REFRESH_GROUP_TREE_INTERVAL = 1000 * 60 * 5; // 5 minutes

export const useAnnuaireStore = defineStore(ModuleType.Annuaire, {
  state: (): AnnuaireStateType => ({
    groupTreeList: [],
    lastRefresh: 0,
  }),
  getters: {
    getGroupTree(state): AnnuaireGroupTree[] {
      return state.groupTreeList;
    },
    getPrimaryGroup(state): AnnuaireGroupTree {
      const primaryNonPoolGroup = state.groupTreeList.find(
        (group) =>
          group.roleList.includes(Role.BANQUE) ||
          group.roleList.includes(Role.PROMOTEUR)
      );

      if (!primaryNonPoolGroup) {
        return state.groupTreeList[0];
      }

      return primaryNonPoolGroup;
    },
    getUserGroupTreeIdList(_state): string[] {
      const primaryGroup = this.getPrimaryGroup;
      const childrenIdList = primaryGroup.children.map((group) => group.id);
      return [primaryGroup.id, ...childrenIdList];
    },
  },
  actions: {
    SetGroupTree(groupTree: AnnuaireGroupTree[]): void {
      this.groupTreeList = groupTree;
    },

    ResetGroupTree(): void {
      this.groupTreeList = [];
      this.lastRefresh = 0;
    },

    async fetchGroupTree(force?: boolean): Promise<void> {
      const shouldRevalidate =
        Date.now() - this.lastRefresh > REFRESH_GROUP_TREE_INTERVAL;

      if (!shouldRevalidate && !force) {
        return;
      }

      const result = await annuaireApi.fetchGroupTree();
      this.SetGroupTree(result.data);
      this.lastRefresh = Date.now();
    },
  },
});
