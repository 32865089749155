import { OperationLight } from "@/domain/dto/operationLight";
import uniq from "lodash/uniq";

export function findReferentUserIdListFromOperationLightList(
  operationList: OperationLight[]
): string[] {
  const userIdList: string[] = [];

  operationList.forEach((operation) => {
    if (operation.referentPromoteur1) {
      userIdList.push(operation.referentPromoteur1);
    }
    if (operation.referentPromoteur2) {
      userIdList.push(operation.referentPromoteur2);
    }
    if (operation.referentPromoteur3) {
      userIdList.push(operation.referentPromoteur3);
    }
    if (operation.referentBanque1) {
      userIdList.push(operation.referentBanque1);
    }
    if (operation.referentBanque2) {
      userIdList.push(operation.referentBanque2);
    }
    if (operation.referentBanque3) {
      userIdList.push(operation.referentBanque3);
    }
  });

  return uniq(userIdList);
}
