import SockitSingleton from "@/api/sockit/sockit";
import useKeycloakStore from "@/store/keycloakModule.pinia";
import { computed, ref, watchEffect } from "vue";

export const useSockit = () => {
  const keycloakStore = useKeycloakStore();

  const sockitInstance = ref<SockitSingleton | null>(null);
  const token = computed(() => keycloakStore.getAuth?.token);

  watchEffect(() => {
    if (token.value) {
      sockitInstance.value = SockitSingleton.getInstance(token.value);
    } else {
      sockitInstance.value = null;
      console.error("Token inaccessible");
    }
  });

  return { sockitInstance, token };
};
