import { apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { ActeSecondaireCreationRequest } from "@domain/dto/acteSecondaireCreationRequest";

export default {
  create(
    acteCreationRequest: ActeSecondaireCreationRequest,
    idOperation: string
  ): Promise<ApiResponse<void[]>> {
    return apiRequestThrottle.post(
      `/operations/${idOperation}/attestations`,
      acteCreationRequest
    );
  },
};
