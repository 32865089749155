import { Group } from "@domain/dto/group";
import { Member } from "@/domain/member";
import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import { GroupTreeHeavy } from "@/domain/dto/groupTreeHeavy";
import uniqBy from "lodash/uniqBy";
import { buildGroupTreeHeavy } from "@/utils/groupUtils";
import orderBy from "lodash/orderBy";
import groupApi from "@/api/groupApi";
import { useDemandeStore } from "@/store/demandeModule.pinia";
import { Demande } from "@/domain/demande";
import { toastError, toastSuccess } from "@/utils/toastUtils";
import { useOperationStore } from "./operationModule.pinia";
import { useUserOperationStore } from "@/store/userOperationModule.pinia";
import { GroupLite } from "@domain/dto/groupLite";
import { AddBanqueParams } from "@/domain/addBanqueParams";

type GroupStateType = {
  banqueGroups: Group[];
  promoteurGroupsRelatedToCurrentBanque: Group[];
  myGroupMembers: Member[];
  banqueGroupsLite: GroupLite[];
};

export const useGroupStore = defineStore(ModuleType.Group, {
  state: (): GroupStateType => ({
    banqueGroups: [],
    promoteurGroupsRelatedToCurrentBanque: [],
    myGroupMembers: [],
    banqueGroupsLite: [],
  }),
  getters: {
    getBanqueGroups(state): Group[] {
      return state.banqueGroups;
    },

    getBanqueGroupsLite(state): GroupLite[] {
      return state.banqueGroupsLite;
    },

    getPromoteurGroupTreesRelatedToCurrentBanque(state): GroupTreeHeavy[] {
      return uniqBy(
        buildGroupTreeHeavy(state.promoteurGroupsRelatedToCurrentBanque),
        "id"
      );
    },

    getBanqueGroupsTree(state): GroupTreeHeavy[] {
      return buildGroupTreeHeavy(state.banqueGroups);
    },

    getMyGroupMembers(state): Member[] {
      return state.myGroupMembers;
    },
  },
  actions: {
    SetBanqueGroups(banqueGroups: Group[]): void {
      this.banqueGroups = orderBy(banqueGroups, "name");
    },

    SetBanqueGroupsLite(banqueGroups: GroupLite[]): void {
      this.banqueGroupsLite = orderBy(banqueGroups, "name");
    },

    SetPromoteurGroupsRelatedToCurrentBanque(promoteurGroups: Group[]): void {
      this.promoteurGroupsRelatedToCurrentBanque = orderBy(
        promoteurGroups,
        "name"
      );
    },

    SetMyGroupMembers(myGroupMembers: Member[]): void {
      this.myGroupMembers = orderBy(myGroupMembers, "lastName");
    },

    async fetchBanqueGroups(): Promise<void> {
      const result = await groupApi.fetchBanqueGroupList();
      this.SetBanqueGroups(result.data);
    },

    async fetchBanqueGroupsLite(): Promise<void> {
      const result = await groupApi.fetchBanqueGroupLiteList();
      this.SetBanqueGroupsLite(result.data);
    },

    async fetchPromoteurGroupsRelatedToCurrentBanque(): Promise<void> {
      const result =
        await groupApi.fetchPromoteurGroupsRelatedToCurrentBanque();
      this.SetPromoteurGroupsRelatedToCurrentBanque(result.data);
    },

    async fetchGroup(idGroup: string): Promise<Group> {
      const result = await groupApi.fetchGroup(idGroup);
      return result.data;
    },

    async fetchMyGroupMembersInOperation(idOperation?: string): Promise<void> {
      if (!idOperation) {
        idOperation = useOperationStore().getCurrentOperationId;
      }
      const result = await groupApi.fetchMyGroupMembers(idOperation);
      this.SetMyGroupMembers(result.data);
    },

    async addBanque(params: AddBanqueParams): Promise<void> {
      const demandeStore = useDemandeStore();

      const demande: Demande = demandeStore.getDemande;

      const result = await groupApi.addBanque(
        demande,
        params.idBanque,
        params.demandeUnilateraleBanqueName
      );

      if (result.status === 403) {
        toastError(result.data);
        throw new Error(result.data);
      } else if (result.status === 200) {
        toastSuccess("La banque a bien été ajoutée à l'opération");
        await demandeStore.fetchDemande({
          idOperation: demande.idOperation,
          idDemande: demande.id,
        });
        await useUserOperationStore().fetchOperationMembers(
          demande.idOperation
        );
        await this.fetchMyGroupMembersInOperation();
      }
    },
  },
});
