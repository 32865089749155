import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { AccordCommercialDocument } from "@domain/dto/accordCommercialDocument";
import { AccordCommercialSignataireUpdateRequest } from "@domain/dto/accordCommercialSignataireUpdateRequest";
import { AccordCommercialUpdateRequest } from "@domain/dto/accordCommercialUpdateRequest";
import { AccordCommercialValidationRequest } from "@domain/dto/accordCommercialValidationRequest";
import { AnnuaireUser } from "@domain/dto/annuaireUser";
import { PendingDocument } from "@domain/dto/pendingDocument";
import { PoolAccordCommercialHeavy } from "@domain/dto/poolAccordCommercialHeavy";
import { AccordCommercialStep } from "@domain/enum/accordCommercialStep";

export default {
  async fetchAccordCommercial(
    idOperation: string,
    idPoolAccordCommercial: string
  ): Promise<ApiResponse<PoolAccordCommercialHeavy>> {
    return apiRequest.get(
      `/operations/${idOperation}/accords-commerciaux/${idPoolAccordCommercial}`
    );
  },

  async fetchAccordCommercialProjectDocument(
    idOperation: string,
    idPoolAccordCommercial: string
  ): Promise<ApiResponse<AccordCommercialDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/accords-commerciaux/${idPoolAccordCommercial}/project`
    );
  },

  async fetchAccordCommercialActeDocument(
    idOperation: string,
    idPoolAccordCommercial: string
  ): Promise<ApiResponse<AccordCommercialDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/accords-commerciaux/${idPoolAccordCommercial}/acte`
    );
  },

  async fetchAccordCommercialDocumentFile(data: {
    idOperation: string;
    idPoolAccordCommercial: string;
    idDocument: string;
  }): Promise<ApiResponse<BlobPart>> {
    return apiRequest.get(
      `/operations/${data.idOperation}/accords-commerciaux/${data.idPoolAccordCommercial}/documents/${data.idDocument}`,
      { responseType: "blob" }
    );
  },

  async fetchMembers(data: {
    idOperation: string;
    idPoolAccordCommercial: string;
  }): Promise<ApiResponse<AnnuaireUser[]>> {
    return apiRequest.get(
      `/operations/${data.idOperation}/accords-commerciaux/${data.idPoolAccordCommercial}/members`
    );
  },

  async uploadProjectDocument(
    idOperation: string,
    idPoolAccordCommercial: string,
    file: File
  ): Promise<ApiResponse<PendingDocument[]>> {
    const formData = new FormData();
    formData.append("project", file);

    return apiRequestThrottle.post(
      `/operations/${idOperation}/accords-commerciaux/${idPoolAccordCommercial}/project`,
      formData
    );
  },

  async validate(data: {
    idOperation: string;
    idPoolAccordCommercial: string;
    updateRequest: AccordCommercialValidationRequest;
  }): Promise<ApiResponse<PoolAccordCommercialHeavy>> {
    return apiRequestThrottle.patch(
      `/operations/${data.idOperation}/accords-commerciaux/${data.idPoolAccordCommercial}/validate`,
      data.updateRequest
    );
  },

  async chooseSignMethod(data: {
    idOperation: string;
    idPoolAccordCommercial: string;
    updateRequest: AccordCommercialUpdateRequest;
  }): Promise<ApiResponse<PoolAccordCommercialHeavy>> {
    return apiRequestThrottle.patch(
      `/operations/${data.idOperation}/accords-commerciaux/${data.idPoolAccordCommercial}`,
      data.updateRequest
    );
  },

  async manuallySign(data: {
    idOperation: string;
    idPoolAccordCommercial: string;
  }): Promise<ApiResponse<PoolAccordCommercialHeavy>> {
    const updateRequest: AccordCommercialUpdateRequest = {
      step: AccordCommercialStep.COMPLETED,
    };
    return apiRequestThrottle.patch(
      `/operations/${data.idOperation}/accords-commerciaux/${data.idPoolAccordCommercial}`,
      updateRequest
    );
  },

  updateSignataireConfiguration(data: {
    idOperation: string;
    idPoolAccordCommercial: string;
    updateRequest: AccordCommercialSignataireUpdateRequest;
  }): Promise<ApiResponse<PoolAccordCommercialHeavy>> {
    return apiRequestThrottle.patch(
      `/operations/${data.idOperation}/accords-commerciaux/${data.idPoolAccordCommercial}/signataires`,
      data.updateRequest,
      {
        validateStatus: function (status) {
          return status === 201 || status === 409;
        },
      }
    );
  },

  initializeSignature(
    idOperation: string,
    idPoolAccordCommercial: string
  ): Promise<ApiResponse<PoolAccordCommercialHeavy>> {
    return apiRequest.post(
      `/operations/${idOperation}/accords-commerciaux/${idPoolAccordCommercial}/signature/initialize`,
      {},
      {
        validateStatus: function (status: number) {
          return [200, 400, 409].includes(status);
        },
      }
    );
  },

  update(
    idOperation: string,
    idPoolAccordCommercial: string,
    updateRequest: AccordCommercialUpdateRequest
  ): Promise<ApiResponse<PoolAccordCommercialHeavy>> {
    return apiRequestThrottle.patch(
      `/operations/${idOperation}/accords-commerciaux/${idPoolAccordCommercial}`,
      updateRequest
    );
  },
};
