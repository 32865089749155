import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { PoolOperationHeavy } from "@/domain/dto/poolOperationHeavy";
import { Group } from "@domain/dto/group";
import { PoolCreationRequest } from "@domain/dto/poolCreationRequest";
import { PoolGroupAndUserListCreationRequest } from "@domain/dto/poolGroupAndUserListCreationRequest";
import { PoolGroupAndUserListUpdateRequest } from "@domain/dto/poolGroupAndUserListUpdateRequest";
import {
  PoolGroupAndUserListErrorWithUserList,
  PoolGroupResponse,
} from "@domain/dto/poolGroupResponse";
import { PoolGroupUpdateRequest } from "@/domain/dto/poolGroupUpdateRequest";
import { PoolGroupBanque } from "@/domain/poolGroupBanque";
import { PoolGroupAndUserListError } from "@domain/dto/poolGroupAndUserListError";

export default {
  fetchPoolGroupList(): Promise<ApiResponse<Group[]>> {
    return apiRequest.get(`/pool/groups`);
  },

  fetchOperationPool(
    idOperation: string
  ): Promise<ApiResponse<PoolOperationHeavy>> {
    return apiRequest.get(`/pool/operations/${idOperation}`);
  },

  shareDemande(
    idOperation: string,
    idDemande: string,
    idPoolGroupList: string[]
  ): Promise<ApiResponse<PoolOperationHeavy>> {
    return apiRequestThrottle.put(
      `/pool/operations/${idOperation}/demandes/${idDemande}/share`,
      idPoolGroupList
    );
  },

  cancelShareDemande(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<PoolOperationHeavy>> {
    return apiRequestThrottle.put(
      `/pool/operations/${idOperation}/demandes/${idDemande}/cancel-share`
    );
  },

  shareOperation(
    idOperation: string,
    poolCreationRequest: PoolCreationRequest
  ): Promise<ApiResponse<PoolOperationHeavy>> {
    return apiRequestThrottle.put(
      `/pool/operations/${idOperation}/share`,
      poolCreationRequest
    );
  },

  createPoolGroupWithUsers(
    poolGroupAndUserListCreationRequest: PoolGroupAndUserListCreationRequest
  ): Promise<ApiResponse<PoolGroupAndUserListError | PoolGroupResponse>> {
    return apiRequestThrottle.post(
      `/pool/groups`,
      poolGroupAndUserListCreationRequest,
      {
        validateStatus(status) {
          return status === 201 || status === 409;
        },
      }
    );
  },
  updatePoolGroup(
    idPoolGroup: string,
    poolGroupUpdateRequest: PoolGroupUpdateRequest
  ): Promise<ApiResponse<PoolGroupBanque>> {
    return apiRequestThrottle.put(
      `/pool/groups/${idPoolGroup}`,
      poolGroupUpdateRequest
    );
  },

  updatePoolGroupWithUsers(
    idPoolGroup: string,
    poolGroupAndUserListUpdateRequest: PoolGroupAndUserListUpdateRequest
  ): Promise<
    ApiResponse<PoolGroupAndUserListErrorWithUserList | PoolGroupResponse>
  > {
    return apiRequestThrottle.put(
      `/pool/groups/${idPoolGroup}/users`,
      poolGroupAndUserListUpdateRequest,
      {
        validateStatus(status) {
          return [200, 404, 409].includes(status);
        },
      }
    );
  },
};
