import { OperationHeavy } from "@/domain/operationHeavy";
import { DemandeType } from "@domain/enum/demandeType";
import { SuiviTravauxStep } from "@domain/enum/SuiviTravauxStep";
import { SuiviTravaux } from "@/domain/suiviTravaux";
import deburr from "lodash/deburr";
import { SuiviTravauxMassif } from "@domain/dto/suiviTravauxMassif";
import { GroupTreeLite } from "@domain/dto/groupTreeLite";

export function getSuiviTravauxLabelByStep(
  suiviTravauxStep: SuiviTravauxStep
): string {
  switch (suiviTravauxStep) {
    case SuiviTravauxStep.DEMOLITION:
      return "Démolition";
    case SuiviTravauxStep.TERRASSEMENT:
      return "Terrassement";
    case SuiviTravauxStep.FONDATIONS:
      return "Fondations";
    case SuiviTravauxStep.DALLE:
      return "Dalle";
    case SuiviTravauxStep.RDC:
      return "RDC";
    case SuiviTravauxStep.GROS_OEUVRE_INF_50_POURCENT:
      return "Gros Œuvre < 50%";
    case SuiviTravauxStep.GROS_OEUVRE_SUP_50_POURCENT:
      return "Gros Œuvre > 50%";
    case SuiviTravauxStep.HORS_EAU:
      return "Hors d'eau";
    case SuiviTravauxStep.HORS_AIR:
      return "Hors d'air";
    case SuiviTravauxStep.CLOISONS:
      return "Cloisons";
    case SuiviTravauxStep.REVETEMENT_DE_SOLS:
      return "Revêtement de sols";
    case SuiviTravauxStep.FINITIONS:
      return "Finitions";
    case SuiviTravauxStep.ACHEVEMENT:
      return "Achèvement";
    case SuiviTravauxStep.LIVRAISON:
      return "Livraison";
    case SuiviTravauxStep.ARRET_DE_CHANTIER:
      return "Arrêt de chantier";
    case SuiviTravauxStep.INCONNU:
      return "Ignorer ce statut";
  }
}

export function getSuiviTravauxStepKeyLabel(): Record<
  string,
  SuiviTravauxStep
> {
  return {
    demolition: SuiviTravauxStep.DEMOLITION,
    demolitions: SuiviTravauxStep.DEMOLITION,
    terrassement: SuiviTravauxStep.TERRASSEMENT,
    terrassements: SuiviTravauxStep.TERRASSEMENT,
    "achevement des terrassements": SuiviTravauxStep.TERRASSEMENT,
    fondations: SuiviTravauxStep.FONDATIONS,
    fondation: SuiviTravauxStep.FONDATIONS,
    dalle: SuiviTravauxStep.DALLE,
    dallage: SuiviTravauxStep.DALLE,
    dalles: SuiviTravauxStep.DALLE,
    rdc: SuiviTravauxStep.RDC,
    "rez de chaussee": SuiviTravauxStep.RDC,
    "rez-de-chaussee": SuiviTravauxStep.RDC,
    "gros oeuvre < 50%": SuiviTravauxStep.GROS_OEUVRE_INF_50_POURCENT,
    "gros oeuvre > 50%": SuiviTravauxStep.GROS_OEUVRE_SUP_50_POURCENT,
    "gros oeuvre": SuiviTravauxStep.GROS_OEUVRE_SUP_50_POURCENT,
    "gros oeuvre acheve": SuiviTravauxStep.GROS_OEUVRE_SUP_50_POURCENT,
    "hors d'eau": SuiviTravauxStep.HORS_EAU,
    "hors eau": SuiviTravauxStep.HORS_EAU,
    "hors d'air": SuiviTravauxStep.HORS_AIR,
    "hors air": SuiviTravauxStep.HORS_AIR,
    cloisons: SuiviTravauxStep.CLOISONS,
    cloison: SuiviTravauxStep.CLOISONS,
    cloisonnement: SuiviTravauxStep.CLOISONS,
    "revetement de sols": SuiviTravauxStep.REVETEMENT_DE_SOLS,
    "revetements de sols": SuiviTravauxStep.REVETEMENT_DE_SOLS,
    plancher: SuiviTravauxStep.REVETEMENT_DE_SOLS,
    planchers: SuiviTravauxStep.REVETEMENT_DE_SOLS,
    carrelage: SuiviTravauxStep.REVETEMENT_DE_SOLS,
    finitions: SuiviTravauxStep.FINITIONS,
    finition: SuiviTravauxStep.FINITIONS,
    achevement: SuiviTravauxStep.ACHEVEMENT,
    livraison: SuiviTravauxStep.LIVRAISON,
    "arret de chantier": SuiviTravauxStep.ARRET_DE_CHANTIER,
    "ignorer ce statut": SuiviTravauxStep.INCONNU,
  };
}

export function egetPreviousSuiviTravauxStepKey(
  key: keyof typeof SuiviTravauxStep
): SuiviTravauxStep | null {
  const keys = Object.keys(
    SuiviTravauxStep
  ) as (keyof typeof SuiviTravauxStep)[];

  for (let i = 1; i < keys.length; i++) {
    if (SuiviTravauxStep[keys[i]] === key) {
      return SuiviTravauxStep[keys[i - 1]];
    }
  }

  return null;
}

export function isDateLaterThanLastStep(
  step: SuiviTravauxStep,
  date: Date,
  suiviTravaux: SuiviTravaux
): boolean {
  const stepsEnumValues = Object.values(SuiviTravauxStep);
  const stepIndex = stepsEnumValues.indexOf(step);

  const precedingStepsToChosenKey = stepsEnumValues.slice(0, stepIndex);
  const followingStepsToChosenKey = stepsEnumValues.slice(stepIndex + 1);

  const latestPrecedingStep = precedingStepsToChosenKey.reduce(
    (latestStep, precedingStepKey) => {
      const precedingStep = suiviTravaux.steps[precedingStepKey];

      if (
        precedingStep &&
        new Date(precedingStep.dateAchevement) >
          new Date(latestStep.dateAchevement)
      ) {
        return precedingStep;
      }
      return latestStep;
    },
    { dateAchevement: new Date(0) }
  );

  const firstFollowingStep = followingStepsToChosenKey.reduce(
    (latestStep, nextStepKey) => {
      const followingStep = suiviTravaux.steps[nextStepKey];

      if (
        followingStep &&
        new Date(followingStep.dateAchevement) <
          new Date(latestStep.dateAchevement)
      ) {
        return followingStep;
      }
      return latestStep;
    },
    { dateAchevement: new Date("9999") }
  );

  return (
    date > new Date(latestPrecedingStep.dateAchevement) &&
    date < new Date(firstFollowingStep.dateAchevement)
  );
}

export function computeListDatePrevisionnelleDisponible(
  operation: OperationHeavy
): Date[] {
  const gfaList = operation.demandeList.filter(
    (demande) => demande.type === DemandeType.GARANTIE_FINANCIERE_ACHEVEMENT
  );

  const dates: Date[] = [];
  for (const gfa of gfaList) {
    if (gfa.datePrevisionnelleAchevement)
      dates.push(gfa.datePrevisionnelleAchevement);
  }
  dates.sort((a, b) => {
    return new Date(a).getTime() - new Date(b).getTime();
  });
  return dates;
}

export function getSuiviTravauxStepByMappedKey(
  mappedKeys: Record<string, SuiviTravauxStep>,
  key: string
): SuiviTravauxStep | undefined {
  const keyLowerCase = key.toLowerCase();

  if (mappedKeys[keyLowerCase]) {
    return mappedKeys[keyLowerCase];
  }
  const keyWithoutAccents = deburr(keyLowerCase);
  if (getSuiviTravauxStepKeyLabel()[keyWithoutAccents]) {
    return getSuiviTravauxStepKeyLabel()[keyWithoutAccents];
  }
  return undefined;
}

export function getSuiviTravauxStepLabelByMappedKey(
  mappedKeys: Record<string, SuiviTravauxStep>,
  key: string
): string {
  const keyLowerCase = key.toLowerCase();
  const step = getSuiviTravauxStepByMappedKey(mappedKeys, keyLowerCase);
  if (step) {
    return getSuiviTravauxLabelByStep(step);
  }
  return key;
}

export function getMostRecentSuiviCreatedAtDate(
  suiviTravauxMassifList: SuiviTravauxMassif[]
): Date | null {
  if (!suiviTravauxMassifList.length) {
    return null;
  }

  // 1970 valeur la plus basse possible utiliser pour apres trouver la date la plus grande
  let mostRecentSuiviTravaux: number = new Date("1970").getTime();
  for (const suiviTravaux of suiviTravauxMassifList) {
    if (
      suiviTravaux.createdAt &&
      new Date(suiviTravaux.createdAt).getTime() > mostRecentSuiviTravaux
    ) {
      mostRecentSuiviTravaux = new Date(suiviTravaux.createdAt).getTime();
    }
  }
  return new Date(mostRecentSuiviTravaux);
}

export function buildGroupNameList(group: GroupTreeLite | null): string[] {
  if (!group) {
    return [];
  }

  const groupGenealogy: string[] = [];

  if (group.parent) {
    groupGenealogy.push(group.parent.name);
  }

  groupGenealogy.push(group.name);

  return groupGenealogy;
}
