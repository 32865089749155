<template>
  <div class="banner-specific-container">
    <div class="icon-container">
      <img
        alt="notification des utilisateurs ayant discuté sur une des vos opérations"
        class="banner-icon"
        src="../../assets/bell_orange.svg"
      />
    </div>
    <div class="content mini" data-test="notification-content">
      {{ messageLabel }}
    </div>
  </div>
</template>

<script lang="ts">
import { NotificationContent } from "@/domain/dto/notificationContent";
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    operationName: {
      type: String,
      required: true,
    },
    messageChefDeFileNotificationContent: {
      type: Object as () => {
        participantName: string;
        notificationContent: NotificationContent;
      },
      required: true,
    },
  },
  setup(props) {
    const messageLabel = computed((): string => {
      return `${numberMessageLabel.value} sur l'opération ${operationLabel.value} avec ${props.messageChefDeFileNotificationContent.participantName}`;
    });

    const numberMessageLabel = computed((): string => {
      const messageCount =
        props.messageChefDeFileNotificationContent.notificationContent
          .messageCount ?? 0;
      const messageSingularPlural =
        messageCount > 1
          ? "messages ont été échangés"
          : "message a été échangé";

      return `${messageCount} ${messageSingularPlural}`;
    });

    const operationLabel = computed((): string => {
      return props.operationName ?? "";
    });

    return {
      messageLabel,
      numberMessageLabel,
      operationLabel,
    };
  },
});
</script>

<style lang="scss" scoped>
.banner-specific-container {
  display: flex;
}
</style>
