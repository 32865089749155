export enum OperationDocumentCategory {
  AUTORISATION_ADMINISTRATIVES = "Autorisations administratives",
  MAITRISE_FONCIERE = "Maitrise foncière",
  FINANCIER = "Financier",
  JURIDIQUE = "Juridique",
  TECHNIQUE = "Technique",
  COMMERCIALISATION = "Commercialisation",
  ACTE = "Acte",
  PROJET = "Projet",
  AUTRE = "Autre",
  LETTRE_ACCORD = "Lettre d'accord",
  ACHEVEMENT = "Achèvement",
  ACHEVEMENT_MAINLEVEE = "Achèvement Mainlevée",
  SUIVI = "Suivi",
  IFPIM = "Ifpim",
}
