<template>
  <div class="banner-specific-container" data-test="notification-text">
    <div class="icon-container">
      <img
        alt="acte secondaire added"
        class="icon"
        src="@/assets/event/AddDoc.svg"
      />
    </div>
    <div>
      <span class="semi-bold">
        {{ notification.payload.acteCount }}
      </span>
      {{ notificationText[0] }}
      <span class="semi-bold"> {{ demandeCategory }}</span>
      {{ notificationText[1] }}
      à l'opération
      <span class="semi-bold">
        {{ notification.payload.operationName }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Notification } from "@/domain/notification";
import { toCategory } from "@/utils/notificationUtils";
import { computed, defineComponent } from "vue";
export default defineComponent({
  props: {
    notification: {
      type: Object as () => Notification,
      required: true,
    },
  },
  setup(props) {
    const hasMultipleActe = computed((): boolean => {
      return (
        !!props.notification.payload.acteCount &&
        props.notification.payload.acteCount > 1
      );
    });

    const notificationText = computed((): string[] => {
      return hasMultipleActe.value
        ? [" actes secondaires de ", " ont été ajoutés "]
        : [" acte secondaire de ", " a été ajouté "];
    });

    const demandeCategory = computed((): string => {
      const demandeType = props.notification.payload.demandeType;
      return demandeType ? toCategory(demandeType) : "inconnu";
    });

    return {
      hasMultipleActe,
      notificationText,
      demandeCategory,
    };
  },
});
</script>

<style lang="scss" scoped>
.banner-specific-container {
  display: flex;
  align-items: center;
}
</style>
