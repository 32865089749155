import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { LettreAccordLight } from "@domain/dto/lettreAccordLight";
import { LettreAccordCreationRequest } from "@domain/dto/lettreAccordCreationRequest";
import { LettreAccordHeavy } from "@domain/dto/lettreAccordHeavy";
import { OperationDocument } from "@domain/dto/operationDocument";
import { LettreAccordSignataireCreateRequest } from "@domain/dto/lettreAccordSignataireCreateRequest";
import { WorkflowRollbackType } from "@/domain/enum/workflowRollbackType";
import { StatusUpdateRequest } from "@domain/dto/statusUpdateRequest";
import { LettreAccordUpdateRequest } from "@domain/dto/lettreAccordUpdateRequest";
import { PendingDocument } from "@domain/dto/pendingDocument";
import { Demande } from "@/domain/demande";
import { DocusignConfigurationError } from "@domain/enum/docusignConfigurationError";

export default {
  create(
    lettreAccordCreationRequest: LettreAccordCreationRequest,
    idOperation: string
  ): Promise<ApiResponse<LettreAccordLight>> {
    return apiRequestThrottle.post(
      `/operations/${idOperation}/lettres-accord`,
      lettreAccordCreationRequest
    );
  },

  validateLettreAccord(
    idOperation: string,
    idLettreAccord: string,
    statusUpdateRequest: StatusUpdateRequest
  ): Promise<ApiResponse<LettreAccordHeavy>> {
    return apiRequestThrottle.patch(
      `/operations/${idOperation}/lettres-accord/${idLettreAccord}/validate`,
      statusUpdateRequest
    );
  },

  fetchOperationLettresAccord(
    idOperation: string
  ): Promise<ApiResponse<LettreAccordLight[]>> {
    return apiRequest.get(`/operations/${idOperation}/lettres-accord`);
  },

  fetchLettreAccord(
    idOperation: string,
    idLettreAccord: string
  ): Promise<ApiResponse<LettreAccordHeavy>> {
    return apiRequest.get(
      `/operations/${idOperation}/lettres-accord/${idLettreAccord}`
    );
  },

  fetchLettreAccordProjectDocument(
    idOperation: string,
    idLettreAccord: string
  ): Promise<ApiResponse<OperationDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/lettres-accord/${idLettreAccord}/project`
    );
  },

  fetchLettreAccordActeDocument(
    idOperation: string,
    idLettreAccord: string
  ): Promise<ApiResponse<OperationDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/lettres-accord/${idLettreAccord}/acte`
    );
  },

  fetchLettreAccordCertificateDocument(
    idOperation: string,
    idLettreAccord: string
  ): Promise<ApiResponse<OperationDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/lettres-accord/${idLettreAccord}/certificate`
    );
  },

  uploadLettreAccordProjectDocument(
    idOperation: string,
    idLettreAccord: string,
    formData: FormData
  ): Promise<ApiResponse<PendingDocument[]>> {
    return apiRequestThrottle.post(
      `/operations/${idOperation}/lettres-accord/${idLettreAccord}/project`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  updateLettreAccordSignatureConfiguration(
    idOperation: string,
    idLettreAccord: string,
    lettreAccordSignataireCreateRequest: LettreAccordSignataireCreateRequest
  ): Promise<ApiResponse<LettreAccordHeavy | null>> {
    return apiRequestThrottle.patch(
      `/operations/${idOperation}/lettres-accord/${idLettreAccord}/signataires`,
      lettreAccordSignataireCreateRequest,
      {
        validateStatus: function (status) {
          return status === 201 || status === 409;
        },
      }
    );
  },

  update(
    idOperation: string,
    idLettreAccord: string,
    lettreAccordUpdateRequest: LettreAccordUpdateRequest
  ): Promise<ApiResponse<LettreAccordHeavy>> {
    return apiRequestThrottle.patch(
      `/operations/${idOperation}/lettres-accord/${idLettreAccord}`,
      lettreAccordUpdateRequest,
      {
        validateStatus(status) {
          return status === 200 || status === 409;
        },
      }
    );
  },

  initializeSignature(
    idOperation: string,
    idLettreAccord: string
  ): Promise<ApiResponse<LettreAccordHeavy | DocusignConfigurationError[]>> {
    return apiRequest.post(
      `/operations/${idOperation}/lettres-accord/${idLettreAccord}/signature/initialize`,
      {},
      {
        validateStatus: function (status: number) {
          return [200, 400, 409].includes(status);
        },
      }
    );
  },

  startLettreAccordSignatureCeremonie(
    idOperation: string,
    idLettreAccord: string
  ): Promise<ApiResponse<string>> {
    return apiRequest.post(
      `/operations/${idOperation}/lettres-accord/${idLettreAccord}/signature/start-ceremonie`
    );
  },

  rollbackWorkflow(
    idOperation: string,
    idLettreAccord: string,
    rollbackType: WorkflowRollbackType
  ): Promise<ApiResponse<string>> {
    return apiRequestThrottle.patch(
      `/operations/${idOperation}/lettres-accord/${idLettreAccord}/workflow/rollback`,
      { rollbackType }
    );
  },

  remindSignatureToRecipient(
    idOperation: string,
    idLettreAccord: string,
    idRecipient: number
  ): Promise<ApiResponse<Demande>> {
    return apiRequestThrottle.put(
      `/operations/${idOperation}/lettres-accord/${idLettreAccord}/signature/remind/${idRecipient}`,
      {},
      {
        validateStatus: (status) => [201, 400, 403, 409].includes(status),
      }
    );
  },
};
