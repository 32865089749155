export enum FileStatus {
  // Step par défaut
  INCONNU = "INCONNU",

  // Steps transitoires
  TRANSFERT_GED = "TRANSFERT_GED",
  CHECK_ANTIVIRUS = "CHECK_ANTIVIRUS",
  CHIFFREMENT = "CHIFFREMENT",

  // Steps définitifs
  VIRUS_DETECTE = "VIRUS_DETECTE",
  ECHEC_TRANSMISSION = "ECHEC_TRANSMISSION",
  ECHEC_CHIFFREMENT = "ECHEC_CHIFFREMENT",
  UPLOADED = "UPLOADED",
  READY_TO_BE_DOWNLOADED = "READY_TO_BE_DOWNLOADED",
}
