import { apiRequestThrottle, ApiResponse } from "@/apiRequest";

export default {
  initializeESGForm(idOperation: string): Promise<ApiResponse<void>> {
    return apiRequestThrottle.post(`/operation/${idOperation}/esg/initialize`);
  },
  askPromoteurForEsgForm(idOperation: string): Promise<ApiResponse<void>> {
    return apiRequestThrottle.post(`/operation/${idOperation}/esg/ask`);
  },
};
