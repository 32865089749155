import { apiRequest, ApiResponse } from "@/apiRequest";
import type { DemandeSignataireExterne } from "@domain/dto/demandeSignataireExterne";
import type { SignataireExterneUpdateRequest } from "@domain/dto/signataireExterneUpdateRequest";

export default {
  update(
    idOperation: string,
    idDemande: string,
    idSignataire: string,
    payload: SignataireExterneUpdateRequest
  ): Promise<ApiResponse<DemandeSignataireExterne>> {
    return apiRequest.patch(
      `/operations/${idOperation}/demandes/${idDemande}/signataires/externe/${idSignataire}`,
      payload,
      {
        validateStatus: function (status) {
          return status === 201 || status === 409;
        },
      }
    );
  },
};
