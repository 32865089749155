import {enumVariants} from "@domain/utils/enumUtils";

export enum DemandeStatus {
    PREPARATION = "PREPARATION",
    PARTAGEE = "PARTAGEE",
    CO_CONSTRUCTION = "CO_CONSTRUCTION",
    CONFIRMATION_A_VALIDER = "CONFIRMATION_A_VALIDER",
    EN_ATTENTE_DU_CHOIX_DE_SIGNATURE = "EN_ATTENTE_DU_CHOIX_DE_SIGNATURE",
    EN_ATTENTE_DE_SIGNATURE = "EN_ATTENTE_DE_SIGNATURE",
    SIGNE = "SIGNE",
    DECLINED = "DECLINED",
    DATE_ACHEVEMENT_DEPASSEE = "DATE_ACHEVEMENT_DEPASSEE",
    EN_ATTENTE_DE_MAINLEVEE = "EN_ATTENTE_DE_MAINLEVEE",
    MAIN_LEVEE_EFFECTUEE = "MAIN_LEVEE_EFFECTUEE",
    MAIN_LEVEE_REFUSEE = "MAIN_LEVEE_REFUSEE",
}

export function demandeStatusVariants(): DemandeStatus[] {
    return enumVariants(DemandeStatus);
}
