<template>
  <div class="bell-container" @click="showNotificationPanel">
    <img
      alt="notification"
      class="bell-icon"
      src="@/assets/Notifications.svg"
    />
    <div class="sticker-container">
      <Sticker v-if="unreadNotificationsCount > 0" :count-value="countLabel" />
    </div>
  </div>
</template>

<script lang="ts">
import Sticker from "@/components/shared/StickerComponent.vue";
import { Notification } from "@/domain/notification";
import { PanelType } from "@/utils/model/panelType";

import { computed, defineComponent } from "vue";
import { getSetupContext } from "@/utils/vueUtils";

export default defineComponent({
  components: { Sticker },
  setup(_props) {
    const { panelStore, notificationStore } = getSetupContext();

    const unreadNotificationsCount = computed((): number => {
      return notificationStore.getNotifications.filter(
        (notification: Notification) => !notification.read
      ).length;
    });

    const countLabel = computed((): string => {
      const count = unreadNotificationsCount.value;
      return count < 100 ? `${count}` : "99+";
    });

    function showNotificationPanel(): void {
      panelStore.SetPanelType(PanelType.NOTIFICATION);
    }

    return {
      // Computed
      unreadNotificationsCount,
      countLabel,

      // Methods
      showNotificationPanel,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";

.bell-container {
  position: relative;
  display: flex;

  .bell-icon {
    cursor: pointer;
    width: 36px;
  }

  .sticker-container {
    position: absolute;
    top: 17px;
    left: 20px;
  }
}
</style>
