import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { AccountCreationRequest } from "@domain/dto/accountCreationRequest";
import { Account } from "@/domain/account";
import { AccountUpdateRequest } from "@domain/dto/accountUpdateRequest";

export default {
  create(account: AccountCreationRequest): Promise<ApiResponse<Account>> {
    return apiRequestThrottle.post(
      `/societes-support/${account.idSocieteSupport}/accounts`,
      account
    );
  },

  update(
    idAccount: string,
    idSocieteSupport: string,
    changePayload: AccountUpdateRequest
  ): Promise<ApiResponse<Account>> {
    return apiRequestThrottle.patch(
      `/societes-support/${idSocieteSupport}/accounts/${idAccount}`,

      changePayload
    );
  },

  fetchAll(idSocieteSupport: string): Promise<ApiResponse<Account[]>> {
    return apiRequest.get(`/societes-support/${idSocieteSupport}/accounts`);
  },
};
