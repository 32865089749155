import { ReportingFilter } from "@/domain/reportingFilter";
import { ReportingGeneriqueData } from "@/domain/reportingGeneriqueData";
import { ReportingDataScopeFilter } from "@/domain/enum/reportingDataScopeFilter";
import { GroupLite } from "@domain/dto/groupLite";
import uniqBy from "lodash/uniqBy";
import { EngagementGlobalType } from "@domain/enum/engagementGlobalType";
import { ReportingRepartitionSousGroupe } from "@/domain/reportingRepartitionSousGroupe";
import { sum, toPercent } from "@/utils/numberUtils";
import orderBy from "lodash/orderBy";
import { uniq } from "lodash";
import { ReportingData } from "@domain/dto/reportingData";
import { OperationType } from "@domain/enum/operationType";

export function applyAllReportingFilters(
  data: ReportingData[],
  filter: ReportingFilter
): ReportingData[] {
  let filteredData = filterCurrentReportingYear(data, filter);
  filteredData = filterCurrentReportingOperationType(filteredData, filter);

  return applyReportingFilters(filteredData, filter);
}

function filterCurrentReportingOperationType(
  data: ReportingData[],
  filter: ReportingFilter
): ReportingData[] {
  return data.filter(
    (reporting) => reporting.type === filter.selectedOperationType
  );
}

export function applyAllOtherYearReportingFilters(
  data: ReportingData[],
  filter: ReportingFilter
): ReportingData[] {
  let filteredData = filterReportingOtherYear(data, filter);
  filteredData = filterCurrentReportingOperationType(filteredData, filter);
  return applyReportingFilters(filteredData, filter);
}

export function applyAllReportingFiltersForYear(
  data: ReportingData[],
  filter: ReportingFilter,
  year: number
): ReportingData[] {
  const filteredData = filterReportingByYear(data, year);
  return applyReportingFilters(filteredData, filter);
}

function applyReportingFilters(
  data: ReportingData[],
  filter: ReportingFilter
): ReportingData[] {
  let filteredData = filterReportingGroups(data, filter);
  filteredData = filterReportingPartners(filteredData, filter);
  filteredData = filterReportingPartnersParent(filteredData, filter);
  filteredData = filterReportingDemandeType(filteredData, filter);

  return filteredData;
}

// filtres
export function filterCurrentReportingYear(
  data: ReportingData[],
  filter: ReportingFilter
): ReportingData[] {
  return filterReportingByYear(data, filter.selectedCurrentYear);
}

export function filterReportingOtherYear(
  data: ReportingData[],
  filter: ReportingFilter
): ReportingData[] {
  return filterReportingByYear(data, filter.selectedOtherYear ?? 0);
}

function filterReportingByYear(
  data: ReportingData[],
  year: number
): ReportingData[] {
  return data.filter((reportingDataRow) => reportingDataRow.year === year);
}

export function filterReportingPartners(
  data: ReportingData[],
  filter: ReportingFilter
): ReportingData[] {
  return data.filter((reportingDataRow) =>
    filter.selectedPartnerList.some(
      (selectedPartner) => selectedPartner.id === reportingDataRow.partner?.id
    )
  );
}

export function filterReportingPartnersParent(
  data: ReportingData[],
  filter: ReportingFilter
): ReportingData[] {
  return data.filter(
    (reportingDataRow) =>
      !filter.selectedPartnerList.some(
        (selectedPartner) =>
          reportingDataRow.partner?.parent?.id === selectedPartner.id
      )
  );
}

export function filterReportingGroups(
  data: ReportingData[],
  filter: ReportingFilter
): ReportingData[] {
  return data.filter((reportingDataRow) =>
    filter.selectedGroupList.some(
      (selectedGroup) => selectedGroup.id === reportingDataRow.group.id
    )
  );
}

export function selectReportingScope(
  reportingData: ReportingData,
  filtres: ReportingFilter
): number {
  switch (filtres.selectedDataScope) {
    case ReportingDataScopeFilter.CA_TTC:
      return reportingData.chiffreAffaire;

    case ReportingDataScopeFilter.NOMBRE_OPERATION_CREES:
      return reportingData.nombreOperation;

    case ReportingDataScopeFilter.NOMBRE_ACTES_CREES:
      return actesNumber(reportingData, filtres);

    case ReportingDataScopeFilter.NOMBRE_LOTS_CREES:
      if (
        reportingData.type === OperationType.RESIDENTIELLE &&
        filtres.selectedOperationType === OperationType.RESIDENTIELLE
      ) {
        return reportingData.nombreLot;
      } else {
        return 0;
      }
    case ReportingDataScopeFilter.SURFACE_UTILE:
      if (
        reportingData.type === OperationType.TERTIAIRE &&
        filtres.selectedOperationType === OperationType.TERTIAIRE
      ) {
        return reportingData.superficie;
      } else {
        return 0;
      }
    default:
      return 0;
  }
}

export function actesNumber(
  reportingData: ReportingData,
  filtres: ReportingFilter
): number {
  return reportingData.demandeList.reduce((acc, demande) => {
    if (
      demande.type === EngagementGlobalType.GFA &&
      filtres.selectedDemandeTypeList.includes(EngagementGlobalType.GFA)
    ) {
      return acc + demande.nombreActe;
    }
    if (
      demande.type === EngagementGlobalType.CAUTION &&
      filtres.selectedDemandeTypeList.includes(EngagementGlobalType.CAUTION)
    ) {
      return acc + demande.nombreActe;
    }
    if (
      demande.type === EngagementGlobalType.CREDIT &&
      filtres.selectedDemandeTypeList.includes(EngagementGlobalType.CREDIT)
    ) {
      return acc + demande.nombreActe;
    }

    return acc;
  }, 0);
}

export function filterReportingDemandeType(
  data: ReportingData[],
  filter: ReportingFilter
): ReportingData[] {
  if (
    filter.selectedDataScope === ReportingDataScopeFilter.NOMBRE_ACTES_CREES
  ) {
    return data.filter((reportingDataRow) => {
      return filter.selectedDemandeTypeList.some((selectedDemandeType) => {
        return (
          reportingDataRow.demandeList.find((demande) => {
            return selectedDemandeType === demande.type;
          }) !== undefined
        );
      });
    });
  }
  return data;
}

export function filterReportingDataForPartner(
  data: ReportingGeneriqueData[],
  partner: GroupLite
): ReportingGeneriqueData[] {
  return data.filter((d) => d.partner?.id === partner.id);
}

export function convertToReportingGeneriqueData(
  data: ReportingData[],
  filter: ReportingFilter
): ReportingGeneriqueData[] {
  return data.map((reportingData) => {
    return {
      month: reportingData.month,
      year: reportingData.year,
      partner: reportingData.partner,
      value: selectReportingScope(reportingData, filter),
    };
  });
}

export function computeReportingGeneriqueDataForYear(
  data: ReportingData[],
  filter: ReportingFilter,
  year: number
): ReportingGeneriqueData[] {
  const reportingData = applyAllReportingFiltersForYear(data, filter, year);
  return convertToReportingGeneriqueData(reportingData, filter);
}

export function sumReportingDataValuesByMonth(
  reportingScopedDataList: ReportingGeneriqueData[]
): number[] {
  const data: number[] = [];
  for (let month = 1; month <= 12; month++) {
    const monthData = reportingScopedDataList.filter(
      (reportingScopedData) => reportingScopedData.month === month
    );
    const monthValue: number = monthData.reduce(function (acc, obj) {
      return acc + obj.value;
    }, 0);
    if (monthData) {
      // toggle filter data
      data.push(monthValue);
    } else {
      data.push(0);
    }
  }
  return data;
}

export function sumReportingDataValues(
  data: ReportingGeneriqueData[],
  partnerList: GroupLite[]
): { group: GroupLite; value: number }[] {
  return partnerList.map((partner) => {
    const partnerData = filterReportingDataForPartner(data, partner);
    const valuePartnerData = partnerData.map((d) => d.value);
    const dataSum = valuePartnerData.reduce(
      (acc, currentValue) => acc + currentValue,
      0
    );
    return { group: partner, value: dataSum };
  });
}

export function reportingDataToPartners(
  reportingDataList: ReportingData[]
): GroupLite[] {
  const partnerList = reportingDataList
    .map((reportingData) => reportingData.partner)
    .filter((partner): partner is GroupLite => Boolean(partner));
  return uniqBy(partnerList, (partner) => partner.id);
}

export function reportingDataToGroups(
  reportingDataList: ReportingData[]
): GroupLite[] {
  const groupList = reportingDataList.map(
    (reportingData) => reportingData.group
  );
  return orderBy(
    uniqBy(groupList, (group) => group.id),
    (group) => group.name
  );
}

export function toggleGroupFromList(
  groupList: GroupLite[],
  groupItem: GroupLite
): GroupLite[] {
  if (
    groupList.find((selectedPartner) => selectedPartner.id === groupItem.id) !==
    undefined
  ) {
    return groupList.filter(
      (selectedPartner) => selectedPartner.id !== groupItem.id
    );
  } else {
    return [...groupList, groupItem];
  }
}

export function toggleDemandeTypeFromList(
  demandeTypeList: EngagementGlobalType[],
  demandeTypeItem: EngagementGlobalType
): EngagementGlobalType[] {
  if (demandeTypeList.includes(demandeTypeItem)) {
    return demandeTypeList.filter(
      (selectedDemandeType) => selectedDemandeType !== demandeTypeItem
    );
  } else {
    return [...demandeTypeList, demandeTypeItem];
  }
}

export function getDataFilterLabel(
  scopeFilter: ReportingDataScopeFilter
): string {
  switch (scopeFilter) {
    case ReportingDataScopeFilter.CA_TTC:
      return "Chiffre d'Affaires TTC";
    case ReportingDataScopeFilter.NOMBRE_LOTS_CREES:
      return "Nombre de lots créés";
    case ReportingDataScopeFilter.NOMBRE_OPERATION_CREES:
      return "Nombre d'opérations créées";
    case ReportingDataScopeFilter.NOMBRE_ACTES_CREES:
      return "Nombre d'actes";
    case ReportingDataScopeFilter.SURFACE_UTILE:
      return "Surface utile";
    default:
      return "undefined";
  }
}

export function computeRepartitionForGroup(
  data: ReportingGeneriqueData[],
  group: GroupLite
): number {
  const partnerValueList = data
    .filter((d) => d.partner?.id === group.id)
    .map((d) => d.value);
  const allValueList = data.map((d) => d.value);

  if (partnerValueList.length === 0 || allValueList.length === 0) {
    return 0;
  }

  const groupSum = sum(partnerValueList);
  const allSum = sum(allValueList);

  return allSum !== 0 ? groupSum / allSum : 0;
}

export function computeRepartitionForSousGroupes(
  allReportingData: ReportingData[],
  filter: ReportingFilter
): ReportingRepartitionSousGroupe[] {
  const repartitionList: ReportingRepartitionSousGroupe[] = [];

  const filteredGroupListWithNoData = filter.selectedGroupList.filter(
    (group) => {
      return !allReportingData.find((data) => data.group.id === group.id);
    }
  );

  filteredGroupListWithNoData.forEach((filterGroupWithNoData) => {
    repartitionList.push({
      group: filterGroupWithNoData,
      repartitionList: [],
    });
  });

  allReportingData.forEach((data) => {
    const foundRepartition = repartitionList.find((repartition) =>
      sameGroupLite(repartition.group, data.group)
    );

    if (foundRepartition) {
      const foundRepartitionGroup = foundRepartition.repartitionList.find(
        (repartition) =>
          repartition.partnerGroup &&
          data.partner &&
          sameGroupLite(repartition.partnerGroup, data.partner)
      );
      if (foundRepartitionGroup) {
        foundRepartitionGroup.repartition += selectReportingScope(data, filter);
      } else {
        foundRepartition.repartitionList.push({
          partnerGroup: data.partner,
          repartition: selectReportingScope(data, filter),
        });
      }
    } else {
      repartitionList.push({
        group: data.group,
        repartitionList: [
          {
            partnerGroup: data.partner,
            repartition: selectReportingScope(data, filter),
          },
        ],
      });
    }
  });

  return [...repartitionList].sort(
    (repartition1, repartition2) =>
      -1 * repartition1.group.name.localeCompare(repartition2.group.name)
  );
}

function sameGroupLite(groupA: GroupLite, groupB: GroupLite): boolean {
  return groupA.id === groupB.id;
}

export function filterSubGroup(groupList: GroupLite[]): GroupLite[] {
  return groupList.filter((group) => group.parent !== undefined);
}

export function computeSumForSousGroupe(
  repartitionList: ReportingRepartitionSousGroupe[],
  group: GroupLite
): number {
  const foundRepartition = repartitionList.find((repartition) =>
    sameGroupLite(repartition.group, group)
  );
  return foundRepartition
    ? sum(foundRepartition.repartitionList.map((r) => r.repartition))
    : 0;
}

export function computePercentageRepartitionForSousGroupe(
  repartitionList: ReportingRepartitionSousGroupe[],
  group: GroupLite
): number {
  const sumForGroup = computeSumForSousGroupe(repartitionList, group);

  const globalSum = sum(
    repartitionList
      .flatMap((repartition) => repartition.repartitionList)
      .map((repartition) => repartition.repartition)
  );

  return sumForGroup / globalSum;
}

/**
 * Computes generic values for a specific partner group with every group.
 * Used in echarts subgroup graph
 *
 * @param repartitionList
 * @param partner
 */
export function findValueListForPartnerGroup(
  repartitionList: ReportingRepartitionSousGroupe[],
  partner: GroupLite
): number[] {
  return repartitionList.map((repartition) => {
    const partnerRepartition = repartition.repartitionList.find(
      (r) => r.partnerGroup && sameGroupLite(r.partnerGroup, partner)
    );
    return partnerRepartition ? partnerRepartition.repartition : 0;
  });
}

export function computeBarStackLabel(
  param: { name: string },
  filter: ReportingFilter,
  repartitionList: ReportingRepartitionSousGroupe[]
): string {
  const group = filter.selectedGroupList.find(
    (selectedGroup) => selectedGroup.name === param.name
  );

  const repartitionPercentage = group
    ? toPercent(
        computePercentageRepartitionForSousGroupe(repartitionList, group)
      )
    : 0;
  const sumLabel = group
    ? computeBarStackSumLabel(group, filter, repartitionList)
    : "";

  return `{boldStyle|${repartitionPercentage}%}\n${sumLabel}`;
}

export function computeYearDifference(
  param: { name: string },
  filter: ReportingFilter,
  repartitionList: ReportingRepartitionSousGroupe[],
  comparaisonYearRepartitionList: ReportingRepartitionSousGroupe[]
): string {
  const group = filter.selectedGroupList.find(
    (selectedGroup) => selectedGroup.name === param.name
  );
  if (!group) {
    return "";
  }

  const currentYearSum = computeSumForSousGroupe(repartitionList, group);
  const comparaisonYearSum = computeSumForSousGroupe(
    comparaisonYearRepartitionList,
    group
  );

  if (Number(comparaisonYearSum) === 0 || Number(currentYearSum) === 0) {
    return `{orangeStyle| ${Number(
      comparaisonYearSum
    )} ${createSelectedScopeLabel(filter.selectedDataScope)} en ${
      filter.selectedOtherYear
    }}`;
  } else {
    const percentage =
      Math.round(
        ((Number(currentYearSum) - Number(comparaisonYearSum)) /
          Number(comparaisonYearSum)) *
          100 *
          100
      ) / 100;
    if (percentage > 0) {
      return `{greenStyle|+${percentage}% depuis ${filter.selectedOtherYear}}`;
    } else {
      return `{redStyle|${percentage}% depuis ${filter.selectedOtherYear}}`;
    }
  }
}

function createSelectedScopeLabel(
  selectedDataScope: ReportingDataScopeFilter
): string {
  const labelByScope = new Map([
    [ReportingDataScopeFilter.CA_TTC, "€"],
    [ReportingDataScopeFilter.NOMBRE_LOTS_CREES, " lots"],
    [ReportingDataScopeFilter.NOMBRE_OPERATION_CREES, " opérations"],
    [ReportingDataScopeFilter.NOMBRE_ACTES_CREES, " actes"],
  ]);

  return labelByScope.get(selectedDataScope) || "";
}

export function computeBarStackSumLabel(
  group: GroupLite,
  filter: ReportingFilter,
  repartitionList: ReportingRepartitionSousGroupe[]
): string {
  const dataSum = computeSumForSousGroupe(repartitionList, group);

  return `${dataSum}${createSelectedScopeLabel(filter.selectedDataScope)}`;
}

export function computeAvailableYears(data: ReportingData[]): number[] {
  const filtredData = data.filter((d) => d.year !== 0);
  const uniqueYears = new Set(filtredData.map((d) => d.year));
  const years = [...uniqueYears];
  return years.sort((a, b) => a - b);
}

export function mutateDataToPartnerParentChildGroupList(
  rawData: ReportingData[]
): {
  mutatedData: ReportingData[];
  partnerParentList: GroupLite[];
} {
  const partnerParentList: GroupLite[] = [];
  const mutatedData: ReportingData[] = [];

  rawData.forEach((data) => {
    if (data.partner?.parent?.id) {
      if (
        partnerParentList.find(
          (group) => group.id === data.partner?.parent?.id
        ) === undefined
      ) {
        partnerParentList.push(data.partner.parent);
      }
    }
  });

  for (const data of rawData) {
    let foundParentIndex = -1;
    let isChild = false;
    let isMaster = false;

    if (!data.partner?.parent?.id) {
      // doesn't have a parent ( he is a parent or a single group )
      isMaster = true;
    }

    for (const [indexJ, partnerParent] of partnerParentList.entries()) {
      if (data.partner?.id === partnerParent.id) {
        // data belongs to a parent partner
        foundParentIndex = indexJ;
      } else if (data.partner?.parent?.id === partnerParent.id) {
        // data belongs to a partner child
        isChild = true;
      }
    }

    const isParent = foundParentIndex !== -1;

    if (isMaster && !isParent) {
      // single groupe just copy data
      mutatedData.push(data);
    } else if (!isMaster && isChild) {
      // child group copy his data and duplicate the same data for his parent
      mutatedData.push(data);
      mutatedData.push({
        ...data,
        partner: data.partner?.parent,
      });
    } else if (isMaster && isParent && data.partner) {
      // parent group copy his data and duplicate it and attribute to new group with name "Siège" ( create new group Siège )
      mutatedData.push(data);
      mutatedData.push({
        ...data,
        partner: {
          ...data.partner,
          id: String(foundParentIndex),
          name: "Siège",
          parent: data.partner,
        },
      });
    }
  }

  return { mutatedData, partnerParentList };
}

function isGroupSelected(
  selectedGroupList: GroupLite[],
  groupToToggle: GroupLite
): boolean {
  return (
    selectedGroupList.find(
      (selectedPartner) => selectedPartner.id === groupToToggle.id
    ) !== undefined
  );
}

function isAParentGroup(
  partnerParentList: GroupLite[],
  groupToToggle: GroupLite
): GroupLite | undefined {
  return partnerParentList.find((parent) => parent.id === groupToToggle.id);
}

export function togglePartnerAndParentFromList(
  allPartner: GroupLite[],
  selectedGroupList: GroupLite[],
  groupToToggle: GroupLite,
  partnerParentList: GroupLite[]
): GroupLite[] {
  if (isGroupSelected(selectedGroupList, groupToToggle)) {
    if (isAParentGroup(partnerParentList, groupToToggle) !== undefined) {
      let filteredGroup = selectedGroupList.filter(
        (selectedPartner) => selectedPartner.id !== groupToToggle.id
      );

      const partnerChildrenIdList = allPartner
        .filter((partner) => partner.parent?.id === groupToToggle.id)
        .map((child) => child.id);

      for (const partnerChildrenId of partnerChildrenIdList) {
        filteredGroup = filteredGroup.filter(
          (selectedPartner) => selectedPartner.id !== partnerChildrenId
        );
      }
      return filteredGroup;
    } else {
      // add only the group to the list
      // retirer le groupe de la liste
      const filteredGroup = selectedGroupList.filter(
        (selectedPartner) => selectedPartner.id !== groupToToggle.id
      );

      // retirer le parent du groupe
      return filteredGroup.filter(
        (selectedPartner) => selectedPartner.id !== groupToToggle.parent?.id
      );
    }
  } else {
    if (isAParentGroup(partnerParentList, groupToToggle) !== undefined) {
      // add group and his children
      const partnerChildren = allPartner.filter(
        (partner) => partner.parent?.id === groupToToggle.id
      );
      return uniq([...selectedGroupList, groupToToggle, ...partnerChildren]);
    } else {
      // add only the group to the list
      return uniq([...selectedGroupList, groupToToggle]);
    }
  }
}
