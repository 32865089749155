// @ts-ignore
import XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { SuiviBudgetDocumentUpload } from "@/domain/suiviBudgetDocumentUpload";
import { OperationHeavy } from "@/domain/operationHeavy";
import { SelectOptionType } from "@/domain/dto/selectOptionType";
import { v4 as uuid } from "uuid";
import { getDocNameOnly, getDocTypeOnly } from "@/utils/formatNameUtils";
import { SuiviBudgetImportType } from "@domain/enum/suiviBudgetImportType";
import {
  SuiviBudgetImportCreation,
  SuiviBudgetImportDocumentCreation,
} from "@/domain/suiviBudgetImportCreation";
import { containsForbiddenCharacters } from "./documentUtils";
import { groupBy } from "lodash";
import { NemmoMimeType } from "@domain/enum/nemmoMimeType";
import { getDocExtension } from "@domain/utils/documentExtensionsUtils";

export function isSuiviBudgetPromoteurImportDocumentCorrectType(
  document: SuiviBudgetDocumentUpload
): boolean {
  return [NemmoMimeType.EXCEL, NemmoMimeType.XLS].includes(
    document.file.type as NemmoMimeType
  );
}

export function isSuiviBudgetPromoteurImportFileCorrectExtension(
  file: File
): boolean {
  const allowedExtensionList = ["CSV", "XLSX", "XLS"];
  return allowedExtensionList.includes(
    getDocExtension(file.name).toUpperCase()
  );
}

export async function computeSuiviBudgetPromoteurDocumentSheetList(
  document: SuiviBudgetDocumentUpload
): Promise<string[]> {
  const workbook = await XlsxPopulate.fromDataAsync(document.file);
  return workbook.sheets().map((sheet: any) => sheet.name());
}

export function computeBanqueOptionsFromOperation(
  operation: OperationHeavy
): SelectOptionType[] {
  const operationGroups = operation.groups;

  const banqueIdList = operation.demandeList.map((demande) => demande.idBanque);

  return operationGroups
    .filter((group) => banqueIdList.includes(group.id))
    .map((group) => ({ option: group.name, value: group.id }));
}

export function addMultipleFilesSuiviBudgetPromoteur(
  existingDocumentList: SuiviBudgetDocumentUpload[],
  files: File[]
): SuiviBudgetDocumentUpload[] {
  const importedFiles = files.filter((file) =>
    isSuiviBudgetPromoteurImportFileCorrectExtension(file)
  );
  const documentListToAdd = importedFiles.map<SuiviBudgetDocumentUpload>(
    (file) => {
      return {
        id: uuid(),
        name: getDocNameOnly(file.name),
        extension: getDocTypeOnly(file.name),
        file: file,
        type: SuiviBudgetImportType.GLOBAL,
      };
    }
  );

  return [...existingDocumentList, ...documentListToAdd];
}

export function buildSuiviBudgetImportCreation(
  idOperation: string,
  idBanque: string,
  documents: SuiviBudgetDocumentUpload[]
): SuiviBudgetImportCreation {
  const documentList = documents.map<SuiviBudgetImportDocumentCreation>(
    (document) => ({
      file: document.file,
      newName: `${document.name}.${document.extension}`,
      typeImport: document.type,
      nomTranche: document.trancheName,
      sheetName: document.sheetName,
    })
  );

  return {
    documentList,
    idOperation,
    idBanque,
  };
}

export enum ErrorSuiviBudgetDocumentUpload {
  NOM_TRANCHE_TROP_COURT,
  NOM_TRANCHE_TROP_LONG,
  NOM_TRANCHE_INVALIDE,
  NOM_FICHIER_TROP_COURT,
  NOM_FICHIER_TROP_LONG,
  NOM_FICHIER_INVALIDE,
}

export interface ValidationSuiviBudgetDocumentUpload {
  id: string;
  errors: ErrorSuiviBudgetDocumentUpload[];
}

export enum ErrorSuiviBudgetDocumentUploadBatch {
  MULTIPLE_GLOBAL,
  MULTIPLE_TRANCHE_MEME_NOM,
}

export interface ErrorSuiviBudgetDocumentBatch {
  erreurs: ErrorSuiviBudgetDocumentUploadBatch[];
  validations: ValidationSuiviBudgetDocumentUpload[];
}

export function computeErrorsSuiviBudgetImport(
  documents: SuiviBudgetDocumentUpload[]
): ErrorSuiviBudgetDocumentBatch {
  const validations = documents.map((document) =>
    computeErrorSuiviBudgetImport(document)
  );
  const erreurs: ErrorSuiviBudgetDocumentUploadBatch[] = [];

  const globalImports = documents.filter(
    (document) => document.type === SuiviBudgetImportType.GLOBAL
  );

  if (globalImports.length > 1) {
    erreurs.push(ErrorSuiviBudgetDocumentUploadBatch.MULTIPLE_GLOBAL);
  }

  const trancheImports = documents.filter(
    (document) => document.type === SuiviBudgetImportType.TRANCHE
  );

  const trancheImportsByName = groupBy(
    trancheImports,
    (tranche) => tranche.trancheName
  );
  const multipleTrancheName = Object.values(trancheImportsByName).some(
    (trancheImportsName) => trancheImportsName.length > 1
  );

  if (multipleTrancheName) {
    erreurs.push(ErrorSuiviBudgetDocumentUploadBatch.MULTIPLE_TRANCHE_MEME_NOM);
  }

  return {
    erreurs,
    validations,
  };
}

export function computeErrorSuiviBudgetImport(
  document: SuiviBudgetDocumentUpload
): ValidationSuiviBudgetDocumentUpload {
  const validation: ValidationSuiviBudgetDocumentUpload = {
    id: document.id,
    errors: [],
  };
  if (document.type === SuiviBudgetImportType.TRANCHE) {
    if (
      document.trancheName === undefined ||
      document.trancheName.trim().length === 0
    ) {
      validation.errors.push(
        ErrorSuiviBudgetDocumentUpload.NOM_TRANCHE_TROP_COURT
      );
    } else if (document.trancheName.length > 50) {
      validation.errors.push(
        ErrorSuiviBudgetDocumentUpload.NOM_TRANCHE_TROP_LONG
      );
    }
    if (
      document.trancheName !== undefined &&
      containsForbiddenCharacters(document.trancheName)
    ) {
      validation.errors.push(
        ErrorSuiviBudgetDocumentUpload.NOM_TRANCHE_INVALIDE
      );
    }
  }

  if (document.name.trim().length <= 0) {
    validation.errors.push(
      ErrorSuiviBudgetDocumentUpload.NOM_FICHIER_TROP_COURT
    );
  } else if (document.name.length > 85) {
    validation.errors.push(
      ErrorSuiviBudgetDocumentUpload.NOM_FICHIER_TROP_LONG
    );
  }

  if (containsForbiddenCharacters(document.name)) {
    validation.errors.push(ErrorSuiviBudgetDocumentUpload.NOM_FICHIER_INVALIDE);
  }
  return validation;
}
