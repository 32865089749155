<template>
  <div v-if="getIsWarningIE" id="ie-warning-bar" data-test="ie-warning-bar">
    <div class="info">
      <span>
        Neomi est optimisé pour une navigation sur les dernières versions de
        Firefox, Microsoft Edge, Chrome, Safari & Opéra</span
      >
    </div>
    <img
      alt="close ie warning"
      data-test="close-button"
      src="@/assets/cross.svg"
      @click="close"
    />
  </div>
</template>

<script lang="ts">
import { getSetupContext } from "@/utils/vueUtils";
import { computed, defineComponent } from "vue";

export default defineComponent({
  setup() {
    const { supportedBrowserInformationStore } = getSetupContext();

    const getIsWarningIE = computed((): boolean => {
      return supportedBrowserInformationStore.getIsWarningIE;
    });

    const ua = window.navigator.userAgent;
    if (ua.indexOf("Trident/") >= 0 || ua.indexOf("MSIE ") >= 0) {
      supportedBrowserInformationStore.SetWarningIE(true);
    }

    function close(): void {
      supportedBrowserInformationStore.SetWarningIE(false);
    }

    return {
      // Computed
      getIsWarningIE,

      // Method
      close,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../sass/variables";

#ie-warning-bar {
  background-color: $primary-main;
  width: 100%;
  z-index: 10;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 72px;
  font-weight: 600;

  .info {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: left;
  }

  img {
    cursor: pointer;
    width: 32px;
    color: black;
  }
}
</style>
