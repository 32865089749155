<template>
  <div class="banner-specific-container">
    <div class="icon-container">
      <img
        alt="icône ajouter document"
        class="banner-icon"
        src="@/assets/event/AddDoc.svg"
      />
    </div>
    <div class="content mini">
      {{ message }}
      <span class="semi-bold" data-test="operation-name">
        {{ notification.payload.operationName }}
      </span>
      !
    </div>
  </div>
</template>

<script lang="ts">
import { Notification } from "@/domain/notification";
import { computed, defineComponent } from "vue";
export default defineComponent({
  props: {
    notification: {
      type: Object as () => Notification,
      required: true,
    },
  },
  setup(props) {
    const message = computed((): string => {
      const numberUploadedDocuments =
        props.notification.payload.numberUploadedDocuments;
      const cmpCount = props.notification.payload.cmpCount;

      if (numberUploadedDocuments && cmpCount) {
        if (numberUploadedDocuments > 1 && cmpCount > 1) {
          return messageMultipleDocumentMultipleCmpNotification.value;
        } else if (numberUploadedDocuments > 1 && cmpCount === 1) {
          return messageMultipleDocumentSingleCmpNotification.value;
        } else {
          return messageSingleDocumentSingleCmpNotification.value;
        }
      } else {
        return messageSingleDocumentSingleCmpNotification.value;
      }
    });

    const messageSingleDocumentSingleCmpNotification = computed((): string => {
      return `${props.notification.payload.numberUploadedDocuments} document a été déposée dans l'opération correspondant à une condition de mise en place`;
    });

    const messageMultipleDocumentSingleCmpNotification = computed(
      (): string => {
        return `${props.notification.payload.numberUploadedDocuments} documents ont été déposée dans l'opération correspondant à une condition de mise en place`;
      }
    );

    const messageMultipleDocumentMultipleCmpNotification = computed(
      (): string => {
        return `${props.notification.payload.numberUploadedDocuments} documents ont été déposée dans l'opération correspondant à ${props.notification.payload.cmpCount} conditions de mise en place`;
      }
    );

    return {
      message,
    };
  },
});
</script>

<style lang="scss" scoped>
.banner-specific-container {
  display: flex;
}
</style>
