import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import { OperationDocumentCheck } from "@/domain/operationDocumentCheck";
import operationDocumentCheckApi from "@/api/operationDocumentCheckApi";
import findIndex from "lodash/findIndex";
import { OperationDocumentCheckUpdateRequest } from "@/domain/dto/demandeDocumentCheckUpdateRequest";
import { OperationHeavy } from "@/domain/operationHeavy";

type OperationDocumentCheckType = {
  operationDocumentCheckList: OperationDocumentCheck[];
};

export const useOperationDocumentCheckStore = defineStore(
  ModuleType.OperationDocumentCheck,
  {
    state: (): OperationDocumentCheckType => ({
      operationDocumentCheckList: [],
    }),
    getters: {
      getOperationDocumentCheckList(state): OperationDocumentCheck[] {
        return state.operationDocumentCheckList;
      },
    },
    actions: {
      SetOperationDocumentCheckList(
        operationDocumentCheckList: OperationDocumentCheck[]
      ): void {
        this.operationDocumentCheckList = operationDocumentCheckList;
      },

      UpdateOperationDocumentCheck(
        operationDocumentCheck: OperationDocumentCheck
      ): void {
        const index = findIndex(this.operationDocumentCheckList, {
          id: operationDocumentCheck.id,
        });
        this.operationDocumentCheckList.splice(
          index,
          1,
          operationDocumentCheck
        );
      },

      async fetchOperationDocumentCheckList(
        operation: OperationHeavy
      ): Promise<OperationDocumentCheck[]> {
        const response = await operationDocumentCheckApi.fetchAll(operation.id);
        this.SetOperationDocumentCheckList(response.data);
        return response.data;
      },

      async updateOperationDocumentCheck(payload: {
        idCheck: string;
        idOperation: string;
        checked: boolean;
      }): Promise<OperationDocumentCheck> {
        const operationDocumentCheckUpdateRequest: OperationDocumentCheckUpdateRequest =
          {
            id: payload.idCheck,
            value: payload.checked,
          };
        const response = await operationDocumentCheckApi.update(
          payload.idOperation,
          operationDocumentCheckUpdateRequest
        );
        this.UpdateOperationDocumentCheck(response.data);
        return response.data;
      },
    },
  }
);
