import { DemandeCreationInfo } from "@/domain/demandeCreationInfo";
import { DemandeCreationRequest } from "@domain/dto/demandeCreationRequest";
import { Demande } from "@/domain/demande";
import { DemandeType } from "@domain/enum/demandeType";
import { v4 as uuid } from "uuid";
import {
  demandeCategoryLabel,
  isCautionType,
  isCreditType,
  isGfaType,
} from "@/utils/demandeUtils";
import { DemandeUpdateRequest } from "@domain/dto/demandeUpdateRequest";
import { ActeSecondaireCreationRequest } from "@domain/dto/acteSecondaireCreationRequest";
import { isEmpty } from "@/utils/validationFormUtils";
import { Role } from "@domain/enum/role";

export function createDemandeCreationRequestList(
  creationInfos: DemandeCreationInfo[],
  existingDemandes: Demande[],
  idSocieteSupport: string
): DemandeCreationRequest[] {
  return creationInfos.flatMap((info) =>
    createDemandeCreationRequestFromInfo(
      info,
      existingDemandes,
      idSocieteSupport
    )
  );
}

export function createDemandeCreationRequestFromInfo(
  creationInfo: DemandeCreationInfo,
  existingDemandes: Demande[],
  idSocieteSupport: string
): DemandeCreationRequest[] {
  const numberExistingDemandesWithSameType = existingDemandes.filter(
    (demande) => demande.type === creationInfo.type
  ).length;
  let currentDemandeTypeNumber = numberExistingDemandesWithSameType + 1;

  const requests: DemandeCreationRequest[] = [];
  for (let i = 0; i < creationInfo.count; i++) {
    requests.push({
      formId: uuid(),
      name: formatDemandeName(creationInfo.type, currentDemandeTypeNumber),
      type: creationInfo.type,
      expectedDate: new Date(),
      euroCentimeAmount: 0,
      idSocieteSupport: creationInfo.idSocieteSupport ?? idSocieteSupport,
    });
    currentDemandeTypeNumber++;
  }
  return requests;
}

function formatDemandeName(type: DemandeType, number: number): string {
  return `${demandeTypeLabel(type)} ${number}`;
}

function demandeTypeLabel(demandeType: DemandeType): string {
  switch (demandeType) {
    case DemandeType.GARANTIE_FINANCIERE_ACHEVEMENT:
      return "Garantie financière d'achèvement";
    case DemandeType.CAUTION_INDEMNITE_IMMOBILISATION:
      return "Caution d’indemnité d'immobilisation";
    case DemandeType.GARANTIE_PAIEMENT_TERME:
      return "Caution de paiement à terme";
    case DemandeType.CAUTION_PAIEMENT_TRAVAUX:
      return "Caution de paiement des travaux";
    case DemandeType.AUTRE_CAUTION:
      return "Autre caution";
    case DemandeType.CREDIT_TERRAIN:
      return "Crédit terrain";
    case DemandeType.CREDIT_ACCOMPAGMENT:
      return "Crédit d'accompagnement";
    case DemandeType.AUTRE_CREDIT:
      return "Autre crédit";
    default:
      return "";
  }
}

export const NAME_MAX_LENGTH = 87;
export const MAX_LENGTH_NAME_TEXT = 50;

export function isDemandeCreationValid(
  demande: DemandeCreationRequest
): boolean {
  return (
    demande.name !== "" &&
    (demande.euroCentimeAmount ?? 0) < Number.MAX_SAFE_INTEGER &&
    demande.expectedDate !== undefined &&
    !doesExceedMaximumLength(demande.name, NAME_MAX_LENGTH) &&
    isCreationValid(demande) &&
    isCautionCreditCreationValid(demande)
  );
}

function isCreationValid(demande: DemandeCreationRequest): boolean {
  return isGfaType(demande.type)
    ? demande.datePrevisionnelleAchevement !== undefined
    : (demande.euroCentimeAmount ?? 0) > 0;
}

function isUpdateValid(
  demande: DemandeUpdateRequest,
  demandeType: DemandeType
): boolean {
  return isGfaType(demandeType)
    ? demande.datePrevisionnelleAchevement !== undefined
    : !!demande.euroCentimeAmount && demande.euroCentimeAmount > 0;
}

function isCautionCreditCreationValid(
  demande: DemandeCreationRequest
): boolean {
  return isCautionType(demande.type) || isCreditType(demande.type)
    ? demande.validityDate !== undefined
    : true;
}

function isCautionCreditUpdateValid(
  demande: DemandeUpdateRequest,
  demandeType: DemandeType
): boolean {
  return isCautionType(demandeType) || isCreditType(demandeType)
    ? demande.validityDate !== undefined
    : true;
}

export function doesExceedMaximumLength(
  name: string | undefined,
  maxLength: number
): boolean {
  return !!name && name.length > maxLength;
}

export function formatActeSecondaireName(
  demandeType: DemandeType,
  count: number
): string {
  return `Attestation de ${demandeCategoryLabel(demandeType)} - ${String(
    count
  ).padStart(2, "0")}`;
}

export function isDemandeUpdateValid(
  demande: DemandeUpdateRequest,
  demandeType: DemandeType
): boolean {
  return (
    demande.titreDemandePrincipale !== "" &&
    demande.expectedDate !== undefined &&
    !doesExceedMaximumLength(demande.titreDemandePrincipale, NAME_MAX_LENGTH) &&
    isUpdateValid(demande, demandeType) &&
    isCautionCreditUpdateValid(demande, demandeType)
  );
}

export function formatUpdateRequestByRole(
  demande: DemandeUpdateRequest,
  demandeType: DemandeType,
  role: Role
): DemandeUpdateRequest | undefined {
  if (role === Role.PROMOTEUR) {
    return {
      id: demande.id,
      titreDemandePrincipale: demande.titreDemandePrincipale,
      datePrevisionnelleAchevement: demande.datePrevisionnelleAchevement,
      validityDate: demande.validityDate,
      expectedDate: demande.expectedDate,
      euroCentimeAmount: !isGfaType(demandeType)
        ? demande.euroCentimeAmount
        : undefined,
    };
  } else if (role === Role.BANQUE) {
    return {
      numeroActe: demande.numeroActe,
    };
  }
}

export function isActeSecondaireCreationValid(
  acteSecondaireCreationRequest: ActeSecondaireCreationRequest | undefined
): boolean {
  if (acteSecondaireCreationRequest) {
    return [
      ...acteSecondaireCreationRequest.attestationList.map((acte) => acte.name),
      acteSecondaireCreationRequest.convention.name,
    ].every(
      (name) =>
        !doesExceedMaximumLength(name, NAME_MAX_LENGTH) && !isEmpty(name)
    );
  } else return false;
}
