<template>
  <DialogRoot :modal="true">
    <DialogPortal>
      <DialogOverlay :force-mount="true" class="modal-mask" />
      <DialogContent
        :force-mount="true"
        @interact-outside="closeModal"
        @escape-key-down="closeModal"
      >
        <div v-bind="$attrs" class="modal-wrapper">
          <div :style="{ width: modalWidth }" class="modal-container">
            <div v-if="showHeader" class="modal-header">
              <DialogTitle>
                <slot name="header"></slot>
              </DialogTitle>
            </div>
            <div class="modal-body" :class="{ scrollable: isScrollable }">
              <DialogDescription>
                <slot name="body"></slot>
              </DialogDescription>
            </div>
            <div v-if="showFooter" class="modal-footer">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </DialogContent>
    </DialogPortal>
  </DialogRoot>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
} from "radix-vue";
import { NeomiEvent } from "@/domain/neomiEvent";

export default defineComponent({
  name: "ModalComponent",
  components: {
    DialogRoot,
    DialogPortal,
    DialogContent,
    DialogOverlay,
    DialogDescription,
    DialogTitle,
  },
  inheritAttrs: false,
  props: {
    modalWidth: {
      type: String,
      default: "642px",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    isScrollable: {
      type: Boolean,
      default: false,
    },
  },
  emits: [NeomiEvent.CLOSE_MODAL],
  setup(_props, context) {
    function closeModal() {
      context.emit(NeomiEvent.CLOSE_MODAL);
    }

    return {
      closeModal,
    };
  },
});
</script>

<style lang="scss">
@import "@/sass/variables.scss";

.modal-mask {
  position: fixed;
  z-index: 1500;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  cursor: pointer;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1501;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  border-radius: 10px;
  transform: translate(-50%, -50%);
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  .modal-header {
    height: 64px;
    border-bottom: solid 1px $primary-main;
    display: flex;
    align-items: center;
    box-shadow: $shadow-1;

    span {
      margin: 0 24px;
      font-size: 18px;
    }
  }

  .modal-body {
    padding: 16px 24px;

    &.scrollable {
      max-height: calc(100vh - 137px);
      overflow-y: auto;
    }

    .instruction {
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .modal-footer {
    display: flex;
    height: 73px;
    justify-content: flex-end;
    padding: 12px 24px;
    border-top: solid 1px $border-color-3;

    > div {
      display: flex;

      .spinner-container {
        width: 53px;
        display: flex;
        align-content: center;
        justify-content: center;
      }

      button {
        margin: 0 8px;
      }
    }
  }
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
</style>
