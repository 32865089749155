import { apiRequest, ApiResponse } from "@/apiRequest";
import { OperationDocumentCategoryPrediction } from "@/domain/operationDocumentCategoryPrediction";

export default {
  predictOperationDocumentCategory(
    filename: string
  ): Promise<ApiResponse<OperationDocumentCategoryPrediction>> {
    return apiRequest.get("/prediction/operation-document/category", {
      params: { filename },
    });
  },
};
