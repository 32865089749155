import dashboardHommeDeArtApi from "@/api/dashboardHommeDeArtApi";
import { HommeDeArtDemandeHeavy } from "@/domain/hommeDeArtDemandeHeavy";
import { HommeDeArtOperation } from "@/domain/hommeDeArtOperation";
import { createDemandeHommeDeArt } from "@/utils/demandeUtils";
import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";

type DashboardHommeDeArtState = {
  operationList: HommeDeArtOperation[];
  demande: HommeDeArtDemandeHeavy;
};

export const useDashboardHommeDeArtStore = defineStore(
  ModuleType.DashboardHommeDeArt,
  {
    state: (): DashboardHommeDeArtState => ({
      operationList: [],
      demande: createDemandeHommeDeArt(),
    }),
    getters: {
      getOperationList(state): HommeDeArtOperation[] {
        return state.operationList;
      },
      getDemande(state): HommeDeArtDemandeHeavy {
        return state.demande;
      },
    },
    actions: {
      SetOperationList(operationList: HommeDeArtOperation[]): void {
        this.operationList = operationList;
      },
      SetDemande(demandeHeavy: HommeDeArtDemandeHeavy): void {
        this.demande = demandeHeavy;
      },
      async fetchOperationList(): Promise<HommeDeArtOperation[]> {
        const response = await dashboardHommeDeArtApi.fetchOperationList();
        this.SetOperationList(response.data);
        return response.data;
      },
      async fetchDemande(
        idOperation: string,
        idDemande: string
      ): Promise<HommeDeArtDemandeHeavy> {
        const response = await dashboardHommeDeArtApi.fetchDemandeHeavy(
          idOperation,
          idDemande
        );
        this.SetDemande(response.data);
        return response.data;
      },
    },
  }
);
