import { apiRequest, ApiResponse } from "@/apiRequest";
import { OperationSurete } from "@domain/dto/operationSurete";

export default {
  getByIdOperation(
    idOperation: string
  ): Promise<ApiResponse<OperationSurete[]>> {
    return apiRequest.get(`/operations/${idOperation}/suretes`);
  },
};
