import { Role } from "@domain/enum/role";
import { useDocumentStore } from "@/store/documentModule.pinia";
import { useToastStore } from "@/store/toastModule.pinia";
import { getActivePinia } from "pinia";

export function getEditRoomName(idDocument: string, groupRole: Role): string {
  return `${idDocument}-${groupRole}`;
}

export function handleSingleFileForMultipleDocumentUpload(payload: {
  isUploadSuccess: boolean;
}): void {
  const pinia = getActivePinia();
  const documentStore = useDocumentStore(pinia);
  const toastStore = useToastStore(pinia);

  documentStore.IncrementProcessedDocumentsToUpload();

  const isMultipleDocumentUpload = documentStore.isMultipleDocumentUpload;

  if (!isMultipleDocumentUpload) {
    return;
  }

  if (payload.isUploadSuccess) {
    documentStore.IncrementTotalDocumentsUploaded();
  }

  const hasMultipleDocumentUploadToast = toastStore.hasMultipleDocumentUpload;

  if (!hasMultipleDocumentUploadToast) {
    toastStore.prependMultipleDocumentUpload();
  }

  if (documentStore.getProcessedDocumentCount > 1) {
    toastStore.updateMultipleDocumentUpload();
  }
}
