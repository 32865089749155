export enum ModuleType {
  Annuaire = "annuaire",
  Keycloak = "keycloak",
  Demande = "demande",
  Account = "account",
  Dashboard = "dashboard",
  OperationDocumentCheck = "operationDocumentCheck",
  Panel = "panel",
  Group = "group",
  PoolAccordCommercial = "poolAccordCommercial",
  User = "user",
  Tiers = "tiers",
  Util = "util",
  OperationChat = "operationChat",
  Operation = "operation",
  OperationSuiviCommercialisation = "OperationSuiviCommercialisation",
  Feature = "feature",
  Document = "document",
  LettreAccord = "lettreAccord",
  Surete = "surete",
  PoolFormalise = "poolFormalise",
  EditDocument = "editDocument",
  EditESGDocument = "editESGDocument",
  EditLettreAccordDocument = "editLettreAccordDocument",
  EditSureteDocument = "editSureteDocument",
  Notification = "notification",
  SupportedBrowserInformation = "supportedBrowserInformation",
  Pool = "pool",
  Route = "route",
  Signature = "signature",
  UserOperation = "userOperation",
  UserProfile = "userProfile",
  Reporting = "reporting",
  SuiviCommercialisation = "suiviCommercialisation",
  Prediction = "prediction",
  Referent = "referent",
  SocieteSupport = "societeSupport",
  Mainlevee = "mainlevee",
  DashboardHommeDeArt = "dashboardHommeDeArt",
  SuiviTravaux = "suiviTravaux",
  SuiviTravauxMassif = "suiviTravauxMassif",
  SuiviBudget = "suiviBudget",
  Facturation = "facturation",
  ESG = "esg",
  Toast = "toast",
  Loader = "loader",
}
