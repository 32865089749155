import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { OperationCreationRequest } from "@domain/dto/operationCreationRequest";
import { OperationHeavy, TiersOperationHeavy } from "@/domain/operationHeavy";
import { OperationLight } from "@/domain/dto/operationLight";
import { OperationUpdateRequest } from "@domain/dto/operationUpdateRequest";
import { OperationFormErrorType } from "@/domain/enum/operationFormErrorType";
import { DashboardFilter } from "@domain/dto/dashboardFilter";
import { OperationSearchRequest } from "@domain/dto/operationSearchRequest";
import { OperationCountRequest } from "@domain/dto/operationCountRequest";
import { OperationCount } from "@domain/dto/operationCount";
import { OperationArchiveHeavy } from "@/domain/operationArchivedHeavy";
import { SocieteSupport } from "@/domain/societeSupport";

export default {
  create(
    operation: OperationCreationRequest
  ): Promise<ApiResponse<OperationHeavy | OperationFormErrorType[]>> {
    return apiRequestThrottle.post("/operations", operation, {
      validateStatus(status) {
        return status === 201 || status === 409;
      },
    });
  },

  update(
    updatedField: OperationUpdateRequest
  ): Promise<ApiResponse<OperationHeavy>> {
    return apiRequestThrottle.patch(
      `/operations/${updatedField.idOperation}`,
      updatedField,
      {
        validateStatus(status) {
          return status === 200 || status === 409;
        },
      }
    );
  },

  updateReferenceBanque(
    updatedField: OperationUpdateRequest
  ): Promise<ApiResponse<{ referenceBanque: string }>> {
    return apiRequestThrottle.patch(
      `/operations/${updatedField.idOperation}/reference-banque`,
      updatedField,
      {
        validateStatus(status) {
          return status === 200 || status === 409;
        },
      }
    );
  },

  updateOperationBanqueReferent(
    updatedField: OperationUpdateRequest
  ): Promise<ApiResponse<OperationHeavy>> {
    return apiRequestThrottle.patch(
      `/operations/${updatedField.idOperation}/banque-referent`,
      updatedField
    );
  },

  delete(idOperation: string): Promise<ApiResponse<string[]>> {
    return apiRequest.delete(`/operations/${idOperation}`);
  },

  archive(idOperation: string): Promise<ApiResponse<string[]>> {
    return apiRequest.patch(`/operations/${idOperation}/archive`);
  },

  unarchive(idOperation: string): Promise<ApiResponse<boolean>> {
    return apiRequest.patch(
      `/operations/${idOperation}/unarchive`,
      {},
      {
        validateStatus(status) {
          return status === 200 || status === 403 || status === 409;
        },
      }
    );
  },

  searchApi(
    operationSearchRequest: OperationSearchRequest
  ): Promise<ApiResponse<OperationLight[]>> {
    // FIXME: le type de retour depuis le back est possiblement TiersOperationLight[]
    return apiRequestThrottle.post(
      "/operations/search",
      operationSearchRequest
    );
  },

  fetchById(id: string): Promise<ApiResponse<OperationHeavy>> {
    return apiRequest.get(`/operations/${id}`);
  },

  fetchOperationDetailsById(
    id: string
  ): Promise<ApiResponse<OperationHeavy | OperationArchiveHeavy>> {
    return apiRequest.get(`/operations/${id}/details`, {
      validateStatus(status) {
        return status === 200 || status === 403;
      },
    });
  },

  fetchTiersOperationDetailsById(
    id: string
  ): Promise<ApiResponse<TiersOperationHeavy>> {
    return apiRequest.get(`/operations/${id}/details`);
  },

  addMembers(
    idOperation: string,
    idMembers: string[]
  ): Promise<ApiResponse<void>> {
    return apiRequestThrottle.post(`/operations/${idOperation}/members/add`, {
      idMembers,
    });
  },

  fetchDashboardExport(): Promise<ApiResponse<BlobPart>> {
    return apiRequest.get(`/dashboard-excel-export`);
  },

  fetchDashboardFilters(): Promise<ApiResponse<DashboardFilter>> {
    return apiRequest.get(`/dashboard/filters`);
  },

  fetchOperationCount(
    operationCountRequest: OperationCountRequest
  ): Promise<ApiResponse<OperationCount>> {
    return apiRequest.post(`/operations/count`, operationCountRequest);
  },
  fetchSocieteSupportByOperationIdAndSocieteSupportIdList(
    idOperation: string,
    societeSupportIdList: string[]
  ): Promise<ApiResponse<SocieteSupport[]>> {
    return apiRequest.post(`/operations/${idOperation}/societe-support-list`, {
      societeSupportIdList,
    });
  },
};
