export enum SortingOrder {
  ASCENDING = "asc",
  DESCENDING = "desc",
}

export function inverseOrder(sortingOrder: SortingOrder): SortingOrder {
  return sortingOrder === SortingOrder.ASCENDING
    ? SortingOrder.DESCENDING
    : SortingOrder.ASCENDING;
}
