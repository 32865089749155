import { Account } from "@/domain/account";
import { Group } from "@domain/dto/group";
import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import { Role } from "@domain/enum/role";
import accountApi from "@/api/accountApi";
import groupApi from "@/api/groupApi";
import { AccountCreationRequest } from "@domain/dto/accountCreationRequest";
import { toastError, toastSuccess } from "@/utils/toastUtils";
import { useSocieteSupportStore } from "@/store/societeSupportModule.pinia";
import { AccountUpdateRequest } from "@domain/dto/accountUpdateRequest";

type AccountStateType = {
  accountList: Account[];
  accountGroupList: Group[];
};
export const useAccountStore = defineStore(ModuleType.Account, {
  state: (): AccountStateType => ({
    accountList: [],
    accountGroupList: [],
  }),
  getters: {
    getAccounts(state): Account[] {
      return state.accountList;
    },

    getAccountGroupList(state): Group[] {
      return state.accountGroupList;
    },

    getAccountBanqueGroups(): Group[] {
      return this.getAccountGroupList.filter((group) =>
        group.roles.includes(Role.BANQUE)
      );
    },

    getSortedByNameAccountBanqueGroups(): Group[] {
      const sortedGroups = [...this.getAccountBanqueGroups];
      return sortedGroups.sort((group1, group2) =>
        group1.name > group2.name ? 1 : -1
      );
    },

    getGroupedAccounts(state): Account[][] {
      return state.accountGroupList
        .filter((group) => group?.roles.includes(Role.BANQUE))
        .sort((group1, group2) => group1.name.localeCompare(group2.name))
        .map((group) => {
          return state.accountList
            .filter((account) => account.idBanque === group.id)
            .sort((account1, account2) =>
              account1.name.localeCompare(account2.name)
            );
        });
    },
  },
  actions: {
    SetAccounts(accounts: Account[]): void {
      this.accountList = accounts;
    },
    AddAccount(account: Account): void {
      this.accountList.push(account);
    },
    SetAccountGroups(accountGroups: Group[]): void {
      this.accountGroupList = accountGroups;
    },

    async fetchAccounts(idSocieteSupport: string): Promise<void> {
      const response = await accountApi.fetchAll(idSocieteSupport);
      this.SetAccounts(response.data);
      this.fetchAccountGroups().then();
    },

    async fetchAccountGroups(): Promise<void> {
      const idGroups = new Set<string>();
      const accounts = this.getAccounts;
      accounts.forEach((account: Account) => {
        idGroups.add(account.idBanque);
      });
      const idGroupsArray = Array.from(idGroups);
      idGroupsArray.push(
        useSocieteSupportStore().getSocieteSupport.idPromoteur
      );
      const results = await Promise.all(
        idGroupsArray.map((idGroup) => groupApi.fetchGroup(idGroup))
      );
      const groups = results.map((result) => result.data);
      this.SetAccountGroups(groups);
    },

    async createAccount(account: AccountCreationRequest): Promise<void> {
      return accountApi
        .create(account)
        .then(async (response) => {
          if (response.status === 201) {
            toastSuccess("Le compte a bien été créé");
            const createdAccount = response.data;
            this.AddAccount(createdAccount);
            await this.fetchAccountGroups();
          }
        })
        .catch(() => {
          toastError("Une erreur est survenue lors de la création du compte");
        });
    },

    async updateAccount(payload: {
      idAccount: string;
      idSocieteSupport: string;
      changePayload: AccountUpdateRequest;
    }): Promise<void> {
      return accountApi
        .update(
          payload.idAccount,
          payload.idSocieteSupport,
          payload.changePayload
        )
        .then((result) => {
          if (result.status === 200) {
            this.fetchAccounts(payload.idSocieteSupport).then(() => {
              toastSuccess("Le compte a été modifié avec succès !");
            });
          } else if (result.status === 400) {
            toastError("SIREN ou BIC invalide");
          }
        })
        .catch(() => {
          toastError(
            "Une erreur est survenue à la modification du Numéro de SIREN"
          );
        });
    },
  },
});
