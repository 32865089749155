import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import { Toast } from "@/domain/toast";
import { ToastType } from "@/domain/enum/toastType";
import {
  TOAST_BASE_TIME_TO_LIVE_MS,
  TOAST_MULTIPLE_DOCUMENT_UPLOAD_INDEX,
} from "@/utils/toastUtils";
import { useDocumentStore } from "@/store/documentModule.pinia";

type ToastStoreType = {
  toastList: Toast[];
  toastIndex: number;
};

export const useToastStore = defineStore(ModuleType.Toast, {
  state: (): ToastStoreType => ({
    toastList: [],
    toastIndex: 1,
  }),
  getters: {
    hasMultipleDocumentUpload(state): boolean {
      const toastMultipleDocumentUpload = state.toastList.find(
        (toast) => toast.index === TOAST_MULTIPLE_DOCUMENT_UPLOAD_INDEX
      );
      return Boolean(toastMultipleDocumentUpload);
    },
  },
  actions: {
    push(type: ToastType, message: string, time = TOAST_BASE_TIME_TO_LIVE_MS) {
      const index = this.toastIndex++;
      this.toastList.push({
        index,
        type,
        message,
        time,
        handler: setTimeout(() => this.remove(index), time),
      });
    },
    prependMultipleDocumentUpload(): void {
      const index = TOAST_MULTIPLE_DOCUMENT_UPLOAD_INDEX;
      const { getTotalDocumentsToUpload, getTotalDocumentsUploaded } =
        useDocumentStore();

      const message = `${getTotalDocumentsUploaded}/${getTotalDocumentsToUpload} documents chargés. Chargement en cours`;

      this.toastList.unshift({
        index,
        type: ToastType.INFO,
        message,
        time: 5000,
      });
    },
    updateMultipleDocumentUpload(): void {
      const {
        getTotalDocumentsToUpload,
        getTotalDocumentsUploaded,
        getProcessedDocumentCount,
      } = useDocumentStore();

      const toastMultipleDocumentUpload = this.toastList.find(
        (toast) => toast.index === TOAST_MULTIPLE_DOCUMENT_UPLOAD_INDEX
      );

      if (toastMultipleDocumentUpload) {
        toastMultipleDocumentUpload.message = `${getTotalDocumentsUploaded}/${getTotalDocumentsToUpload} documents chargés. Chargement en cours`;

        if (getTotalDocumentsToUpload === getProcessedDocumentCount) {
          toastMultipleDocumentUpload.type = ToastType.SUCCESS;
          toastMultipleDocumentUpload.message = `${getTotalDocumentsUploaded}/${getTotalDocumentsToUpload} documents chargés`;

          setTimeout(() => {
            this.removeMultipleDocumentUpload();
          }, TOAST_BASE_TIME_TO_LIVE_MS);
        }
      }
    },
    remove(index: number) {
      this.toastList = this.toastList.filter((toast) => {
        if (toast.index === index) {
          clearTimeout(toast.handler);
          return false;
        }
        return true;
      });
    },
    removeMultipleDocumentUpload(): void {
      if (this.hasMultipleDocumentUpload) {
        this.toastList.shift();
      }
    },
  },
});
