export enum PoolGroupAndUserListError {
  ONE_EMAIL_IS_PROMOTEUR = "ONE_EMAIL_IS_PROMOTEUR",
  ONE_EMAIL_IS_IN_AUTHOR_GROUP = "ONE_EMAIL_IS_IN_AUTHOR_GROUP",
  POOL_NAME_ALREADY_EXIST = "POOL_NAME_ALREADY_EXIST",
  UNKNOWN_POOL_GROUP = "UNKNOWN_POOL_GROUP",
  UNKNOWN_POOL_GROUP_BANQUE = "UNKNOWN_POOL_GROUP_BANQUE",
  USER_ALREADY_PART_PARTNER_BANQUE = "USER_ALREADY_PART_PARTNER_BANQUE",
  UNAUTHORIZED = "UNAUTHORIZED",
}

export interface PoolGroupAndUserListErrorWithUserList {
  errorType: PoolGroupAndUserListError;
  emailListInError: string[];
}
