export enum EtapeCommercialisationImport {
  CHOIX_PREMIERE_LIGNE = "CHOIX_PREMIERE_LIGNE",
  CHOIX_COLONNE_IDENTIFIANT = "CHOIX_COLONNE_IDENTIFIANT",
  CHOIX_COLONNE_TRANCHE = "CHOIX_COLONNE_TRANCHE",
  CHOIX_CA_TTC_TOTAL = "CHOIX_CA_TTC_TOTAL",
  CHOIX_CA_TTC_ACTE = "CHOIX_CA_TTC_ACTE",
  CHOIX_NOMBRE_LOTS_TOTAL = "CHOIX_NOMBRE_LOTS_TOTAL",
  CHOIX_NOMBRE_LOTS_ACTES = "CHOIX_NOMBRE_LOTS_ACTES",
  CHOIX_CA_TTC_RESERVE = "CHOIX_CA_TTC_RESERVE",
  CHOIX_NOMBRE_LOTS_RESERVES = "CHOIX_NOMBRE_LOTS_RESERVES",
  CHOIX_ETAPE_NON_DEFINIE = "CHOIX_ETAPE_NON_DEFINIE",
}
