import { NemmoMimeType } from "@domain/enum/nemmoMimeType";
import { useDocumentStore } from "@/store/documentModule.pinia";

export function downloadClickLink(
  documentStream: BlobPart,
  {
    name,
    type,
  }: {
    name: string;
    type?: string;
  }
): void {
  const url = createObjectUrl(documentStream, type);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  link.remove();
}

export function downloadFromURL(url: string): void {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result as string;
      resolve(result.split(",", 2)[1]);
    };
    reader.readAsDataURL(blob);
  });
}

export function createObjectUrl(data: Blob | BlobPart, type?: string): string {
  return window.URL.createObjectURL(new Blob([data], { type }));
}

export function setupDocumentPreview(
  data: Blob | BlobPart,
  type: NemmoMimeType = NemmoMimeType.PDF
) {
  const documentStore = useDocumentStore();

  const url = createObjectUrl(data, type);

  documentStore.SetPreviewDocument(url);
  documentStore.SetIsShowPreviewDocument(true);
}
