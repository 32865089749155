import { ApiResponse, apiRequest, apiRequestThrottle } from "@/apiRequest";
import { PoolFormaliseCreationRequest } from "@/domain/dto/poolFormaliseCreationRequest";
import { PoolFormalise } from "@/domain/poolFormalise";
import { PoolFormaliseParticipantLight } from "@/domain/poolFormaliseParticipantLight";

export default {
  fetchPoolFormaliseList(): Promise<ApiResponse<PoolFormalise[]>> {
    return apiRequest.get(`/pool/formalise`);
  },

  fetchParticipantById(
    idPoolFormaliseParticipant: string,
    idPoolFormalise: string | null
  ): Promise<ApiResponse<PoolFormaliseParticipantLight>> {
    return apiRequest.get(
      `/pool/formalise/${idPoolFormalise}/participant/${idPoolFormaliseParticipant}`
    );
  },

  createPoolFormalise(
    poolFormaliseCreationRequest: PoolFormaliseCreationRequest
  ): Promise<ApiResponse<PoolFormalise>> {
    return apiRequestThrottle.post(
      `/pool/formalise`,
      poolFormaliseCreationRequest,
      {
        validateStatus(status) {
          return status === 201 || status === 409;
        },
      }
    );
  },
  updatePoolFormalise(
    id: string,
    poolFormaliseUpdateRequest: PoolFormaliseCreationRequest
  ): Promise<ApiResponse<PoolFormalise>> {
    return apiRequestThrottle.patch(
      `/pool/formalise/${id}`,
      poolFormaliseUpdateRequest,
      {
        validateStatus(status) {
          return status === 201 || status === 409;
        },
      }
    );
  },
};
