import {
  DemandeStatus,
  demandeStatusVariants,
} from "@domain/enum/demandeStatus";
import { DemandeType } from "@domain/enum/demandeType";
import { DemandeLight } from "@/domain/dto/demandeLight";
import { OperationLight } from "@/domain/dto/operationLight";
import { HighlightText } from "@/domain/highlightText";
import { normalizeString } from "@/utils/wordUtils";
import { EngagementGlobalType } from "@domain/enum/engagementGlobalType";
import { DashboardStatusTypeGroup } from "@domain/dto/dashboardFilter";
import { SelectedDashboardFilter } from "@/domain/selectedDashboardFilter";
import { getLastUpdatedDemandeByOperationLight } from "@/utils/operationUtils";
import { OperationArchiveStatus } from "@domain/enum/operationArchiveStatus";

export function createSelectedDashboardFilter(
  selectedDashboardFilter: Partial<SelectedDashboardFilter>
): SelectedDashboardFilter {
  return {
    partnerIdList: [],
    demandeStatusList: [],
    engagementTypeList: [],
    regionList: [],
    searchQuery: "",
    poolAccessModeList: [],
    operationType: [],
    selectedUnilateralGroup: "",
    operationArchiveStatus: OperationArchiveStatus.ACTIVE,
    ...selectedDashboardFilter,
  };
}

export function filterDemandesByTypesAndStatus(
  operations: OperationLight[],
  types: DemandeType[],
  status: DemandeStatus[]
): DemandeLight[] {
  const demandes: DemandeLight[] = [];

  operations.forEach((operation) => {
    operation.demandeList.forEach((demande) => {
      if (types.includes(demande.type) && status.includes(demande.status)) {
        demandes.push(demande);
      }
    });
  });
  return demandes;
}

export function countDemandesByTypeAndStatus(
  dashboardStatusTypeGroupList: DashboardStatusTypeGroup[],
  typeList: EngagementGlobalType[],
  statusList: DemandeStatus[]
): number {
  const demandeCountList = dashboardStatusTypeGroupList
    .filter(
      (dashboardStatusTypeGroup) =>
        typeList.includes(dashboardStatusTypeGroup.engagementType) &&
        statusList.includes(dashboardStatusTypeGroup.status)
    )
    .map((dashboardStatusTypeGroup) => dashboardStatusTypeGroup.count);

  return demandeCountList.length
    ? demandeCountList.reduce((prev, next) => prev + next)
    : 0;
}

export function countTotalEngagements(
  dashboardStatusTypeGroupList: DashboardStatusTypeGroup[]
): number {
  return dashboardStatusTypeGroupList
    .filter((ds) => !!ds.count)
    .map((ds) => ds.count)
    .reduce((prev, next) => prev + next, 0);
}

export function filterDemandesByTypes(
  operations: OperationLight[],
  types: DemandeType[]
): DemandeLight[] {
  return filterDemandesByTypesAndStatus(
    operations,
    types,
    demandeStatusVariants()
  );
}

export function createHighlightTextMatrice(
  highlightQuery: string,
  wording: string
): HighlightText[] {
  const highlightTextMatrice: HighlightText[] = [];
  if (highlightQuery.trim() !== "") {
    const wordList = wording.trim().split(" ");
    const wordQueryList = highlightQuery
      .trim()
      .split(" ")
      .map((query) => normalizeString(query));
    for (const word of wordList) {
      const highlight = wordQueryList.some((queryWord) => {
        return normalizeString(word).includes(queryWord);
      });
      highlightTextMatrice.push({
        subText: `${word} `,
        highlight: highlight,
      });
    }
  } else {
    highlightTextMatrice.push({
      subText: wording.trim(),
      highlight: false,
    });
  }
  return highlightTextMatrice;
}

export function getDashboardSocieteSupportName(
  isPromoteur: boolean,
  operation: OperationLight
): string {
  if (
    isPromoteur &&
    operation.demandeList.length === 0 &&
    operation.societeSupportOrigine
  ) {
    return operation.societeSupportOrigine.name;
  }

  const lastUpdatedDemande = getLastUpdatedDemandeByOperationLight(operation);
  if (lastUpdatedDemande) {
    return lastUpdatedDemande.societeSupport.name;
  }

  return "";
}
