export enum DocusignConfigurationError {
  DUPLICATED_RECIPIENT = "DUPLICATED_RECIPIENT",
  TOO_MANY_BANQUE_RECIPIENT = "TOO_MANY_BANQUE_RECIPIENT",
  TOO_MANY_PROMOTEUR_RECIPIENT = "TOO_MANY_PROMOTEUR_RECIPIENT",
  NOT_ENOUGH_BANQUE_RECIPIENT = "NOT_ENOUGH_BANQUE_RECIPIENT",
  NOT_ENOUGH_PROMOTEUR_RECIPIENT = "NOT_ENOUGH_PROMOTEUR_RECIPIENT",
  NOT_ENOUGH_SIGNATAIRE_EXTERNE_RECIPIENT = "NOT_ENOUGH_SIGNATAIRE_EXTERNE_RECIPIENT",
  TOO_MANY_SIGNATAIRE_EXTERNE_RECIPIENT = "TOO_MANY_SIGNATAIRE_EXTERNE_RECIPIENT",
  INCORRECT_RECIPIENT_INFORMATION_FIRSTNAME = "INCORRECT_RECIPIENT_INFORMATION_FIRSTNAME",
  INCORRECT_RECIPIENT_INFORMATION_LASTNAME = "INCORRECT_RECIPIENT_INFORMATION_LASTNAME",
  INCORRECT_RECIPIENT_INFORMATION_PHONENUMBER = "INCORRECT_RECIPIENT_INFORMATION_PHONENUMBER",
  INCORRECT_RECIPIENT_INFORMATION_EMAIL = "INCORRECT_RECIPIENT_INFORMATION_EMAIL",
}