import { SocieteSupport } from "@/domain/societeSupport";
import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { SocieteSupportCreationRequest } from "@domain/dto/societeSupportCreationRequest";
import { SocieteSupportUpdateRequest } from "@domain/dto/societeSupportUpdateRequest";
import { SocieteSupportFormErrorType } from "@/domain/enum/societeSupportFormErrorType";
import { SureteLight } from "@/domain/sureteLight";
import { SocieteSupportLightWithAccessType } from "@domain/dto/societeSupportLightWithAccessType";
import { GroupLite } from "@domain/dto/groupLite";
import { OperationLight } from "@/domain/dto/operationLight";

export default {
  create(
    societeSupport: SocieteSupportCreationRequest
  ): Promise<ApiResponse<SocieteSupport | SocieteSupportFormErrorType[]>> {
    return apiRequestThrottle.post("/societes-support", societeSupport, {
      validateStatus(status) {
        return status === 201 || status === 409;
      },
    });
  },

  update(
    idSocieteSupport: string,
    societeSupportUpdateRequest: SocieteSupportUpdateRequest
  ): Promise<ApiResponse<SocieteSupport | SocieteSupportFormErrorType[]>> {
    return apiRequestThrottle.patch(
      `/societes-support/${idSocieteSupport}`,
      societeSupportUpdateRequest,
      {
        validateStatus(status) {
          return status === 200 || status === 409;
        },
      }
    );
  },

  fetchAll(): Promise<ApiResponse<SocieteSupportLightWithAccessType[]>> {
    return apiRequest.get("/societes-support");
  },

  fetchById(id: string): Promise<ApiResponse<SocieteSupport>> {
    return apiRequest.get(`/societes-support/${id}`);
  },

  fetchAllSocieteSupportOperations(
    id: string,
    options: {
      withImplicitOperations?: boolean;
    } = {}
  ): Promise<ApiResponse<OperationLight[]>> {
    return apiRequest.get(`/societes-support/${id}/operations`, {
      params: {
        withImplicitOperations: options.withImplicitOperations
          ? "true"
          : "false",
      },
    });
  },

  fetchAllSurete(): Promise<ApiResponse<SureteLight[]>> {
    return apiRequest.get(`/societes-support/suretes`);
  },

  fetchBanques(idSocieteSupport: string): Promise<ApiResponse<GroupLite[]>> {
    return apiRequest.get(`/societes-support/${idSocieteSupport}/banques`);
  },
};
