import { SureteStep } from "@domain/enum/sureteStep";
import { Surete } from "@/domain/surete";
import { ValidationStatus } from "@domain/enum/validationStatus";
import { SureteStatus } from "@domain/enum/sureteStatus";
import { SureteSignataireCreateRequest } from "@domain/dto/sureteSignataireCreateRequest";
import { SureteSignataireCreate } from "@domain/dto/sureteSignataireCreate";
import { SignataireHeavy } from "@domain/dto/signataireHeavy";
import { SignMethod } from "@domain/enum/signMethod";
import { SignatairePosition } from "@/domain/enum/signatairePosition";
import { SureteHeavy } from "@domain/dto/sureteHeavy";
import { Role } from "@domain/enum/role";
import { User } from "@domain/dto/user";
import { getGroupRoleFromUser } from "@/utils/groupUtils";
import { SureteUserRoom } from "@domain/dto/sureteUserRoom";
import { SignataireType } from "@domain/enum/signataireType";
export function createSurete(): SureteHeavy {
  return {
    id: "",
    name: "",
    idSocieteSupport: "",
    idBanque: "",
    createdAt: new Date(),
    step: SureteStep.CO_CONSTRUCTION,
    status: SureteStatus.CO_CONSTRUCTION,
    statusPromoteur: ValidationStatus.UNCHECKED,
    statusBanque: ValidationStatus.UNCHECKED,
    idEnvelope: "",
    signatairePromoteurCount: 0,
    signataireBanqueCount: 0,
    signataireExterneCount: 0,
    signataireExterneList: [],
    signataireList: [],
    signMethod: SignMethod.UNCHECKED,
    operationList: [],
    isSignatureOrdered: false,
  };
}

export function sureteStepLabel(step: SureteStatus): string {
  switch (step) {
    case SureteStatus.EN_ATTENTE_DE_VALIDATION:
      return "En attente de validation";
    case SureteStatus.EN_ATTENTE_DE_SIGNATURE:
      return "En attente de signature";
    case SureteStatus.COMPLETED:
      return "Signé";
    default:
      return "Co-construction";
  }
}

export function stepColor(status: SureteStatus): string {
  switch (status) {
    case SureteStatus.COMPLETED:
      return "green";
    case SureteStatus.EN_ATTENTE_DE_VALIDATION:
    case SureteStatus.EN_ATTENTE_DE_SIGNATURE:
      return "orange";
    default:
      return "yellow";
  }
}

const SureteStepNumber = new Map([
  [SureteStep.CO_CONSTRUCTION, 1],
  [SureteStep.SIGNATURE, 2],
]);

export function toNumber(sureteStep: SureteStep): number {
  const category = SureteStepNumber.get(sureteStep);
  return category || 0;
}

export function buildSureteNewSignataireBanqueCountCreateRequest(
  surete: Surete | SureteHeavy,
  signataireBanqueCount: number
): SureteSignataireCreateRequest {
  const signataireCreateList: SureteSignataireCreate[] = surete.signataireList
    .filter(
      (signataire) =>
        signataireBanqueCount === 2 ||
        (signataireBanqueCount === 1 &&
          signataire.idRecipient !== SignatairePosition.BANQUE_SECOND) ||
        (signataireBanqueCount === 0 &&
          signataire.idRecipient !== SignatairePosition.BANQUE_FIRST &&
          signataire.idRecipient !== SignatairePosition.BANQUE_SECOND)
    )
    .map((signataire) => buildSureteSignataireCreate(surete, signataire));

  return {
    idSurete: surete.id,
    signatairePromoteurCount: surete.signatairePromoteurCount,
    signataireExterneCount: surete.signataireExterneCount,
    signataireBanqueCount,
    signataireCreateList,
  };
}

export function buildSureteNewSignatairePromoteurCountCreateRequest(
  surete: Surete | SureteHeavy,
  signatairePromoteurCount: number
): SureteSignataireCreateRequest {
  const signataireCreateList: SureteSignataireCreate[] = surete.signataireList
    .filter(
      (signataire) =>
        signatairePromoteurCount === 2 ||
        (signatairePromoteurCount === 1 &&
          signataire.idRecipient !== SignatairePosition.PROMOTEUR_SECOND)
    )
    .map((signataire) => buildSureteSignataireCreate(surete, signataire));

  return {
    idSurete: surete.id,
    signatairePromoteurCount,
    signataireBanqueCount: surete.signataireBanqueCount,
    signataireExterneCount: surete.signataireExterneCount,
    signataireCreateList,
  };
}
export function buildSureteNewSignataireExterneCountCreateRequest(
  surete: Surete | SureteHeavy,
  signataireExterneCount: number
): SureteSignataireCreateRequest {
  const signataireCreateList: SureteSignataireCreate[] = surete.signataireList
    .filter(
      (signataire) =>
        signataireExterneCount === 2 ||
        (signataireExterneCount === 1 &&
          signataire.idRecipient !== SignatairePosition.PROMOTEUR_SECOND)
    )
    .map((signataire) => buildSureteSignataireCreate(surete, signataire));

  return {
    idSurete: surete.id,
    signataireExterneCount,
    signatairePromoteurCount: surete.signatairePromoteurCount,
    signataireBanqueCount: surete.signataireBanqueCount,
    signataireCreateList,
  };
}
function buildSureteSignataireCreate(
  surete: Surete | SureteHeavy,
  signataire: SignataireHeavy
): SureteSignataireCreate {
  return {
    idSurete: surete.id,
    idSignataire: signataire.user.id,
    idRecipient: signataire.idRecipient,
    type: SignataireType.INTERNE,
  };
}

export function buildSureteSignataireCreateRequest(
  surete: Surete | SureteHeavy,
  newSignataire: { id: string },
  idRecipient: number
): SureteSignataireCreateRequest {
  const signataireCreateList: SureteSignataireCreate[] = surete.signataireList
    .filter(
      (signataire) =>
        signataire.idRecipient !== idRecipient &&
        signataire.user.id !== newSignataire.id
    )
    .map((signataire) => buildSureteSignataireCreate(surete, signataire));

  signataireCreateList.push({
    idSurete: surete.id,
    idSignataire: newSignataire.id,
    idRecipient: idRecipient,
    type: SignataireType.INTERNE,
  });

  return {
    idSurete: surete.id,
    signatairePromoteurCount: surete.signatairePromoteurCount,
    signataireBanqueCount: surete.signataireBanqueCount,
    signataireExterneCount: surete.signataireExterneCount,

    signataireCreateList,
  };
}

export function isSignedSurete(surete: Surete | SureteHeavy): boolean {
  return surete.step === SureteStep.COMPLETED;
}

export function isDeclinedSurete(surete: Surete | SureteHeavy): boolean {
  return surete.statusBanque === ValidationStatus.DECLINED;
}

export function isElectronicSignedSurete(
  surete: Surete | SureteHeavy
): boolean {
  return (
    isSignedSurete(surete) &&
    [SignMethod.ELECTRONIC, SignMethod.FREE_ELECTRONIC].includes(
      surete.signMethod
    )
  );
}

export function shouldBlockSureteEdition(
  payload: { idSurete: string; role: Role; idUploader: string },
  sureteRoomUserList: SureteUserRoom[],
  userProfile: User
): boolean {
  const myGroupRole = getGroupRoleFromUser(userProfile);

  const mySureteUserRoom = sureteRoomUserList.find(
    (user) => user.idUser === userProfile.id
  );
  const uploaderSureteUserRoom = sureteRoomUserList.find(
    (user) => user.idUser === payload.idUploader
  );

  if (!mySureteUserRoom || !uploaderSureteUserRoom) {
    return false;
  }

  const isBanqueOrPromoteurFromMyGroup =
    [Role.PROMOTEUR, Role.BANQUE].includes(mySureteUserRoom.role) &&
    myGroupRole === payload.role;

  return isBanqueOrPromoteurFromMyGroup;
}
