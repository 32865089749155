import { SuiviTravauxStep } from "@/domain/enum/suiviTravauxStep";
import { CmpManageStep } from "@/domain/enum/cmpManageType";
import { SidePanelSize } from "@/domain/enum/sidePanelSize";
import { PanelType } from "@/utils/model/panelType";
import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";

type PanelProps = Partial<{
  suiviTravaux: {
    id?: string;
    disabledBefore?: Date;
    disabledAfter?: Date;
    etape?: SuiviTravauxStep;
  };
}>;

type PanelStateType = {
  panelType: undefined | PanelType;
  panelSize: SidePanelSize;
  customTitle: string;
  sidePanelStep: CmpManageStep | null;
  currentEntityId: string;
  props: PanelProps;
};

export const usePanelStore = defineStore(ModuleType.Panel, {
  state: (): PanelStateType => ({
    panelType: undefined,
    panelSize: SidePanelSize.NORMAL,
    customTitle: "",
    sidePanelStep: null,
    currentEntityId: "",
    props: {},
  }),
  getters: {
    showPanel(state): boolean {
      return !!state.panelType;
    },

    getCustomTitle(state): string {
      return state.customTitle;
    },

    getSidePanelStep(state): CmpManageStep | null {
      return state.sidePanelStep;
    },

    getPanelType(state): PanelType | undefined {
      return state.panelType;
    },

    getSidePanelSize(state): SidePanelSize {
      return state.panelSize;
    },
    getCurrentEntityId(state): string {
      return state.currentEntityId;
    },
    getProps(state): PanelProps {
      return state.props;
    },
  },
  actions: {
    SetPanelType(panelType: PanelType): void {
      this.panelType = panelType;
      this.panelSize = SidePanelSize.NORMAL;
    },

    SetPanelSize(panelSize: SidePanelSize): void {
      this.panelSize = panelSize;
    },

    SetCustomTitle(title: string): void {
      this.customTitle = title;
    },

    SetSidePanelStep(step: CmpManageStep): void {
      this.sidePanelStep = step;
    },

    CloseSidePanel(): void {
      this.panelType = undefined;
    },
    SetCurrentEntityId(id: string): void {
      this.currentEntityId = id;
    },
    SetProps(props: Partial<PanelProps>): void {
      this.props = { ...this.props, ...props };
    },
    resetProps(): void {
      this.props = {};
    },
  },
});
