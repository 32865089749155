import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";

import { ReportingFilter } from "@/domain/reportingFilter";
import { EngagementGlobalType } from "@domain/enum/engagementGlobalType";
import { ReportingDataScopeFilter } from "@/domain/enum/reportingDataScopeFilter";
import { ReportingPoolModeFilter } from "@/domain/enum/reportingPoolModeFilter";
import { GroupLite } from "@domain/dto/groupLite";
import { ReportingGeneriqueData } from "@/domain/reportingGeneriqueData";
import {
  reportingDataToGroups,
  reportingDataToPartners,
  applyAllReportingFilters,
  convertToReportingGeneriqueData,
  applyAllOtherYearReportingFilters,
  computeAvailableYears,
  togglePartnerAndParentFromList,
  toggleGroupFromList,
  toggleDemandeTypeFromList,
  mutateDataToPartnerParentChildGroupList,
} from "@/utils/reportingFilterUtils";
import { orderBy, uniqBy } from "lodash";
import reportingApi from "@/api/reportingApi";
import { OperationType } from "@domain/enum/operationType";
import { ReportingData } from "@domain/dto/reportingData";

type ReportingStateType = {
  reportingDataList: ReportingData[];
  reportingFilter: ReportingFilter;
};

export const useReportingStore = defineStore(ModuleType.Reporting, {
  state: (): ReportingStateType => ({
    reportingDataList: [],
    reportingFilter: {
      selectedDataScope: ReportingDataScopeFilter.CA_TTC,
      selectedDemandeTypeList: [
        EngagementGlobalType.GFA,
        EngagementGlobalType.CAUTION,
        EngagementGlobalType.CREDIT,
      ],
      partnerParentList: [],
      selectedPartnerList: [],
      selectedGroupList: [],
      selectedCurrentYear: new Date().getFullYear(),
      selectedOtherYear: new Date().getFullYear() - 1,
      otherYearActivated: false,
      selectedPoolMode: ReportingPoolModeFilter.BANQUE_PRINCIPALE,
      selectedOperationType: OperationType.RESIDENTIELLE,
    },
  }),
  getters: {
    getReportingPoolModeFilter(state): ReportingPoolModeFilter {
      return state.reportingFilter.selectedPoolMode;
    },

    getReportingPartnerParentList(state): GroupLite[] {
      return state.reportingFilter.partnerParentList;
    },

    getReportingDataList(state): ReportingData[] {
      return state.reportingDataList;
    },

    getReportingFilter(state): ReportingFilter {
      return state.reportingFilter;
    },

    getReportingDataScopeFilter(state): ReportingDataScopeFilter {
      return state.reportingFilter.selectedDataScope;
    },

    getReportingDemandeTypeFilter(state): EngagementGlobalType[] {
      return state.reportingFilter.selectedDemandeTypeList;
    },

    getReportingGroups(state): GroupLite[] {
      return reportingDataToGroups(state.reportingDataList);
    },

    // a masterGroup is a group which is not a child (may or may not have child group)
    getReportingMasterGroupList(_state): GroupLite[] {
      // state function adds to reporting groups, groups which are parent but have no reporting data
      const parentGroupList = this.getReportingGroups
        .filter((group) => !!group.parent)
        .map((group) => group.parent as GroupLite);
      const masterGroupList = this.getReportingGroups.filter(
        (group) => !group.parent
      );
      return orderBy(
        uniqBy([...parentGroupList, ...masterGroupList], "id"),
        (item) => item.name
      );
    },

    getReportingSelectedGroupList(state): GroupLite[] {
      return state.reportingFilter.selectedGroupList;
    },

    getReportingPartners(state): GroupLite[] {
      return reportingDataToPartners(state.reportingDataList);
    },

    getReportingMasterPartnerList(_state): GroupLite[] {
      const parentGroupList = this.getReportingPartners
        .filter((group) => !!group.parent)
        .map((group) => group.parent as GroupLite);
      const masterGroupList = this.getReportingPartners.filter(
        (group) => !group.parent
      );
      return orderBy(
        uniqBy([...parentGroupList, ...masterGroupList], "id"),
        (item) => item.name
      );
    },

    getReportingSelectedPartnerList(state): GroupLite[] {
      return state.reportingFilter.selectedPartnerList;
    },

    getScopedData(state): ReportingGeneriqueData[] {
      const filteredData = applyAllReportingFilters(
        state.reportingDataList,
        state.reportingFilter
      );
      return convertToReportingGeneriqueData(
        filteredData,
        state.reportingFilter
      );
    },

    getOtherYearScopedData(state): ReportingGeneriqueData[] {
      const filteredData = applyAllOtherYearReportingFilters(
        state.reportingDataList,
        state.reportingFilter
      );
      return convertToReportingGeneriqueData(
        filteredData,
        state.reportingFilter
      );
    },

    getOperationTypeFilter(state): OperationType {
      return state.reportingFilter.selectedOperationType;
    },

    shouldShowOperationTypeSelector(state): boolean {
      const operationTypeSet = [
        ...new Set(
          state.reportingDataList.map((reportingData) => reportingData.type)
        ),
      ];

      return operationTypeSet.length > 1;
    },
  },
  actions: {
    // Mutations
    SetReportingPoolModeFilter(poolMode: ReportingPoolModeFilter): void {
      this.reportingFilter.selectedPoolMode = poolMode;
    },
    SetConfirmedPartnerParentList(partnerParentList: GroupLite[]): void {
      this.reportingFilter.partnerParentList = partnerParentList;
    },
    SetReportingData(reportingData: ReportingData[]): void {
      this.reportingDataList = reportingData;
      const availableYearList = computeAvailableYears(reportingData);
      const selectedCurrentYear = availableYearList.pop();
      if (selectedCurrentYear) {
        this.reportingFilter.selectedCurrentYear = selectedCurrentYear;
      }
      const selectedOtherYear = availableYearList.pop();
      if (selectedOtherYear) {
        this.reportingFilter.selectedOtherYear = selectedOtherYear;
        this.reportingFilter.otherYearActivated = true;
      }
    },
    SetReportingDataScopeFilter(
      reportingDataScope: ReportingDataScopeFilter
    ): void {
      this.reportingFilter.selectedDataScope = reportingDataScope;
    },
    SetSelectedPartners(selectedPartners: GroupLite[]): void {
      this.reportingFilter.selectedPartnerList = selectedPartners;
    },
    SetSelectedGroups(selectedGroup: GroupLite[]): void {
      this.reportingFilter.selectedGroupList = selectedGroup;
    },
    SetSelectedDemandeTypes(
      selectedDemandeTypes: EngagementGlobalType[]
    ): void {
      this.reportingFilter.selectedDemandeTypeList = selectedDemandeTypes;
    },
    SetReportingSelectedCurrentYear(year: number): void {
      this.reportingFilter.selectedCurrentYear = year;
    },
    SetReportingSelectedOtherYear(year: number): void {
      this.reportingFilter.selectedOtherYear = year;
    },
    SetReportingOtherYearActivated(activated: boolean): void {
      if (computeAvailableYears(this.reportingDataList).length > 1) {
        this.reportingFilter.otherYearActivated = activated;
      } else {
        this.reportingFilter.otherYearActivated = false;
      }
    },
    SetReportingOperationTypeFilter(operationType: OperationType): void {
      this.reportingFilter.selectedOperationType = operationType;

      if (
        this.reportingFilter.selectedDataScope ===
          ReportingDataScopeFilter.NOMBRE_LOTS_CREES &&
        operationType === OperationType.TERTIAIRE
      ) {
        this.reportingFilter.selectedDataScope =
          ReportingDataScopeFilter.SURFACE_UTILE;
      } else if (
        this.reportingFilter.selectedDataScope ===
          ReportingDataScopeFilter.SURFACE_UTILE &&
        operationType === OperationType.RESIDENTIELLE
      ) {
        this.reportingFilter.selectedDataScope =
          ReportingDataScopeFilter.NOMBRE_LOTS_CREES;
      }
    },

    // Actions
    togglePartnerFilter(partner: GroupLite): void {
      const selectedPartners: GroupLite[] =
        this.getReportingSelectedPartnerList;
      const masterGroupList: GroupLite[] = this.getReportingPartnerParentList;
      const allPartners: GroupLite[] = this.getReportingPartners;

      this.SetSelectedPartners(
        togglePartnerAndParentFromList(
          allPartners,
          selectedPartners,
          partner,
          masterGroupList
        )
      );
    },
    toggleGroupFilter(group: GroupLite): void {
      const selectedGroups: GroupLite[] = this.getReportingSelectedGroupList;
      this.SetSelectedGroups(toggleGroupFromList(selectedGroups, group));
    },
    toggleDemandeTypeFilter(demandeType: EngagementGlobalType): void {
      const selectedDemandeTypes: EngagementGlobalType[] =
        this.getReportingDemandeTypeFilter;

      this.SetSelectedDemandeTypes(
        toggleDemandeTypeFromList(selectedDemandeTypes, demandeType)
      );
    },
    async fetchReportingData(): Promise<void> {
      const isPoolMode =
        this.getReportingPoolModeFilter ===
        ReportingPoolModeFilter.POOL_PARTICIPANT;

      const response = await (isPoolMode
        ? reportingApi.fetchReportingDataForPoolParticipant()
        : reportingApi.fetchReportingData());
      const { mutatedData, partnerParentList } =
        mutateDataToPartnerParentChildGroupList(response.data);
      this.SetSelectedPartners(reportingDataToPartners(mutatedData));
      this.SetSelectedGroups(reportingDataToGroups(mutatedData));
      this.SetConfirmedPartnerParentList(partnerParentList);
      this.SetReportingData(mutatedData);
    },
  },
});
