<template>
  <div class="toast" :class="[props.toast.type, toastLineClass]">
    <ToastIconSuccess v-if="isSuccessToast" />
    <ToastIconBell v-else :toast-type="toast.type" />

    <div class="toast-text">
      <h5>{{ title }}</h5>
      <p :class="[displaySpinnerClass]">{{ props.toast.message }}</p>
    </div>

    <div class="toast-action">
      <button @click.prevent="removeToast">OK</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Toast } from "@/domain/toast";
import {
  getLabelFromToastType,
  TOAST_MULTIPLE_DOCUMENT_UPLOAD_INDEX,
} from "@/utils/toastUtils";
import { computed } from "vue";
import ToastIconBell from "./ToastIconBell.vue";
import ToastIconSuccess from "./ToastIconSuccess.vue";
import { ToastType } from "@/domain/enum/toastType";
import { useToastStore } from "@/store/toastModule.pinia";

const props = defineProps<{
  toast: Toast;
}>();

const title = computed((): string => getLabelFromToastType(props.toast.type));
const isSuccessToast = computed(
  (): boolean => props.toast.type === ToastType.SUCCESS
);

const isUploadingMultipleDocumentToast = computed((): boolean => {
  return (
    props.toast.type === ToastType.INFO &&
    props.toast.index === TOAST_MULTIPLE_DOCUMENT_UPLOAD_INDEX
  );
});

const toastLineClass = computed((): string => {
  return isUploadingMultipleDocumentToast.value ? "" : "toast-line";
});

const displaySpinnerClass = computed((): string => {
  return isUploadingMultipleDocumentToast.value ? "spinner-uploading" : "";
});

const timeCss = computed((): string => `${props.toast.time}ms`);

function removeToast() {
  useToastStore().remove(props.toast.index);
}
</script>

<style scoped lang="scss">
@import "@/sass/variables";

.toast {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  padding: 12px 16px;

  color: white;
  border-radius: 4px;

  box-shadow: $shadow-5;

  font-size: 14px;
  width: 300px;

  &.success {
    border-color: $green2-transparent;
    background: $green2;
  }

  &.error {
    border-color: #f0f4fe;
    background: $button-red-border-color;
  }

  &.warn {
    border-color: #f0f4fe;
    background: #f4c452;
  }

  &.info {
    border-color: #f0f4fe;
    background: $blue2;
  }

  .toast-text {
    flex: 1;

    h5,
    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
      text-align: left;
      word-break: break-word;
    }
  }

  .toast-action {
    border-left: 1px solid rgba(#d3eed3, 0.26);

    align-self: stretch;

    button {
      padding: 0;
      margin: 0;
      min-height: 0;
      height: 100%;
      padding-left: 16px;
      padding-right: 8px;
      background-color: transparent;
      border: none;
      font-size: 16px;
      color: white;
    }
  }
}

.toast-line::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  background-color: rgba(black, 0.3);
  animation: time-countdown v-bind(timeCss);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.spinner-uploading::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: solid grey 2px;
  border-top: solid white 2px;
  width: 10px;
  height: 10px;
  animation: spinning linear infinite 1000ms;
  margin-left: 5px;
  margin-top: 5px;
}

@keyframes time-countdown {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
@keyframes spinning {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(359deg);
  }
}
</style>
