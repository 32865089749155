import { ApiResponse, apiRequest, apiRequestThrottle } from "@/apiRequest";
import { SuiviBudgetOperationImport } from "@domain/dto/suiviBudgetSomme";
import { PendingDocument } from "@domain/dto/pendingDocument";
import { createSuiviBudgetDocumentUploadPayloadMetadata } from "@/utils/documentUtils";
import { SuiviBudgetImportCreation } from "@/domain/suiviBudgetImportCreation";

export default {
  uploadSuiviBudgetPromoteur(
    importCreation: SuiviBudgetImportCreation
  ): Promise<ApiResponse<PendingDocument[]>> {
    const formData: FormData = new FormData();

    formData.append(
      "metadata",
      createSuiviBudgetDocumentUploadPayloadMetadata(importCreation)
    );

    importCreation.documentList.forEach((document) => {
      formData.append("files", document.file);
    });
    return apiRequestThrottle.post(
      `/operations/${importCreation.idOperation}/suivi-budget/budget-promoteur/${importCreation.documentList.length}`,
      formData,
      {
        validateStatus: function (status) {
          return status === 201;
        },
      }
    );
  },

  fetchAllForOperation(
    idOperation: string
  ): Promise<ApiResponse<SuiviBudgetOperationImport[]>> {
    return apiRequest.get(`/operations/${idOperation}/suivi-budget`);
  },
  downloadSuiviBudget(idOperation: string): Promise<ApiResponse<BlobPart>> {
    return apiRequest.get(`/operations/${idOperation}/suivi-budget/export`);
  },
};
