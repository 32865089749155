export function getDocExtension(fileName: string): string {
  const nameSplitted = fileName.split(".");
  return nameSplitted[nameSplitted.length - 1];
}

export function isExecutableFile(fileName: string, mimeType?: string): boolean {
  return (
    ExecutableExtensions.includes(getDocExtension(fileName).toUpperCase()) ||
    !!(mimeType && ForbiddenMimetypes.includes(mimeType))
  );
}

export function filterExecutableFiles(files: File[]): File[] {
  return files.filter((file) => isExecutableFile(file.name, file.type));
}

export function filterNonExecutableFiles(files: File[]): File[] {
  return files.filter((file) => !isExecutableFile(file.name, file.type));
}

export const ExecutableExtensions = [
  "HTML",
  "ACTION",
  "APK",
  "APP",
  "BAT",
  "BIN",
  "CMD",
  "COM",
  "COMMAND",
  "CPL",
  "CSH",
  "EXE",
  "GADGET",
  "INF",
  "INS",
  "INX",
  "IPA",
  "ISU",
  "JOB",
  "JSE",
  "KSH",
  "LNK",
  "MSC",
  "MSI",
  "MSP",
  "MST",
  "OSX",
  "OUT",
  "PAF",
  "PIF",
  "PRG",
  "PS1",
  "REG",
  "RGS",
  "RUN",
  "SCR",
  "SCT",
  "SHB",
  "SHS",
  "U3P",
  "VB",
  "VBE",
  "VBS",
  "VBSCRIPT",
  "WORKFLOW",
  "WS",
  "WSF",
  "WSH",
  "0XE",
  "73K",
  "89K",
  "A6P",
  "AC",
  "ACC",
  "ACR",
  "ACTM",
  "AHK",
  "AIR",
  "APP",
  "ARSCRIPT",
  "AS",
  "ASB",
  "AWK",
  "AZW2",
  "BEAM",
  "BTM",
  "CEL",
  "CELX",
  "CHM",
  "COF",
  "CRT",
  "DEK",
  "DLD",
  "DMC",
  "DOCM",
  "DOTM",
  "DXL",
  "EAR",
  "EBM",
  "EBS",
  "EBS2",
  "ECF",
  "EHAM",
  "ELF",
  "ES",
  "EX4",
  "EXOPC",
  "EZS",
  "FAS",
  "FKY",
  "FPI",
  "FRS",
  "FXP",
  "GS",
  "HAM",
  "HMS",
  "HPF",
  "HTA",
  "IIM",
  "IPF",
  "ISP",
  "JAR",
  "JS",
  "JSX",
  "KIX",
  "LO",
  "LS",
  "MAM",
  "MCR",
  "MEL",
  "MPX",
  "MRC",
  "MS",
  "MS",
  "MXE",
  "NEXE",
  "OBS",
  "ORE",
  "OTM",
  "PEX",
  "PLX",
  "POTM",
  "PPAM",
  "PPSM",
  "PPTM",
  "PRC",
  "PVD",
  "PWC",
  "PYC",
  "PYO",
  "QPX",
  "RBX",
  "ROX",
  "RPJ",
  "S2A",
  "SBS",
  "SCA",
  "SCAR",
  "SCB",
  "SCRIPT",
  "SMM",
  "SPR",
  "TCP",
  "THM",
  "TLB",
  "TMS",
  "UDF",
  "UPX",
  "URL",
  "VLX",
  "VPM",
  "WCM",
  "WIDGET",
  "WIZ",
  "WPK",
  "WPM",
  "XAP",
  "XBAP",
  "XLAM",
  "XLM",
  "XLSM",
  "XLTM",
  "XQT",
  "XYS",
  "ZL9",
  "DLL",
  "INK",
  "SYS",
  "SWF",
  "GZQUAR",
  "ZIX",
  "OCX",
  "CLA",
  "CLASS",
  "DRV",
  "OZD",
  "ARU",
  "WMF",
  "PGM",
  "DEV",
  "XNXX",
  "VXD",
  "TPS",
  "VBA",
  "PCX",
  "BOO",
  "386",
  "WSC",
  "PHP",
  "PHP3",
  "APPLICATION",
  "PS1XML",
  "PS2",
  "PS2XML",
  "PSC1",
  "PSC2",
  "MSH",
  "MSH1",
  "MSH2",
  "MSHXML",
  "MSH1XML",
  "MSH2XML",
  "SCF",
  "SLDM",
];

export const ForbiddenMimetypes = [
  "application/bat",
  "application/vnd.microsoft.portable-executable",
  "application/x-dosexec",
  "application/x-ms-application",
  "application/x-msdownload",
  "application/java-archive",
  "application/mediaservercontrol+xml",
  "application/wsdl+xml",
  "application/wspolicy+xml",
  "model/mesh",
  "application/x-msi",
  "application/java-archive",
  "application/x-msdos-program",
  "application/x-bat",
  "application/vnd.microsoft.portable-executable",
  "application/x-executable",
  "application/x-dosexec",
  "application/x-sharedlib",
  "application/x-msdownload",
  "application/x-sh",
  "text/javascript",
  "application/sparql-query",
  "application/ecmascript",
  "application/javascript",
  "text/vnd.wap.wmlscript",
  "application/x-csh",
  "application/x-tcl",
];
