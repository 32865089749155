<template>
  <div class="profile-div">
    <UserProfileImageEdit v-if="canEdit" :id-profile-image="idProfileImage" />
    <div
      :class="[
        { superuser: isSuperUser, 'operation-header': isInOperationHeader },
        profilePictureSize,
      ]"
      class="profile-icon"
    >
      <img
        v-if="isUserProfileImageReady"
        :src="profileImage"
        alt="image profile"
        class="profile-image"
      />
      <div v-else>{{ fullNameInitials }}</div>
      <SuperUserIcon
        v-if="isSuperUser && shouldShowSuperUserSticker"
        :class-color="getSuperUserIconColor"
        alt="icône super user"
        class="admin-icon"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { getSetupContext } from "@/utils/vueUtils";

import UserProfileImageEdit from "./UserProfileImageEdit.vue";

import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import { Role } from "@domain/enum/role";
import SuperUserIcon from "@/components/shared/SuperUserIcon.vue";

export default defineComponent({
  components: { SuperUserIcon, UserProfileImageEdit },
  props: {
    fullNameInitials: {
      type: String,
      required: true,
    },
    isSuperUser: {
      type: Boolean,
      default: false,
    },
    profilePictureSize: {
      type: String,
      default: "",
    },
    idProfileImage: {
      type: String,
      default: "",
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    shouldHideSuperUserSticker: {
      type: Boolean,
      default: false,
    },
    isInOperationHeader: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String as PropType<Role>,
      default: undefined,
    },
  },
  setup(props) {
    const { userProfileStore } = getSetupContext();

    const profileImage = ref("");

    const isUserProfileImageReady = computed((): boolean => {
      return profileImage.value !== "";
    });

    const shouldShowSuperUserSticker = computed((): boolean => {
      return !props.shouldHideSuperUserSticker;
    });

    const superUserBorderColor = computed<string>(() => {
      return props.role === Role.BANQUE ? "#3860c7" : "#ff861d";
    });
    const getSuperUserIconColor = computed<string>(() => {
      return props.role === Role.BANQUE ? "blue" : "primary";
    });
    watch(
      () => props.idProfileImage,
      () => {
        fetchUserProfileImage();
      }
    );

    onMounted(async () => {
      await fetchUserProfileImage();
    });

    async function fetchUserProfileImage(): Promise<void> {
      profileImage.value = "";
      if (props.idProfileImage && props.idProfileImage !== "") {
        profileImage.value = await userProfileStore.fetchUserProfileImage(
          props.idProfileImage
        );
      }
    }

    return {
      // Data
      profileImage,

      // Computed
      isUserProfileImageReady,
      shouldShowSuperUserSticker,
      superUserBorderColor,
      getSuperUserIconColor,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";
@import "@/sass/media.scss";

img {
  vertical-align: 0;
}

.edit-div {
  display: none;
}

.profile-div {
  position: relative;
}

.profile-icon {
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 600;
  border: 2px solid black;
  border-radius: 50%;
  justify-content: center;

  &.operation-header {
    border: 1px solid $operation-banner-text;
    color: $operation-banner-text;
    min-width: 26px;
    margin-right: 2px;
  }

  .admin-icon {
    position: relative;
    border-radius: 50%;
  }

  &.superuser {
    border: 2px solid v-bind(superUserBorderColor);
  }
}

.small-size-user-profile {
  width: 40px;
  height: 40px;
  min-width: 40px;
  line-height: 36px;
  position: relative;

  .admin-icon {
    position: absolute;
    top: 20px;
    left: 25px;
    width: 20px;
  }

  .profile-image {
    margin: 0;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    object-fit: cover;
  }

  &.superuser {
    .profile-image {
      width: 36px;
      height: 36px;
    }
  }
}

.huge-size-user-profile {
  width: 144px;
  height: 144px;
  font-size: 64px;
  line-height: 125px;
  margin: 8px;
  position: relative;

  .admin-icon {
    position: absolute;
    top: 100px;
    left: 100px;
    width: 35px;
    z-index: 3;
  }

  .profile-image {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
  }

  &.superuser {
    border: 4px solid v-bind(superUserBorderColor);

    .profile-image {
      width: 136px;
      height: 136px;
    }
  }
}
</style>
