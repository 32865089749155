<template>
  <div class="banner-specific-container">
    <div class="icon-container">
      <img
        alt="icône document signé"
        class="banner-icon"
        src="@/assets/event/DocSigned.svg"
      />
    </div>
    <div class="content mini">
      Une lettre d'accord sur l'opération
      <span class="semi-bold">{{ notification.payload.operationName }}</span>
      est signée !
    </div>
  </div>
</template>

<script lang="ts">
import { Notification } from "@/domain/notification";

import { defineComponent } from "vue";
export default defineComponent({
  props: {
    notification: {
      type: Object as () => Notification,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.banner-specific-container {
  display: flex;
}
</style>
