import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { SuiviTravauxFilter } from "@domain/dto/suiviTravauxFilter";
import { SuiviTravauxMassifOperation } from "@domain/dto/suiviTravauxMassif";
import { SuiviTravauxMassifCreateRequest } from "@domain/dto/suiviTravauxMassifCreateRequest";
import { SuiviTravauxMassifCreationResponse } from "@domain/dto/suiviTravauxMassifCreationResponse";
import { SuiviTravauxMassifSearchRequest } from "@domain/dto/suiviTravauxMassifSearchRequest";

export default {
  fetchAll(
    searchRequest: SuiviTravauxMassifSearchRequest
  ): Promise<ApiResponse<SuiviTravauxMassifOperation[]>> {
    return apiRequestThrottle.post(`/suivi-travaux/search`, searchRequest, {
      validateStatus(status: number) {
        return status === 200;
      },
    });
  },
  uploadSuiviTravauxMassifData(
    creationRequestList: SuiviTravauxMassifCreateRequest[]
  ): Promise<ApiResponse<SuiviTravauxMassifCreationResponse>> {
    return apiRequestThrottle.post("/suivi-travaux", creationRequestList);
  },
  fetchSuiviTravauxMassifExport(): Promise<ApiResponse<BlobPart>> {
    return apiRequest.get("/suivi-travaux/export");
  },
  fetchSuiviTravauxFilters(): Promise<ApiResponse<SuiviTravauxFilter>> {
    return apiRequest.get("/suivi-travaux/filters");
  },
};
