export enum GedTargetResource {
  OPERATION = "OPERATION",
  DEMANDE = "DEMANDE",
  DEMANDE_PROJECT = "DEMANDE_PROJECT",
  DEMANDE_DOCUMENT_VALIDATION = "DEMANDE_DOCUMENT_VALIDATION",
  PROFILE = "PROFILE",
  LETTRE_ACCORD_PROJECT = "LETTRE_ACCORD_PROJECT",
  POOL_ACCORD_COMMERCIAL_PROJECT = "POOL_ACCORD_COMMERCIAL_PROJECT",
  POOL_ACCORD_COMMERCIAL_BULK_PROJECT = "POOL_ACCORD_COMMERCIAL_BULK_PROJECT",
  SOCIETE_SUPPORT_SURETE_PROJECT = "SOCIETE_SUPPORT_SURETE_PROJECT",
  SOCIETE_SUPPORT = "SOCIETE_SUPPORT",

  DEMANDE_ACTE_SIGNE = "DEMANDE_ACTE_SIGNE",
  DEMANDE_CERTIFICATE = "DEMANDE_CERTIFICATE",

  POOL_ACCORD_COMMERCIAL_SIGNED_ACTE = "POOL_ACCORD_COMMERCIAL_SIGNED_ACTE",
  POOL_ACCORD_COMMERCIAL_CERTIFICATE = "POOL_ACCORD_COMMERCIAL_CERTIFICATE",

  LETTRE_ACCORD_SIGNED_ACTE = "LETTRE_ACCORD_SIGNED_ACTE",
  LETTRE_ACCORD_CERTIFICATE = "LETTRE_ACCORD_CERTIFICATE",

  SURETE_SIGNED_ACTE = "SURETE_SIGNED_ACTE",
  SURETE_CERTIFICATE = "SURETE_CERTIFICATE",

  EDITION_EN_LIGNE_DOCUMENT = "EDITION_EN_LIGNE_DOCUMENT",
  EDITION_EN_LIGNE_BROUILLON = "EDITION_EN_LIGNE_BROUILLON",

  OPERATION_ESG_FORM = "OPERATION_ESG_FORM",
  EDITION_EN_LIGNE_ESG_FORM = "EDITION_EN_LIGNE_ESG_FORM",
  EDITION_EN_LIGNE_ESG_BROUILLON = "EDITION_EN_LIGNE_ESG_BROUILLON",

  SUIVI_TRAVAUX_STEP_DOCUMENT = "SUIVI_TRAVAUX_STEP_DOCUMENT",

  EDITION_EN_LIGNE_LETTRE_ACCORD_DOCUMENT = "EDITION_EN_LIGNE_LETTRE_ACCORD_DOCUMENT",
  EDITION_EN_LIGNE_LETTRE_ACCORD_BROUILLON = "EDITION_EN_LIGNE_LETTRE_ACCORD_BROUILLON",

  SUIVI_COMMERCIALISATION = "SUIVI_COMMERCIALISATION",
  EDITION_EN_LIGNE_SURETE_DOCUMENT = "EDITION_EN_LIGNE_SURETE_DOCUMENT",
  EDITION_EN_LIGNE_SURETE_BROUILLON = "EDITION_EN_LIGNE_SURETE_BROUILLON",

  OPERATION_POOL = "OPERATION_POOL",

  OPERATION_SUIVI_BUDGET_PROMOTEUR = "OPERATION_SUIVI_BUDGET_PROMOTEUR",
}

export enum PreviewFocusedResource {
  DEMANDE = GedTargetResource.DEMANDE,
  LETTRE_ACCORD_PROJECT = GedTargetResource.LETTRE_ACCORD_PROJECT,
  SOCIETE_SUPPORT_SURETE_PROJECT = GedTargetResource.SOCIETE_SUPPORT_SURETE_PROJECT,
  POOL_ACCORD_COMMERCIAL_PROJECT = GedTargetResource.POOL_ACCORD_COMMERCIAL_PROJECT,
}
