<template>
  <FirstLoginUserModal />
  <PreviewDocumentModal v-if="shouldShowPreviewDocument" />
  <EditDemandeDocumentModal v-if="shouldShowEditDemandeDocument" />
  <EditLettreAccordDocumentModal v-if="shouldShowEditLettreAccordDocument" />
  <EditSureteDocumentModal v-if="shouldShowEditSureteDocument" />
  <EditESGDocumentModal v-if="shouldShowESGEditionDocument" />
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import PreviewDocumentModal from "@/components/document/PreviewDocumentModal.vue";
import FirstLoginUserModal from "@/components/user/FirstLoginUserModal.vue";
import EditDemandeDocumentModal from "@/components/document/EditDemandeDocumentModal.vue";
import EditLettreAccordDocumentModal from "@/components/document/EditLettreAccordDocumentModal.vue";
import EditESGDocumentModal from "@/components/document/EditESGDocumentModal.vue";

import { getSetupContext } from "@/utils/vueUtils";
import { shouldShowEditionModal } from "@/utils/collaboraUtils";
import EditSureteDocumentModal from "@/components/document/EditSureteDocumentModal.vue";

export default defineComponent({
  name: "TheModals",
  components: {
    EditSureteDocumentModal,
    EditDemandeDocumentModal,
    EditLettreAccordDocumentModal,
    FirstLoginUserModal,
    PreviewDocumentModal,
    EditESGDocumentModal,
  },
  setup() {
    const {
      documentStore,
      editDocumentStore,
      editLettreAccordDocumentStore,
      editESGDocumentStore,
      editSureteDocumentStore,
    } = getSetupContext();

    const shouldShowPreviewDocument = computed<boolean>(() => {
      return documentStore.getIsShowPreviewDocument;
    });

    const shouldShowEditDemandeDocument = computed<boolean>(() => {
      return shouldShowEditionModal(editDocumentStore.getEditedDocument);
    });

    const shouldShowEditLettreAccordDocument = computed<boolean>(() => {
      return shouldShowEditionModal(
        editLettreAccordDocumentStore.getEditedDocument
      );
    });

    const shouldShowESGEditionDocument = computed<boolean>(() => {
      return shouldShowEditionModal(editESGDocumentStore.getEditedDocument);
    });

    const shouldShowEditSureteDocument = computed<boolean>(() => {
      return shouldShowEditionModal(editSureteDocumentStore.getEditedDocument);
    });

    return {
      shouldShowPreviewDocument,
      shouldShowEditDemandeDocument,
      shouldShowEditLettreAccordDocument,
      shouldShowESGEditionDocument,
      shouldShowEditSureteDocument,
    };
  },
});
</script>
