import { SuiviBudgetOperationImport } from "@domain/dto/suiviBudgetSomme";
import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import suiviBudgetApi from "@/api/suiviBudgetApi";
import { SuiviBudgetDocumentUpload } from "@/domain/suiviBudgetDocumentUpload";
import { buildSuiviBudgetImportCreation } from "@/utils/suiviBudgetImportPromoteurUtils";
import { toastError } from "@/utils/toastUtils";
import { downloadClickLink } from "@/utils/downloadFileUtils";
import { NemmoMimeType } from "@domain/enum/nemmoMimeType";

export enum SuiviBudgetVue {
  GLOBAL = "GLOBAL",
  TRANCHE = "TRANCHE",
}

type SuiviBudgetStateType = {
  suiviBudgetList: SuiviBudgetOperationImport[];
  vue: SuiviBudgetVue;
};

export const useSuiviBudget = defineStore(ModuleType.SuiviBudget, {
  state: (): SuiviBudgetStateType => ({
    suiviBudgetList: [],
    vue: SuiviBudgetVue.GLOBAL,
  }),

  getters: {
    getSuiviBudgetList(state): SuiviBudgetOperationImport[] {
      return state.suiviBudgetList;
    },

    getSuiviBudgetVue(state): SuiviBudgetVue {
      return state.vue;
    },
  },
  actions: {
    SetSuiviBudgetVue(vue: SuiviBudgetVue): void {
      this.vue = vue;
    },

    async fetchSuiviBudgetList(idOperation: string): Promise<void> {
      const result = await suiviBudgetApi.fetchAllForOperation(idOperation);
      this.suiviBudgetList = result.data;
    },

    async uploadSuiviBudgetDocumentList(
      idOperation: string,
      idBanque: string,
      documents: SuiviBudgetDocumentUpload[]
    ): Promise<void> {
      try {
        const importCreation = buildSuiviBudgetImportCreation(
          idOperation,
          idBanque,
          documents
        );
        await suiviBudgetApi.uploadSuiviBudgetPromoteur(importCreation);
      } catch (error) {
        toastError("Erreur lors de l'ajout de document(s)");
      }
    },

    async downloadSuiviBudget(idOperation: string): Promise<void> {
      await suiviBudgetApi
        .downloadSuiviBudget(idOperation)
        .then((response) => {
          const buffer = Buffer.from(response.data as string, "base64");
          downloadClickLink(buffer, {
            name: "export_suivi_budget.xlsx",
            type: NemmoMimeType.EXCEL,
          });
        })
        .catch(() => {
          toastError("Erreur lors du téléchargement du document.");
        });
    },
  },
});
