import * as Sentry from "@sentry/vue";
import { App } from "vue";
import { Router } from "vue-router";

export function initSentry(
  app: App,
  router: Router,
  dsn: string,
  release: string,
  environment: string,
  disableReplaysTexts: boolean
) {
  Sentry.init({
    app,
    dsn,
    release,
    environment,
    ignoreErrors: [/THROTTLED/],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay({
        maskAllText: disableReplaysTexts,
      }),
    ],

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: [
      /^https:\/\/(app|qual|demo|poc|ad|int|sentry|admin).neomi.immo/,
    ],
    tracesSampleRate: 1.0,
  });
}
