<template>
  <div class="banner-specific-container">
    <div class="icon-container">
      <img
        alt="lettre accord sign ready icon"
        class="banner-icon"
        src="@/assets/event/WaitingSign.svg"
      />
    </div>
    <div class="content mini">
      Une lettre d'accord est prête à être signée sur l'opération
      <span class="semi-bold">
        {{ notification.payload.operationName }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Notification } from "@/domain/notification";

import { defineComponent } from "vue";
export default defineComponent({
  props: {
    notification: {
      type: Object as () => Notification,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.banner-specific-container {
  display: flex;
}
</style>
