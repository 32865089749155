export enum EngagementType {
  // GFA
  GARANTIE_FINANCIERE_ACHEVEMENT = "GARANTIE_FINANCIERE_ACHEVEMENT",
  // CAUTION
  CAUTION_INDEMNITE_IMMOBILISATION = "CAUTION_INDEMNITE_IMMOBILISATION",
  GARANTIE_PAIEMENT_TERME = "GARANTIE_PAIEMENT_TERME",
  CAUTION_PAIEMENT_TRAVAUX = "CAUTION_PAIEMENT_TRAVAUX",
  AUTRE_CAUTION = "AUTRE_CAUTION",

  // CRÉDIT
  CREDIT_TERRAIN = "CREDIT_TERRAIN",
  CREDIT_ACCOMPAGMENT = "CREDIT_ACCOMPAGMENT",
  AUTRE_CREDIT = "AUTRE_CREDIT",

  LETTRE_ACCORD = "LETTRE_ACCORD",
}
