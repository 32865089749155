export enum OperationMessageEventType {
  OPERATION_DOCUMENT_UPLOADED = "OPERATION_DOCUMENT_UPLOADED",
  DEMANDE_SHARED_WITH_BANQUE = "DEMANDE_SHARED_WITH_BANQUE",
  DEMANDE_PROJECT_UPLOADED = "DEMANDE_PROJECT_UPLOADED",
  DEMANDE_PROJECT_VALIDATED = "DEMANDE_PROJECT_VALIDATED",
  DEMANDE_SIGNED = "DEMANDE_SIGNED",
  LETTRE_ACCORD_CREATED = "LETTRE_ACCORD_CREATED",
  LETTRE_ACCORD_PROJECT_UPLOADED = "LETTRE_ACCORD_PROJECT_UPLOADED",
  LETTRE_ACCORD_SIGN_READY = "LETTRE_ACCORD_SIGN_READY",
  LETTRE_ACCORD_SIGNED = "LETTRE_ACCORD_SIGNED",
  USER_MESSAGE = "USER_MESSAGE",
  ACTE_SECONDAIRE_CREATED = "ACTE_SECONDAIRE_CREATED",

  ALL_POOL_PARTICIPANT = "ALL_POOL_PARTICIPANT",

  DEMANDE_MAINLEVEE_VALIDATED = "DEMANDE_MAINLEVEE_VALIDATED",
  DEMANDE_MAINLEVEE_REFUSED = "DEMANDE_MAINLEVEE_REFUSED",
  DEMANDE_MAINLEVEE_REQUEST = "DEMANDE_MAINLEVEE_REQUEST",
  DEMANDE_MAINLEVEE_DOCUMENT_UPLOADED = "DEMANDE_MAINLEVEE_DOCUMENT_UPLOADED",
  POOL_DEMANDE_MAINLEVEE_VALIDATED = "POOL_DEMANDE_MAINLEVEE_VALIDATED",

  USER_ABSENCE_MESSAGE = "USER_ABSENCE_MESSAGE",

  ACCORD_COMMERCIAL_PROJET_AJOUTE = "ACCORD_COMMERCIAL_PROJET_AJOUTE",
  ACCORD_COMMERCIAL_PROJET_VALIDE = "ACCORD_COMMERCIAL_PROJET_VALIDE",
  ACCORD_COMMERCIAL_PROJET_DECLINED = "ACCORD_COMMERCIAL_PROJET_DECLINED",
  ACCORD_COMMERCIAL_PROJET_PRET_A_SIGNE = "ACCORD_COMMERCIAL_PROJET_PRET_A_SIGNE",
  ACCORD_COMMERCIAL_PROJET_SIGNE = "ACCORD_COMMERCIAL_PROJET_SIGNE",

  SUIVI_BUDGET_PROMOTEUR_IMPORT = "SUIVI_BUDGET_PROMOTEUR_IMPORT",
}
