<template>
  <div class="banner-specific-container">
    <div class="icon-container">
      <img
        alt="icône nouvelle demande"
        class="banner-icon"
        src="@/assets/event/NewDemand.svg"
      />
    </div>
    <div class="content mini">
      Une nouvelle demande de
      <span class="semi-bold">{{ demandeCategory }}</span>
      -
      <span class="semi-bold">
        {{ notification.payload.demandeName }}
      </span>
      a été ajoutée à l'opération
      <span class="semi-bold">
        {{ notification.payload.operationName }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Notification } from "@/domain/notification";
import { toCategory } from "@/utils/notificationUtils";
import { computed, defineComponent } from "vue";
export default defineComponent({
  props: {
    notification: {
      type: Object as () => Notification,
      required: true,
    },
  },
  setup(props) {
    const demandeCategory = computed((): string => {
      const demandeType = props.notification.payload.demandeType;
      return demandeType ? toCategory(demandeType) : "inconnu";
    });

    return {
      demandeCategory,
    };
  },
});
</script>

<style lang="scss" scoped>
.banner-specific-container {
  display: flex;
}
</style>
