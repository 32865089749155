import Keycloak from "keycloak-js";
import config from "@/config";

const keycloakConfig = {
  clientId: config.KEYCLOAK_CLIENT,
  url: config.KEYCLOAK_URL,
  realm: config.KEYCLOAK_REALM,
};

// @ts-ignore
const keycloak: Keycloak = new Keycloak(keycloakConfig);

export default keycloak;
