export function normalizeString(string: string): string {
  return string
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

export function arrayToString(array: string[]): string {
  return array.join(", ");
}
