import { SignataireExterne } from "@domain/dto/signataireExterne";
import { SignMethod } from "@domain/enum/signMethod";
import { DocusignConfigurationError } from "@domain/enum/docusignConfigurationError";
import { SignatureDisplayOptions } from "@/components/shared/signature/SignatureDisplayOptions";

export function isSignMethodElectronic(signMethod: SignMethod): boolean {
  return (
    signMethod === SignMethod.ELECTRONIC ||
    signMethod === SignMethod.FREE_ELECTRONIC
  );
}

export function isSignMethodManual(signMethod: SignMethod): boolean {
  return signMethod === SignMethod.MANUAL;
}

export function isSignMethodFreeElectronic(signMethod: SignMethod): boolean {
  return signMethod === SignMethod.FREE_ELECTRONIC;
}

export function labelNombreSignataire(n: number): string {
  if (n <= 1) {
    return `${n} signataire`;
  }
  return `${n} signataires`;
}

export function buildSignatureDisplayOptions(
  displayOptions: Partial<SignatureDisplayOptions> = {}
): SignatureDisplayOptions {
  return {
    availableSignMethods: [
      SignMethod.ELECTRONIC,
      SignMethod.FREE_ELECTRONIC,
      SignMethod.MANUAL,
    ],
    canRollbackToSignature: false,
    canRollbackToCoConstruction: false,
    firstPartyLabel: "Signataires promoteur",
    secondPartyLabel: "Signataires banque",
    ...displayOptions,
  };
}

export function isSignataireExterneValid(
  signataireExterne: SignataireExterne
): boolean {
  return (
    Boolean(signataireExterne.firstname) &&
    Boolean(signataireExterne.lastname) &&
    Boolean(signataireExterne.email) &&
    Boolean(signataireExterne.phoneNumber)
  );
}

export function formatDocusignConfigurationErrorList(
  errorList: DocusignConfigurationError[]
): string {
  if (errorList.length === 0) {
    return "La configuration de la signature a échoué pour une raison inconnue.";
  }

  const reasons = errorList.map<string>((error) => {
    switch (error) {
      case DocusignConfigurationError.DUPLICATED_RECIPIENT:
        return "Un ou plusieurs signataires sont dupliqués.";
      case DocusignConfigurationError.NOT_ENOUGH_BANQUE_RECIPIENT:
        return "Il n'y a pas assez de signataire banque.";
      case DocusignConfigurationError.NOT_ENOUGH_PROMOTEUR_RECIPIENT:
        return "Il n'y a pas assez de signataire promoteur.";
      case DocusignConfigurationError.NOT_ENOUGH_SIGNATAIRE_EXTERNE_RECIPIENT:
        return "Il n'y a pas assez de signataire externe.";
      case DocusignConfigurationError.TOO_MANY_BANQUE_RECIPIENT:
        return "Il y a trop de signataire banque.";
      case DocusignConfigurationError.TOO_MANY_PROMOTEUR_RECIPIENT:
        return "Il y a trop de signataire promoteur.";
      case DocusignConfigurationError.TOO_MANY_SIGNATAIRE_EXTERNE_RECIPIENT:
        return "Il y a trop de signataire externe.";
      case DocusignConfigurationError.INCORRECT_RECIPIENT_INFORMATION_FIRSTNAME:
        return "Un signataire n'a pas renseigné de prénom.";
      case DocusignConfigurationError.INCORRECT_RECIPIENT_INFORMATION_LASTNAME:
        return "Un signataire n'a pas renseigné de nom.";
      case DocusignConfigurationError.INCORRECT_RECIPIENT_INFORMATION_PHONENUMBER:
        return "Un signataire n'a pas renseigné de numéro de téléphone.";
      case DocusignConfigurationError.INCORRECT_RECIPIENT_INFORMATION_EMAIL:
        return "Un signataire n'a pas renseigné d'adresse email.";
    }
  });

  return (
    "La configuration de la signature a échoué pour les raisons suivantes : " +
    reasons.join(" ")
  );
}
