<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="classColor"
      d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0Z"
    />
    <path
      d="M13.1589 14.9302L10.0013 13.1034L6.84366 14.9302C6.47102 15.1467 6.01012 14.8266 6.10819 14.4217L6.94172 10.9753L4.16653 8.66822C3.84292 8.39514 4.01943 7.87723 4.45091 7.83957L8.11849 7.53824L9.55021 4.28956C9.71692 3.90348 10.2857 3.90348 10.4524 4.28956L11.8841 7.52882L15.5517 7.83015C15.9832 7.86782 16.1597 8.38572 15.8263 8.6588L13.0511 10.9658L13.8846 14.4217C13.9827 14.8266 13.5316 15.1467 13.1589 14.9302V14.9302Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SuperUserIcon",
  props: {
    classColor: { type: String, default: "primary" },
  },
});
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";

.primary {
  fill: $primary-main;
}

.blue {
  fill: $blue2;
}
</style>
