<template>
  <div class="banner-specific-container">
    <div class="icon-container">
      <img
        alt="icône document validé"
        class="banner-icon"
        src="@/assets/event/DocSigned.svg"
      />
    </div>
    <div class="content mini">
      <span class="semi-bold">
        {{ notification.payload.authorFirstName }}
        {{ notification.payload.authorLastName }} -
        {{ notification.payload.authorGroup }}
      </span>
      a validé la dernière version du projet de lettre d'accord de
      <span class="semi-bold">{{ notification.payload.operationName }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Notification } from "@/domain/notification";

import { defineComponent } from "vue";
export default defineComponent({
  props: {
    notification: {
      type: Object as () => Notification,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.banner-specific-container {
  display: flex;
}
</style>
