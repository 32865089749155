import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import { PoolFormalise } from "@/domain/poolFormalise";
import { PoolFormaliseCreationRequest } from "@/domain/dto/poolFormaliseCreationRequest";
import poolFormaliseApi from "@/api/poolFormaliseApi";
import { toastError } from "@/utils/toastUtils";
import cloneDeep from "lodash/cloneDeep";
import { ApiResponse } from "@/apiRequest";
import axios from "axios";
import * as Sentry from "@sentry/vue";
import { PoolFormaliseParticipantLight } from "@/domain/poolFormaliseParticipantLight";

type PoolFormaliseState = {
  poolFormaliseList: PoolFormalise[];
  poolFormaliseEdited: PoolFormalise | undefined;
  currentPoolFormaliseParticipant: PoolFormaliseParticipantLight | undefined;
};

export const usePoolFormaliseStore = defineStore(ModuleType.PoolFormalise, {
  state: (): PoolFormaliseState => ({
    poolFormaliseList: [],
    poolFormaliseEdited: undefined,
    currentPoolFormaliseParticipant: undefined,
  }),
  getters: {
    getPoolFormaliseList(state): PoolFormalise[] {
      return state.poolFormaliseList;
    },
    getPoolFormaliseEdited(state): PoolFormalise | undefined {
      return state.poolFormaliseEdited;
    },
    getCurrentPoolFormaliseParticipant(
      state
    ): PoolFormaliseParticipantLight | undefined {
      return state.currentPoolFormaliseParticipant;
    },
  },
  actions: {
    SetPoolFormaliseEdited(poolFormaliseEdited: PoolFormalise) {
      this.poolFormaliseEdited = cloneDeep(poolFormaliseEdited);
    },
    ResetPoolFormaliseEdited() {
      this.poolFormaliseEdited = undefined;
    },
    async fetchPoolFormaliseList(): Promise<PoolFormalise[]> {
      const response = await poolFormaliseApi.fetchPoolFormaliseList();
      this.poolFormaliseList = response.data;

      return response.data;
    },
    async upsertPoolFormalise(
      idPoolFormalise: string | null,
      poolFormaliseCreationRequest: PoolFormaliseCreationRequest
    ): Promise<PoolFormalise | undefined> {
      let response: ApiResponse<PoolFormalise | undefined>;
      try {
        if (idPoolFormalise) {
          response = await poolFormaliseApi.updatePoolFormalise(
            idPoolFormalise,
            poolFormaliseCreationRequest
          );
        } else {
          response = await poolFormaliseApi.createPoolFormalise(
            poolFormaliseCreationRequest
          );
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          response = {
            status: parseInt(err.code ?? "500"),
            data: undefined,
          };
        } else {
          Sentry.captureException(err);
          toastError("Une erreur s'est produite");
          return;
        }
      }

      switch (response.status) {
        case 201:
          return response.data;

        case 409:
          toastError("Un pool formalisé avec ce nom existe déjà");
          break;

        default:
          toastError("Une erreur s'est produite");
          break;
      }
      return undefined;
    },
    async fetchPoolFormaliseParticipant(
      idPoolFormaliseParticipant: string,
      idPoolFormalise: string | null
    ): Promise<PoolFormaliseParticipantLight | undefined> {
      const response = await poolFormaliseApi.fetchParticipantById(
        idPoolFormaliseParticipant,
        idPoolFormalise
      );
      this.currentPoolFormaliseParticipant = response.data;
      return response.data;
    },
  },
});
