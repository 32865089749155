<template>
  <EditDocumentModal
    :editing-document-link="editingDocumentLink"
    :modal-message="modalMessage"
    @brouillon-document-interval="onBrouillonInterval"
    @close-edit-document-modal="onCloseEditDocumentModal"
  />
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { getEditionLink } from "@/utils/collaboraUtils";
import { CollaboraApplication } from "@/utils/collaboraApplication";
import { getSetupContext } from "@/utils/vueUtils";
import EditDocumentModal from "../shared/EditDocumentModal.vue";

export default defineComponent({
  name: "EditESGDocumentModal",
  components: { EditDocumentModal },

  setup() {
    const { editESGDocumentStore } = getSetupContext();

    const editingDocumentLink = computed<string>(() => {
      return getEditionLink(editESGDocumentStore.getEditedDocument);
    });

    const modalMessage = computed<string>(() => {
      return "Voulez-vous publier cette version ?";
    });

    function onBrouillonInterval(collabora: CollaboraApplication | null): void {
      collabora?.saveBrouillon();
    }

    function onCloseEditDocumentModal(
      collabora: CollaboraApplication | null
    ): void {
      collabora?.closeModal();
      editESGDocumentStore.ResetESGEdition();
    }

    return {
      editingDocumentLink,
      modalMessage,
      onBrouillonInterval,
      onCloseEditDocumentModal,
    };
  },
});
</script>
