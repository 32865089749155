import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";

export const useSupportedBrowserInformation = defineStore(
  ModuleType.SupportedBrowserInformation,
  {
    state: () => ({
      isWarningIE: false,
    }),
    getters: {
      getIsWarningIE(state): boolean {
        return state.isWarningIE;
      },
    },
    actions: {
      SetWarningIE(isWarningIE: boolean): void {
        this.isWarningIE = isWarningIE;
      },
    },
  }
);
