import { EtapeImportConfigurationType } from "@/domain/enum/etapeImportConfigurationType";
import { EtapeImportConfiguration } from "@/domain/etapeImportConfiguration";
import { isPositiveInteger } from "@domain/validators/utils";
import { unzip, zip } from "lodash";

export function getConfiguredImportColumnList<T extends string>(
  steps: EtapeImportConfiguration<T>[],
  configuration: Partial<Record<T, number>>
): number[] {
  const columnList: number[] = [];

  for (const [key, col] of Object.entries(configuration)) {
    const step = steps.find((step) => step.key === key);
    if (
      isPositiveInteger(col) &&
      step &&
      step.type === EtapeImportConfigurationType.COLUMN
    ) {
      columnList.push(col);
    }
  }
  return columnList;
}

export function getConfiguredImportHeaderList<T extends string>(
  steps: EtapeImportConfiguration<T>[],
  configuration: Partial<Record<T, number>>
): number[] {
  const columnList: number[] = [];

  for (const [key, col] of Object.entries(configuration)) {
    const step = steps.find((step) => step.key === key);
    if (
      isPositiveInteger(col) &&
      step &&
      step.type === EtapeImportConfigurationType.HEADER
    ) {
      columnList.push(col);
    }
  }
  return columnList;
}

export function filterOutInvalidRows<T extends string>(
  matrix: string[][],
  configuration: Record<T, number>
): string[][] {
  const columnList = Object.values<number>(configuration);

  return matrix.filter((row) => {
    for (const column of columnList) {
      if (!row[column]) {
        return false;
      }
    }

    return true;
  });
}

export function getDataOnlyImportMatrix<T extends string>(
  matrix: string[][],
  steps: EtapeImportConfiguration<T>[],
  configuration: Record<T, number>
): string[][] {
  const configuredHeaderList = getConfiguredImportHeaderList(
    steps,
    configuration
  );

  const dataOnlyMatrix = matrix.slice(Math.max(...configuredHeaderList) + 1);

  return filterOutInvalidRows(dataOnlyMatrix, configuration);
}

export function convertImportMatrixToPreviewData<T extends string>(
  matrix: string[][],
  steps: EtapeImportConfiguration<T>[],
  configuration: Record<T, number>
): string[][] {
  const configuredColumnList = getConfiguredImportColumnList(
    steps,
    configuration
  );

  const dataOnlyMatrix = getDataOnlyImportMatrix(matrix, steps, configuration);

  return filterAndSortMatrix(dataOnlyMatrix, configuredColumnList);
}

/** Tri et filtre les colonnes d'une matrice selon une liste de colonnes */
export function filterAndSortMatrix(
  matrix: string[][],
  columnList: number[]
): string[][] {
  const maxColumnLength = Math.max(...matrix.map((row) => row.length));

  const validColumnList = columnList.filter(
    (col) => col >= 0 && col < maxColumnLength
  );

  if (!validColumnList.length) {
    return [];
  }

  const transposed = zip(...matrix).filter(
    (row): row is string[] => row !== undefined
  );

  const sortedData = validColumnList.map((col) => transposed[col]);

  return unzip(sortedData);
}
