import { DocusignConfigurationError } from "@domain/enum/docusignConfigurationError";

export function isDocusignConfigurationError(
  value: unknown
): value is DocusignConfigurationError {
  return (
    typeof value === "string" &&
    Object.values(DocusignConfigurationError).includes(
      value as DocusignConfigurationError
    )
  );
}

export function isDocusignConfigurationErrorList(
  value: unknown
): value is DocusignConfigurationError[] {
  return Array.isArray(value) && value.every(isDocusignConfigurationError);
}