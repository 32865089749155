<template>
  <div :style="styles" class="spinner spinner-origami" :data-color="color">
    <div :style="innerStyles" class="spinner-inner loading">
      <span class="slice"></span>
      <span class="slice"></span>
      <span class="slice"></span>
      <span class="slice"></span>
      <span class="slice"></span>
      <span class="slice"></span>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
export default defineComponent({
  name: "SpinnerComponent",
  props: {
    size: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "orange",
    },
  },
  setup(props) {
    const innerStyles = computed((): { transform: string } => {
      let size = parseInt(props.size);
      return {
        transform: "scale(" + size / 60 + "," + size / 60 + ")",
      };
    });

    const styles = computed(
      (): { "min-width": string; "min-height": string } => {
        return {
          "min-width": props.size,
          "min-height": props.size,
        };
      }
    );

    return {
      // Computed
      innerStyles,
      styles,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    line-height: 0;
    box-sizing: border-box;
  }

  &[data-color="blue"] {
    --spinner-color: #{$blue2};
  }
  &[data-color="orange"] {
    --spinner-color: #{$primary-main};
  }
  &[data-color="purple"] {
    --spinner-color: #{$purple};
  }
}

.spinner-inner {
  display: block;
  height: 68px;

  .slice {
    border-top: 18px solid transparent;
    border-right: none;
    border-bottom: 16px solid transparent;
    border-left: 30px solid var(--spinner-color, #ff861d);
    position: absolute;
    transform-origin: left bottom;
    border-radius: 3px 3px 0 0;
  }

  @for $i from 1 through 6 {
    .slice:nth-child(#{$i}) {
      transform: rotateZ(60 * $i + deg) rotateY(0deg) rotateX(0);
      animation: 0.15s linear 0.9 - $i * 0.08s origami-hide-#{$i} both 1;
    }
  }

  &.loading {
    @for $i from 1 through 6 {
      .slice:nth-child(#{$i}) {
        transform: rotateZ(60 * $i + deg) rotateY(90deg) rotateX(0);
        animation: 2s origami-cycle-#{$i} linear infinite both;
      }
    }
  }
}

@for $i from 1 through 6 {
  @keyframes origami-cycle-#{$i} {
    $startIndex: $i * 5;
    $reverseIndex: (80 - $i * 5);

    #{$startIndex * 1%} {
      transform: rotateZ(60 * $i + deg) rotateY(90deg) rotateX(0deg);
      border-left-color: var(--spinner-color, #ff861d);
      filter: brightness(0.8);
    }
    #{$startIndex + 5%},
    #{$reverseIndex * 1%} {
      transform: rotateZ(60 * $i + deg) rotateY(0) rotateX(0deg);
      border-left-color: var(--spinner-color, #ff861d);
      filter: brightness(1);
    }

    #{$reverseIndex + 5%},
    100% {
      transform: rotateZ(60 * $i + deg) rotateY(90deg) rotateX(0deg);
      border-left-color: var(--spinner-color, #ff861d);
      filter: brightness(0.8);
    }
  }
}
</style>
