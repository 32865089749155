import { DemandeType } from "@domain/enum/demandeType";

const DemandeTypeLabel: Record<DemandeType, string> = {
  [DemandeType.CAUTION_INDEMNITE_IMMOBILISATION]: "Caution",
  [DemandeType.CAUTION_PAIEMENT_TRAVAUX]: "Caution",
  [DemandeType.AUTRE_CAUTION]: "Caution",
  [DemandeType.CREDIT_ACCOMPAGMENT]: "Crédit",
  [DemandeType.CREDIT_TERRAIN]: "Crédit",
  [DemandeType.AUTRE_CREDIT]: "Crédit",
  [DemandeType.GARANTIE_FINANCIERE_ACHEVEMENT]: "GFA",
  [DemandeType.GARANTIE_PAIEMENT_TERME]: "Garantie",
};

export function formatNameInitials(
  firstName: string,
  lastName: string
): string {
  if (firstName && lastName) {
    return firstName[0].toUpperCase() + lastName[0].toUpperCase();
  } else {
    return "?";
  }
}

export function formatFirstNameLastName(member: {
  firstName: string;
  lastName: string;
}): string {
  return `${member.firstName} ${member.lastName}`;
}

export function formatDemandeName(demande: {
  name: string;
  type: DemandeType;
}): string {
  return `${demande.name} - ${DemandeTypeLabel[demande.type]}`;
}

export function getDocNameOnly(fileName: string): string {
  return fileName.substring(0, fileName.lastIndexOf("."));
}

export function getDocTypeOnly(fileName: string): string {
  const nameSplitted = fileName.split(".");
  return nameSplitted[nameSplitted.length - 1];
}

export function formatPhoneNumber(phoneNumber: string): string {
  phoneNumber = phoneNumber.replace(/\s/g, "");
  let formattedNumber = "";
  if (phoneNumber.startsWith("+")) {
    formattedNumber = phoneNumber.substring(0, 3);
    phoneNumber = " " + phoneNumber.substring(3);
  }
  const splittedPhoneNumber = phoneNumber.split("");
  for (let i = 0; i < splittedPhoneNumber.length; i++) {
    formattedNumber += splittedPhoneNumber[i];
    if (i % 2) {
      formattedNumber += " ";
    }
  }
  return formattedNumber;
}

export function formatDisplayEuroAmount(euroAmount: string): string {
  const euroAmountFloat = parseFloat(euroAmount.replace(",", "."));
  return Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(euroAmountFloat);
}

export function formatSuperficie(superficie: string): string {
  const superficieInt = parseInt(superficie);
  return `${superficieInt} m²`;
}

export function formatChiffreAffaireSuiviCom(
  chiffreAffaire: string,
  isKEuro = false,
  shouldShowEuro = true
): string {
  const chiffreAffaireNumber = Number(chiffreAffaire);

  if (!chiffreAffaire || isNaN(chiffreAffaireNumber)) {
    return "";
  }

  const isInteger = chiffreAffaireNumber % 1 === 0;
  const willRoundUp = Math.round(chiffreAffaireNumber % 1) === 1;

  let result;
  if (isInteger || willRoundUp) {
    result = Intl.NumberFormat("fr-FR", {
      maximumFractionDigits: 0,
    }).format(chiffreAffaireNumber);
  } else {
    result = Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(chiffreAffaireNumber);
  }

  if (shouldShowEuro) {
    if (isKEuro) {
      result += " k€";
    } else {
      result += " €";
    }
  }

  return result;
}

export function formatRegionName(region: string): string {
  return (
    region.charAt(0).toUpperCase() +
    region.slice(1, region.length).toLowerCase()
  );
}
