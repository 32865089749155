import config from "@/config";
import { Socket, io } from "socket.io-client";
import { RoomMemberType, SockitEvent, socketOptions } from "./sockit";
import { Role } from "@domain/enum/role";
import { useUserProfileStore } from "@/store/userProfileModule.pinia";
import { shouldBlockDemandeDocumentEdition } from "@/utils/collaboraUtils";
import { useDocumentEditStore } from "@/store/editDocumentModule.pinia";
import { DemandeUserRoom } from "@domain/dto/demandeUserRoom";
import { useDemandeStore } from "@/store/demandeModule.pinia";

export class DemandeSockitNamespace {
  private static instance: DemandeSockitNamespace;
  private sockit: Socket;

  private constructor(token: string) {
    this.sockit = io(`${config.ROOT_URL}/demande`, {
      ...socketOptions,
      auth: { token },
    });
    this.bindEvents();
  }

  public static getInstance(token: string): DemandeSockitNamespace {
    if (!DemandeSockitNamespace.instance) {
      DemandeSockitNamespace.instance = new DemandeSockitNamespace(token);
    }

    return DemandeSockitNamespace.instance;
  }

  private bindEvents(): void {
    this.sockit.on(
      SockitEvent.USER_JOINED_ROOM,
      (payload: { userRoom: DemandeUserRoom }) => {
        useDocumentEditStore().AddEditingUser(payload.userRoom);
      }
    );

    this.sockit.on(
      SockitEvent.USER_LEFT_ROOM,
      (payload: { idUser: string }) => {
        useDocumentEditStore().RemoveEditingUser(payload.idUser);
      }
    );
    this.sockit.on(
      SockitEvent.USER_JOINED_EDIT_SESSION,
      (payload: { idUser: string }) => {
        useDocumentEditStore().UpdateEditingUser({
          idUser: payload.idUser,
          isEditing: true,
        });
      }
    );
    this.sockit.on(
      SockitEvent.USER_LEFT_EDIT_SESSION,
      (payload: { idUser: string }) => {
        useDocumentEditStore().UpdateEditingUser({
          idUser: payload.idUser,
          isEditing: false,
        });
      }
    );

    this.sockit.on(
      SockitEvent.REFRESH_EDITED_DOCUMENT,
      async (payload: { idOperation: string; idDemande: string }) => {
        const demandeStore = useDemandeStore();

        // On attend 2 secondes après la sauvegarde du document côté backend
        // pour que Collabora puisse être relancé sans erreur
        setTimeout(async () => {
          await demandeStore.fetchDemandeProject({
            idOperation: payload.idOperation,
            idDemande: payload.idDemande,
          });
          useDocumentEditStore().SetIsDocumentEditionBlocked(false);
        }, 2000);
      }
    );

    this.sockit.on(SockitEvent.ALLOW_DOCUMENT_EDITION, () => {
      // On attend 2 secondes après la sauvegarde du document côté backend
      // pour que Collabora puisse être relancé sans erreur
      setTimeout(() => {
        useDocumentEditStore().SetIsDocumentEditionBlocked(false);
      }, 2000);
    });

    this.sockit.on(
      SockitEvent.DOCUMENT_EDITION_BLOCKED,
      (payload: { idDemande: string; role: Role; idUploader: string }) => {
        const userProfile = useUserProfileStore().getUserProfile;
        const demandeRoomUserList = useDocumentEditStore().getEditingUserList;

        const shouldBlockEdition = shouldBlockDemandeDocumentEdition(
          payload,
          demandeRoomUserList,
          userProfile
        );

        if (shouldBlockEdition) {
          useDocumentEditStore().SetIsDocumentEditionBlocked(true);
        }
      }
    );
  }

  public getMembersRoom(idDemande: string): Promise<RoomMemberType[]> {
    return new Promise((resolve) => {
      this.sockit.emit(
        SockitEvent.GET_MEMBERS_DEMANDE_ROOM,
        idDemande,
        (users: RoomMemberType[]) => {
          return resolve(users);
        }
      );
    });
  }

  public joinRoom(demandeRoomOptions: DemandeUserRoom): void {
    this.sockit.emit(SockitEvent.JOIN_ROOM, demandeRoomOptions);
  }

  public leaveRoom(idDemande: string): void {
    this.sockit.emit(SockitEvent.LEAVE_ROOM, idDemande);
  }

  public startEditingDocument(idDemande: string, token: string): void {
    this.sockit.emit(SockitEvent.JOIN_DOCUMENT_EDIT_SESSION, {
      idRoom: idDemande,
      token,
    });
  }

  public stopEditingDocument(idDemande: string): void {
    this.sockit.emit(SockitEvent.LEAVE_DOCUMENT_EDIT_SESSION, {
      idRoom: idDemande,
    });
  }

  public setDocumentModified(): void {
    this.sockit.emit(SockitEvent.MODIFIED_DOCUMENT_EDIT_SESSION);
  }

  public blockDocumentEdition(payload: {
    idDemande: string;
    role: Role;
    idUploader: string;
  }): void {
    this.sockit.emit(SockitEvent.BLOCK_DOCUMENT_EDITION, payload);
  }
}
