import config from "@/config";
import { Socket, io } from "socket.io-client";
import { RoomMemberType, SockitEvent, socketOptions } from "./sockit";
import { LettreAccordUserRoom } from "@domain/dto/lettreAccordUserRoom";
import { Role } from "@domain/enum/role";
import { useEditLettreAccordDocumentStore } from "@/store/editLettreAccordDocumentModule.pinia";
import { useLettreAccordStore } from "@/store/lettreAccordModule.pinia";
import { useUserProfileStore } from "@/store/userProfileModule.pinia";
import { shouldBlockLettreAccordEdition } from "@/utils/lettreAccordUtils";
import { getGroupRole } from "@/utils/groupUtils";

export class LettreAccordSockitNamespace {
  private static instance: LettreAccordSockitNamespace;
  private sockit: Socket;

  private constructor(token: string) {
    this.sockit = io(`${config.ROOT_URL}/lettre-accord`, {
      ...socketOptions,
      auth: { token },
    });
    this.bindEvents();
  }

  public static getInstance(token: string): LettreAccordSockitNamespace {
    if (!LettreAccordSockitNamespace.instance) {
      LettreAccordSockitNamespace.instance = new LettreAccordSockitNamespace(
        token
      );
    }

    return LettreAccordSockitNamespace.instance;
  }

  private bindEvents(): void {
    this.sockit.on("reconnect", () => {
      const lettreAccord = useLettreAccordStore().getLettreAccord;
      const userProfile = useUserProfileStore().getUserProfile;

      if (lettreAccord.id) {
        const lettreAccordRoomOptions: LettreAccordUserRoom = {
          idOperation: lettreAccord.idOperation,
          idLettreAccord: lettreAccord.id,
          idUser: userProfile.id,
          isEditing: false,
          role: getGroupRole(userProfile.roles),
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
        };

        this.joinRoom(lettreAccordRoomOptions);
      }
    });

    this.sockit.on(
      SockitEvent.USER_JOINED_ROOM,
      (payload: { userRoom: LettreAccordUserRoom }) => {
        useEditLettreAccordDocumentStore().AddEditingUser(payload.userRoom);
      }
    );

    this.sockit.on(
      SockitEvent.USER_LEFT_ROOM,
      (payload: { idUser: string }) => {
        useEditLettreAccordDocumentStore().RemoveEditingUser(payload.idUser);
      }
    );
    this.sockit.on(
      SockitEvent.USER_JOINED_EDIT_SESSION,
      (payload: { idUser: string }) => {
        useEditLettreAccordDocumentStore().UpdateEditingUser({
          idUser: payload.idUser,
          isEditing: true,
        });
      }
    );
    this.sockit.on(
      SockitEvent.USER_LEFT_EDIT_SESSION,
      (payload: { idUser: string }) => {
        useEditLettreAccordDocumentStore().UpdateEditingUser({
          idUser: payload.idUser,
          isEditing: false,
        });
      }
    );

    this.sockit.on(
      SockitEvent.REFRESH_EDITED_DOCUMENT,
      async (_payload: { idOperation: string; idLettreAccord: string }) => {
        const lettreAccordStore = useLettreAccordStore();

        // On attend 2 secondes après la sauvegarde du document côté backend
        // pour que Collabora puisse être relancé sans erreur
        setTimeout(async () => {
          await lettreAccordStore.fetchLettreAccordProjectDocument();
          useEditLettreAccordDocumentStore().SetIsDocumentEditionBlocked(false);
        }, 2000);
      }
    );

    this.sockit.on(SockitEvent.ALLOW_DOCUMENT_EDITION, () => {
      // On attend 2 secondes après la sauvegarde du document côté backend
      // pour que Collabora puisse être relancé sans erreur
      setTimeout(() => {
        useEditLettreAccordDocumentStore().SetIsDocumentEditionBlocked(false);
      }, 2000);
    });

    this.sockit.on(
      SockitEvent.DOCUMENT_EDITION_BLOCKED,
      (payload: { idLettreAccord: string; role: Role; idUploader: string }) => {
        const userProfile = useUserProfileStore().getUserProfile;
        const lettreAccordRoomUserList =
          useEditLettreAccordDocumentStore().getEditingUserList;

        const shouldBlockEdition = shouldBlockLettreAccordEdition(
          payload,
          lettreAccordRoomUserList,
          userProfile
        );

        if (shouldBlockEdition) {
          useEditLettreAccordDocumentStore().SetIsDocumentEditionBlocked(true);
        }
      }
    );
  }

  public getMembersRoom(idLettreAccord: string): Promise<RoomMemberType[]> {
    return new Promise((resolve) => {
      this.sockit.emit(
        SockitEvent.GET_MEMBERS_DEMANDE_ROOM,
        idLettreAccord,
        (users: RoomMemberType[]) => {
          return resolve(users);
        }
      );
    });
  }

  public joinRoom(LettreAccordRoomOptions: LettreAccordUserRoom): void {
    this.sockit.emit(SockitEvent.JOIN_ROOM, LettreAccordRoomOptions);
  }

  public leaveRoom(idLettreAccord: string): void {
    this.sockit.emit(SockitEvent.LEAVE_ROOM, idLettreAccord);
  }

  public startEditingDocument(idLettreAccord: string, token: string): void {
    this.sockit.emit(SockitEvent.JOIN_DOCUMENT_EDIT_SESSION, {
      idRoom: idLettreAccord,
      token,
    });
  }

  public stopEditingDocument(idLettreAccord: string): void {
    this.sockit.emit(SockitEvent.LEAVE_DOCUMENT_EDIT_SESSION, {
      idRoom: idLettreAccord,
    });
  }

  public setDocumentModified(): void {
    this.sockit.emit(SockitEvent.MODIFIED_DOCUMENT_EDIT_SESSION);
  }

  public blockDocumentEdition(payload: {
    idLettreAccord: string;
    role: Role;
    idUploader: string;
  }): void {
    this.sockit.emit(SockitEvent.BLOCK_DOCUMENT_EDITION, payload);
  }
}
