import { sum } from "@/utils/numberUtils";

export const BYTES_IN_KILOBYTE = 1024;
export const BYTES_IN_MEGABYTE = 1024 * BYTES_IN_KILOBYTE;
export const LIMIT_UPLOAD_FILE_IN_MEGA = 200;

// A batch file upload should be less than 200Mo.
export function isFileBatchUploadable(documentList: { file: File }[]): boolean {
  const byteSizeList = documentList.map((document) => document.file.size);
  return sum(byteSizeList) < LIMIT_UPLOAD_FILE_IN_MEGA * BYTES_IN_MEGABYTE;
}

export function computeExceedFileBatchSizeError(
  documentList: { file: File }[]
): string {
  const simpleOrMultipleLabel =
    documentList.length > 1 ? "des documents" : "du document";
  return `Erreur lors de l'ajout ${simpleOrMultipleLabel} (taille maximale autorisée ${LIMIT_UPLOAD_FILE_IN_MEGA}Mo)`;
}
