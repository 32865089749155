import { OperationDocument } from "@domain/dto/operationDocument";
import { DemandeUserRoom } from "@domain/dto/demandeUserRoom";
import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import documentApi from "@/api/documentApi";
import {
  PreviewCollaboraType,
  PreviewDocumentParams,
  computeCollaboraGenericPreviewShareLink,
  computeCollaboraShareLink,
  getMySideEditorUserList,
  getPartnerSideEditorUserList,
} from "@/utils/collaboraUtils";
import { DocumentEditionStatus } from "@/domain/enum/documentEditionStatus";
import { User } from "@domain/dto/user";
import { useUserProfileStore } from "./userProfileModule.pinia";
import { TiersInvitingGroupRole } from "@/domain/tiersInvitingGroupRole";
import { EditionOperationDocument } from "@domain/dto/collabora/editionOperationDocument";
import { ApiResponse } from "@/apiRequest";

type DocumentEditStateType = {
  editedDocument: { link: string; document?: OperationDocument };

  userListInDemandeRoom: DemandeUserRoom[];
  documentEditionBlocked: boolean;

  token: string | undefined;
};

export const useDocumentEditStore = defineStore(ModuleType.EditDocument, {
  state: (): DocumentEditStateType => ({
    editedDocument: {
      link: "",
    },

    userListInDemandeRoom: [],
    documentEditionBlocked: false,

    token: undefined,
  }),
  getters: {
    getEditedDocument(state): { link: string; document?: OperationDocument } {
      return state.editedDocument;
    },

    getEditingUserList(state): DemandeUserRoom[] {
      return state.userListInDemandeRoom;
    },

    partnerSideDocumentEditorList(state): DemandeUserRoom[] {
      const profile = useUserProfileStore().getUserProfile;

      return getPartnerSideEditorUserList(state.userListInDemandeRoom, profile);
    },

    mySideDocumentEditorList(state): DemandeUserRoom[] {
      const profile = useUserProfileStore().getUserProfile;

      return getMySideEditorUserList(state.userListInDemandeRoom, profile);
    },

    isDocumentEditionBlocked(state): boolean {
      return state.documentEditionBlocked;
    },

    getEditionToken(state): string | undefined {
      return state.token;
    },
  },

  actions: {
    // Mutations
    SetEditedDocumentLink(link: string) {
      this.editedDocument = { ...this.editedDocument, link };
    },

    SetEditingUsers(userList: DemandeUserRoom[]) {
      this.userListInDemandeRoom = userList;
    },

    SetToken(token: string) {
      this.token = token;
    },

    AddEditingUser(user: DemandeUserRoom) {
      if (
        !this.userListInDemandeRoom.some(
          (demandeUser) => demandeUser.idUser === user.idUser
        )
      ) {
        this.userListInDemandeRoom.push(user);
      }
    },

    RemoveEditingUser(idUser: string) {
      this.userListInDemandeRoom = this.userListInDemandeRoom.filter(
        (userRoom) => userRoom.idUser !== idUser
      );
    },

    UpdateEditingUser(payload: { idUser: string; isEditing: boolean }) {
      const userToUpdate = this.userListInDemandeRoom.find(
        (userRoom) => userRoom.idUser === payload.idUser
      );
      if (userToUpdate) {
        userToUpdate.isEditing = payload.isEditing;
      }
    },

    SetEditedDocument(document: OperationDocument) {
      this.editedDocument = { ...this.editedDocument, document };
    },

    SetIsDocumentEditionBlocked(value: boolean): void {
      this.documentEditionBlocked = value;
    },

    // Actions
    async previewDocument(payload: PreviewDocumentParams): Promise<void> {
      let editLinkResponse:
        | ApiResponse<EditionOperationDocument<OperationDocument>>
        | undefined;
      switch (payload.type) {
        case PreviewCollaboraType.OPERATION_GED:
          if (payload.idOperation) {
            editLinkResponse = await documentApi.fetchOperationPreviewLink(
              payload.idOperation,
              payload.idDocument
            );
          }
          break;
        case PreviewCollaboraType.SOCIETE_SUPPORT_GED:
          if (payload.idSocieteSupport) {
            editLinkResponse = await documentApi.fetchSocieteSupportPreviewLink(
              payload.idSocieteSupport,
              payload.idDocument
            );
          }
          break;
        case PreviewCollaboraType.DEMANDE:
          if (payload.idOperation && payload.idDemande) {
            editLinkResponse =
              await documentApi.fetchDemandeWorkflowPreviewLink(
                payload.idOperation,
                payload.idDemande,
                payload.idDocument
              );
          }
          break;
        case PreviewCollaboraType.LETTRE_ACCORD:
          if (payload.idOperation && payload.idLettreAccord) {
            editLinkResponse =
              await documentApi.fetchLettreAccordWorkflowPreviewLink(
                payload.idOperation,
                payload.idLettreAccord,
                payload.idDocument
              );
          }
          break;
        case PreviewCollaboraType.SURETE:
          if (payload.idSocieteSupport && payload.idSurete) {
            editLinkResponse = await documentApi.fetchSureteWorkflowPreviewLink(
              payload.idSocieteSupport,
              payload.idSurete,
              payload.idDocument
            );
          }
          break;
        case PreviewCollaboraType.ACCORD_COMMERCIAL:
          if (payload.idOperation && payload.idAccordCommercial) {
            editLinkResponse =
              await documentApi.fetchAccordCommercialWorkflowPreviewLink(
                payload.idOperation,
                payload.idAccordCommercial,
                payload.idDocument
              );
          }
          break;
      }
      if (!!editLinkResponse) {
        const { link, token, document } = editLinkResponse.data;
        const buildLink = computeCollaboraGenericPreviewShareLink({
          ...payload,
          originalLink: link,
          token,
          idDocument: document.id,
        });

        this.SetToken(token);
        this.SetEditedDocument(document);
        this.SetEditedDocumentLink(buildLink);
      }
    },
    async editDemandeProjectDocument(payload: {
      idOperation: string;
      idDemande: string;
      user: User;
      invitingGroupRole?: TiersInvitingGroupRole;
    }): Promise<void> {
      const editLinkResponse = await documentApi.operationDocumentEditLink(
        payload.idOperation,
        payload.idDemande
      );

      const { link, token, document } = editLinkResponse.data;
      const buildLink = computeCollaboraShareLink(
        link,
        token,
        payload.idOperation,
        payload.idDemande,
        payload.user,
        {
          isBrouillon: false,
          invitingGroupRole: payload.invitingGroupRole,
        }
      );

      this.SetToken(token);
      this.SetEditedDocument(document);
      this.SetEditedDocumentLink(buildLink);
    },

    async editDemandeProjectBrouillonDocument(payload: {
      idOperation: string;
      idDemande: string;
      user: User;
      invitingGroupRole?: TiersInvitingGroupRole;
    }): Promise<void> {
      const editLinkResponse =
        await documentApi.operationBrouillonDocumentEditLink(
          payload.idOperation,
          payload.idDemande
        );
      const { link, token, document } = editLinkResponse.data;
      const buildLink = computeCollaboraShareLink(
        link,
        token,
        payload.idOperation,
        payload.idDemande,
        payload.user,
        { isBrouillon: true, invitingGroupRole: payload.invitingGroupRole }
      );
      this.SetToken(token);
      this.SetEditedDocument(document);
      this.SetEditedDocumentLink(buildLink);
    },

    async checkEditDemandeProjectBrouillonDocument(payload: {
      idOperation: string;
      idDemande: string;
    }): Promise<DocumentEditionStatus> {
      const documentEditionBrouillonStatus =
        await documentApi.operationDocumentCheckBrouillon(
          payload.idOperation,
          payload.idDemande
        );
      return documentEditionBrouillonStatus.data;
    },
    async fetchUserEditingDocument(payload: {
      idOperation: string;
      idDemande: string;
    }): Promise<DemandeUserRoom[]> {
      const { data } = await documentApi.fetchUserEditingDocument(
        payload.idOperation,
        payload.idDemande
      );
      this.SetEditingUsers(data);
      return data;
    },
  },
});
