import config from "@/config";
import { io, Socket } from "socket.io-client";
import { RoomMemberType, socketOptions, SockitEvent } from "./sockit";
import { Role } from "@domain/enum/role";
import { useUserProfileStore } from "@/store/userProfileModule.pinia";
import { getGroupRole } from "@/utils/groupUtils";
import { useSureteStore } from "@/store/sureteModule.pinia";
import { SureteUserRoom } from "@domain/dto/sureteUserRoom";
import { useEditSureteDocumentStore } from "@/store/editSureteDocumentModule.pinia";
import { shouldBlockSureteEdition } from "@/utils/sureteUtils";

export class SureteSockitNamespace {
  private static instance: SureteSockitNamespace;
  private sockit: Socket;

  private constructor(token: string) {
    this.sockit = io(`${config.ROOT_URL}/surete`, {
      ...socketOptions,
      auth: { token },
    });
    this.bindEvents();
  }

  public static getInstance(token: string): SureteSockitNamespace {
    if (!SureteSockitNamespace.instance) {
      SureteSockitNamespace.instance = new SureteSockitNamespace(token);
    }

    return SureteSockitNamespace.instance;
  }

  private bindEvents(): void {
    this.sockit.on("reconnect", () => {
      const surete = useSureteStore().getSurete;
      const userProfile = useUserProfileStore().getUserProfile;

      if (surete.id) {
        const sureteRoomOptions: SureteUserRoom = {
          idSocieteSupport: surete.idSocieteSupport,
          idSurete: surete.id,
          idUser: userProfile.id,
          isEditing: false,
          role: getGroupRole(userProfile.roles),
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
        };

        this.joinRoom(sureteRoomOptions);
      }
    });

    this.sockit.on(
      SockitEvent.USER_JOINED_ROOM,
      (payload: { userRoom: SureteUserRoom }) => {
        useEditSureteDocumentStore().AddEditingUser(payload.userRoom);
      }
    );

    this.sockit.on(
      SockitEvent.USER_LEFT_ROOM,
      (payload: { idUser: string }) => {
        useEditSureteDocumentStore().RemoveEditingUser(payload.idUser);
      }
    );
    this.sockit.on(
      SockitEvent.USER_JOINED_EDIT_SESSION,
      (payload: { idUser: string }) => {
        useEditSureteDocumentStore().UpdateEditingUser({
          idUser: payload.idUser,
          isEditing: true,
        });
      }
    );
    this.sockit.on(
      SockitEvent.USER_LEFT_EDIT_SESSION,
      (payload: { idUser: string }) => {
        useEditSureteDocumentStore().UpdateEditingUser({
          idUser: payload.idUser,
          isEditing: false,
        });
      }
    );

    this.sockit.on(
      SockitEvent.REFRESH_EDITED_DOCUMENT,
      async (_payload: { idSocieteSupport: string; idSurete: string }) => {
        const sureteStore = useSureteStore();

        // On attend 2 secondes après la sauvegarde du document côté backend
        // pour que Collabora puisse être relancé sans erreur
        setTimeout(async () => {
          await sureteStore.fetchSureteDocument();
          useEditSureteDocumentStore().SetIsDocumentEditionBlocked(false);
        }, 2000);
      }
    );

    this.sockit.on(SockitEvent.ALLOW_DOCUMENT_EDITION, () => {
      // On attend 2 secondes après la sauvegarde du document côté backend
      // pour que Collabora puisse être relancé sans erreur
      setTimeout(() => {
        useEditSureteDocumentStore().SetIsDocumentEditionBlocked(false);
      }, 2000);
    });

    this.sockit.on(
      SockitEvent.DOCUMENT_EDITION_BLOCKED,
      (payload: { idSurete: string; role: Role; idUploader: string }) => {
        const userProfile = useUserProfileStore().getUserProfile;
        const sureteRoomUserList =
          useEditSureteDocumentStore().getEditingUserList;

        const shouldBlockEdition = shouldBlockSureteEdition(
          payload,
          sureteRoomUserList,
          userProfile
        );

        if (shouldBlockEdition) {
          useEditSureteDocumentStore().SetIsDocumentEditionBlocked(true);
        }
      }
    );
  }

  public getMembersRoom(idSurete: string): Promise<RoomMemberType[]> {
    return new Promise((resolve) => {
      this.sockit.emit(
        SockitEvent.GET_MEMBERS_DEMANDE_ROOM,
        idSurete,
        (users: RoomMemberType[]) => {
          return resolve(users);
        }
      );
    });
  }

  public joinRoom(sureteRoomOptions: SureteUserRoom): void {
    this.sockit.emit(SockitEvent.JOIN_ROOM, sureteRoomOptions);
  }

  public leaveRoom(idSurete: string): void {
    this.sockit.emit(SockitEvent.LEAVE_ROOM, idSurete);
  }

  public startEditingDocument(idSurete: string, token: string): void {
    this.sockit.emit(SockitEvent.JOIN_DOCUMENT_EDIT_SESSION, {
      idRoom: idSurete,
      token,
    });
  }

  public stopEditingDocument(idSurete: string): void {
    this.sockit.emit(SockitEvent.LEAVE_DOCUMENT_EDIT_SESSION, {
      idRoom: idSurete,
    });
  }

  public setDocumentModified(): void {
    this.sockit.emit(SockitEvent.MODIFIED_DOCUMENT_EDIT_SESSION);
  }

  public blockDocumentEdition(payload: {
    idSurete: string;
    role: Role;
    idUploader: string;
  }): void {
    this.sockit.emit(SockitEvent.BLOCK_DOCUMENT_EDITION, payload);
  }
}
