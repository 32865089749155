import predictionApi from "@/api/predictionApi";
import { OperationDocumentCategoryPrediction } from "@/domain/operationDocumentCategoryPrediction";
import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";

export const usePredictionStore = defineStore(ModuleType.Prediction, {
  actions: {
    predictOperationDocumentCategory(
      filename: string
    ): Promise<OperationDocumentCategoryPrediction> {
      return predictionApi
        .predictOperationDocumentCategory(filename)
        .then((response) => response.data);
    },
  },
});
