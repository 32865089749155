import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";

type FeatureStoreState = {
  featureList: Record<string, (roleList: string[]) => boolean>;
};

export const useFeatureStore = defineStore(ModuleType.Feature, {
  state: (): FeatureStoreState => ({
    featureList: {},
  }),
  getters: {
    getFeatureList(state): FeatureStoreState["featureList"] {
      return state.featureList;
    },
  },
  actions: {
    AddFeature(payload: {
      name: string;
      enabled: (roleList: string[]) => boolean;
    }): void {
      this.featureList[payload.name] = payload.enabled;
    },
  },
});
