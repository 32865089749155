<template>
  <div class="banner-specific-container">
    <div class="icon-container">
      <img
        alt="signed surete icon"
        class="banner-icon"
        src="@/assets/event/DocSigned.svg"
      />
    </div>
    <div class="content mini">
      <span class="semi-bold"> {{ formatName() }} </span>
      à signé la sûreté
      <span class="semi-bold">
        {{ notification.payload.sureteName }}
      </span>
      sur la société support
      <span class="semi-bold">
        {{ notification.payload.societeSupportName }}
      </span>
      correspondant à la condition de mise en place
      <span class="semi-bold">
        {{ notification.payload.cmpName }}
      </span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Notification } from "@/domain/notification";

const props = defineProps<{
  notification: Notification;
}>();

const formatName = (): string => {
  return `${props.notification.payload.authorFirstName} ${props.notification.payload.authorLastName}`;
};
</script>
<style lang="scss" scoped>
.banner-specific-container {
  display: flex;
}
</style>
