import config from "@/config";
import { Socket, io } from "socket.io-client";
import { SockitEvent, socketOptions } from "./sockit";
import { useDemandeStore } from "@/store/demandeModule.pinia";
import { RecipientSignatureEvent } from "@domain/enum/recipientSignatureEvent";

import { useLettreAccordStore } from "@/store/lettreAccordModule.pinia";
import { EngagementGeneral } from "@domain/enum/engagementGeneral";
import { usePoolAccordCommercialStore } from "@/store/poolAccordCommercialModule.pinia";
import { useSureteStore } from "@/store/sureteModule.pinia";

export class SignatureSockitNamespace {
  private static instance: SignatureSockitNamespace;
  private sockit: Socket;

  private constructor(token: string) {
    this.sockit = io(`${config.ROOT_URL}/signature`, {
      ...socketOptions,
      auth: { token },
    });
    this.bindEvents();
  }

  public static getInstance(token: string): SignatureSockitNamespace {
    if (!SignatureSockitNamespace.instance) {
      SignatureSockitNamespace.instance = new SignatureSockitNamespace(token);
    }

    return SignatureSockitNamespace.instance;
  }

  private bindEvents(): void {
    this.sockit.on(
      SockitEvent.HANDLE_RECIPIENT_SIGNED,
      async (payload: RecipientSignatureEvent) => {
        if (payload.engagement === EngagementGeneral.DEMANDE) {
          await useDemandeStore().fetchDemande(payload);
          return;
        }

        if (payload.engagement === EngagementGeneral.LETTRE_ACCORD) {
          await useLettreAccordStore().fetchLettreAccord(payload);
          return;
        }

        if (payload.engagement === EngagementGeneral.POOL_ACCORD_COMMERCIAL) {
          await usePoolAccordCommercialStore().fetchAccordCommercial(
            payload.idOperation,
            payload.idAccordCommercial
          );
          return;
        }

        if (payload.engagement === EngagementGeneral.SURETE) {
          await useSureteStore().fetchSocieteSupportSurete(payload);
          return;
        }
      }
    );
  }

  public joinSignatureRoom(room: string): void {
    this.sockit.emit(SockitEvent.JOIN_ROOM, room);
  }

  public leaveSignatureRoom(room: string): void {
    this.sockit.emit(SockitEvent.LEAVE_ROOM, room);
  }
}
