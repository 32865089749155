import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { CommercialisationCreateRequest } from "@domain/dto/commercialisationCreateRequest";
import { CommercialisationImportConfiguration } from "@/domain/commercialisationImportConfiguration";
import { SuiviCommercialisationAllErrors } from "@domain/dto/suiviCommercialisationAllErrors";
import { SuiviCommercialisationPromoteur } from "@domain/dto/suiviCommercialisationPromoteur";
import { TrancheDemande } from "@domain/dto/trancheDemande";
import { TrancheDemandeCreationRequest } from "@domain/dto/trancheDemandeCreationRequest";
import { SuiviCommercialisationUpdateRequest } from "@domain/dto/suiviCommercialisationUpdateRequest";
import { UnboundTranchesGroupedByOperation } from "@domain/dto/unboundTranchesGroupedByOperation";
import { SuiviCommercialisationTableauHeavy } from "@domain/dto/suiviCommercialisationTableauHeavy";

export default {
  fetchAllForPromoteur(): Promise<
    ApiResponse<SuiviCommercialisationTableauHeavy>
  > {
    return apiRequest.get("/suivi-commercialisation");
  },

  fetchAllForBanque(
    idPromoteur: string
  ): Promise<ApiResponse<SuiviCommercialisationTableauHeavy>> {
    return apiRequest.get(`/suivi-commercialisation/promoteurs/${idPromoteur}`);
  },

  findTrancheErrorListForBanque(
    idPromoteur: string
  ): Promise<ApiResponse<UnboundTranchesGroupedByOperation[]>> {
    return apiRequest.get(
      `/suivi-commercialisation/promoteurs/${idPromoteur}/errors`
    );
  },

  findAllErrors(): Promise<ApiResponse<SuiviCommercialisationAllErrors>> {
    return apiRequest.get("/suivi-commercialisation/errors");
  },

  uploadCommercialisationJSONData(
    commercialisationData: CommercialisationCreateRequest[],
    file: File
  ): Promise<ApiResponse<SuiviCommercialisationTableauHeavy>> {
    const formData: FormData = new FormData();

    formData.append("metadata", JSON.stringify(commercialisationData));

    formData.append("file", file);

    return apiRequestThrottle.post(`/suivi-commercialisation`, formData);
  },

  saveImportConfiguration(
    suiviCommercialisationImportConfiguration: CommercialisationImportConfiguration
  ): Promise<ApiResponse<CommercialisationImportConfiguration>> {
    return apiRequestThrottle.post(
      `/suivi-commercialisation/configuration`,
      suiviCommercialisationImportConfiguration
    );
  },

  findImportConfiguration(): Promise<
    ApiResponse<CommercialisationImportConfiguration>
  > {
    return apiRequest.get(`/suivi-commercialisation/configuration`);
  },

  fetchSuiviCommercialisationPromoteurList(): Promise<
    ApiResponse<SuiviCommercialisationPromoteur[]>
  > {
    return apiRequest.get(`/suivi-commercialisation/promoteurList`);
  },

  createTrancheDemande(
    trancheDemandeCreationRequest: TrancheDemandeCreationRequest[]
  ): Promise<ApiResponse<TrancheDemande>> {
    return apiRequestThrottle.post(`/suivi-commercialisation/tranches`, {
      trancheDemandes: trancheDemandeCreationRequest,
    });
  },
  updateSuiviCommercialisation(
    suiviCommUpdateRequestList: SuiviCommercialisationUpdateRequest[]
  ): Promise<ApiResponse<void>> {
    return apiRequestThrottle.patch(
      `/suivi-commercialisation`,
      suiviCommUpdateRequestList
    );
  },

  downloadCommercialisationExcelForBanque(
    idPromoteur: string
  ): Promise<ApiResponse<BlobPart>> {
    return apiRequest.get(
      `/suivi-commercialisation/promoteurs/${idPromoteur}/export`
    );
  },
};
