import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import { RouteLocation } from "vue-router";

type RouteStoreState = {
  activeRoute: Partial<RouteLocation>;
};

export const useRouteStore = defineStore(ModuleType.Route, {
  state: (): RouteStoreState => ({
    activeRoute: {},
  }),
  getters: {
    getActiveRouteName(): string {
      return this.activeRoute.name ? this.activeRoute.name.toString() : "";
    },
  },
  actions: {
    SetActiveRoute(route: RouteLocation): void {
      this.activeRoute = route;
    },
  },
});
