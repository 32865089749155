import { apiRequest, ApiResponse } from "@/apiRequest";
import { FacturationRequest } from "@domain/dto/facturationRequest";
import { Facture } from "@/domain/facture";
import { FacturationSuiviConsommation } from "@domain/dto/facturationSuiviConsommation";

export default {
  async fetchFacturationExport(
    facturationRequest: FacturationRequest
  ): Promise<ApiResponse<BlobPart>> {
    return apiRequest.post(`/facturation/export`, facturationRequest);
  },

  async fetchFactureList(): Promise<ApiResponse<Facture[]>> {
    return apiRequest.get(`/facturation/facture`, {
      validateStatus(status: number) {
        return status === 200;
      },
    });
  },

  async fetchFacturationSuiviConsommation(
    year: number
  ): Promise<ApiResponse<FacturationSuiviConsommation[]>> {
    return apiRequest.get(`/facturation/suivis-consommation/annee/${year}`);
  },

  async fetchFacturationLotPrevisionnel(): Promise<ApiResponse<number[]>> {
    return apiRequest.get(`/facturation/suivis-consommation/lots-previsionnel`);
  },

  async downloadFacture(idFacture: string): Promise<ApiResponse<BlobPart>> {
    return apiRequest.get(`/facturation/facture/${idFacture}/download`, {
      responseType: "blob",
    });
  },
};
