<template>
  <EditDocumentModal
    :modal-message="modalMessage"
    :editing-document-link="editingDocumentLink"
    @brouillon-document-interval="onBrouillonInterval"
    @started-editing-document="onStartedEditingDocument"
    @edit-document-modified="onDocumentModified"
    @close-edit-document-modal="onCloseEditDocumentModal"
  />
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import EditDocumentModal from "../shared/EditDocumentModal.vue";
import { getSetupContext } from "@/utils/vueUtils";
import { RoomMemberType } from "@/api/sockit/sockit";
import { getEditionLink } from "@/utils/collaboraUtils";
import { User } from "@domain/dto/user";
import { CollaboraApplication } from "@/utils/collaboraApplication";
import { Role } from "@domain/enum/role";
import { toastSuccess } from "@/utils/toastUtils";
import { getGroupRoleFromUser } from "@/utils/groupUtils";
import { useSockit } from "@/composables/useSockit";
import { SureteHeavy } from "@domain/dto/sureteHeavy";
import { SureteUserRoom } from "@domain/dto/sureteUserRoom";

export default defineComponent({
  name: "EditSureteDocumentModal",
  components: { EditDocumentModal },
  setup() {
    const {
      userProfileStore,
      isBanque,
      isPromoteur,
      editSureteDocumentStore,
      sureteStore,
    } = getSetupContext();

    const { sockitInstance } = useSockit();

    const editingDocumentLink = computed<string>(() => {
      return getEditionLink(editSureteDocumentStore.getEditedDocument);
    });

    const surete = computed<SureteHeavy>(() => {
      return sureteStore.getSurete;
    });

    const profile = computed<User>(() => {
      return userProfileStore.getUserProfile;
    });

    const tokenCollabora = computed((): string => {
      return editSureteDocumentStore.getEditionToken as string;
    });

    const partnerSideDocumentEditorList = computed<SureteUserRoom[]>(
      () => editSureteDocumentStore.getPartnerSideDocumentEditorList
    );

    const mySideDocumentEditorList = computed<SureteUserRoom[]>(() => {
      return editSureteDocumentStore.getMySideDocumentEditorList;
    });

    const modalMessage = computed<string>(() => {
      return partnerSideDocumentEditorList.value.length > 0
        ? "L'autre partie travaille aussi sur ce document. Voulez-vous tout de même publier ? Vous n'écraserez pas leurs modifications."
        : "Voulez-vous publier cette version ?";
    });

    async function onBrouillonInterval(
      collabora: CollaboraApplication | null
    ): Promise<void> {
      const roomMemberList = await sockitInstance.value?.surete.getMembersRoom(
        surete.value.id
      );

      if (!roomMemberList) {
        return Promise.resolve();
      }

      let groupRoomMemberList: RoomMemberType[] = [];

      if (isPromoteur.value) {
        groupRoomMemberList = roomMemberList.filter(
          (member) => member.userRole === Role.PROMOTEUR
        );
      } else if (isBanque.value) {
        groupRoomMemberList = roomMemberList.filter(
          (member) => member.userRole === Role.BANQUE
        );
      }

      const roomMemberListSortByLongestEditor = groupRoomMemberList.toSorted(
        (member1, member2) => member1.startEditingAt - member2.startEditingAt
      );

      const isLongestEditor = roomMemberListSortByLongestEditor.length
        ? roomMemberListSortByLongestEditor[0].idUser === profile.value.id
        : false;

      if (isLongestEditor) {
        collabora?.saveBrouillon();
      }
    }

    function onStartedEditingDocument(): void {
      sockitInstance.value?.surete.startEditingDocument(
        surete.value.id,
        tokenCollabora.value
      );
    }

    function onDocumentModified(): void {
      sockitInstance.value?.surete.setDocumentModified();
    }

    function onCloseEditDocumentModal(
      collabora: CollaboraApplication | null
    ): void {
      const isLastUserInRoom = mySideDocumentEditorList.value.length === 1;
      if (collabora?.isModified && isLastUserInRoom) {
        sockitInstance.value?.surete.blockDocumentEdition({
          idSurete: surete.value.id,
          role: getGroupRoleFromUser(profile.value),
          idUploader: profile.value.id,
        });
        toastSuccess("Vos modifications ont bien été enregistrées.");
      }

      collabora?.closeModal();
      editSureteDocumentStore.SetEditedDocumentLink("");
      sockitInstance.value?.surete.stopEditingDocument(surete.value.id);
    }

    return {
      // computed
      editingDocumentLink,
      modalMessage,
      // methods
      onBrouillonInterval,
      onStartedEditingDocument,
      onDocumentModified,
      onCloseEditDocumentModal,
    };
  },
});
</script>
