import config from "@/config";
import { Socket, io } from "socket.io-client";
import { socketOptions, SockitEvent } from "./sockit";
import { useOperationChatStore } from "@/store/operationChatModule.pinia";
import { toastError, toastSuccess, toastWarn } from "@/utils/toastUtils";
import {
  getGroupRoleFromUser,
  getMasterGroupIdFromOperation,
} from "@/utils/groupUtils";
import { useOperationStore } from "@/store/operationModule.pinia";
import {
  getGroupIdFromUser,
  getUserGroupFromOperation,
} from "@/utils/userUtils";
import { OperationMessageCreationRequest } from "@domain/dto/operationMessageCreationRequest";
import useKeycloakStore from "@/store/keycloakModule.pinia";
import { usePoolStore } from "@/store/poolModule.pinia";
import { useUserProfileStore } from "@/store/userProfileModule.pinia";
import { OperationChatRoomOptions } from "@/domain/dto/operationChatRoomOptions";
import { OperationAccessAsRole } from "@/domain/enum/operationAccessAsRole";

export class ChatSockitNamespace {
  private static instance: ChatSockitNamespace;
  private sockit: Socket;

  private constructor(token: string) {
    this.sockit = io(`${config.ROOT_URL}/chat`, {
      ...socketOptions,
      auth: { token },
    });
    this.bindEvents(token);
  }

  public static getInstance(token: string): ChatSockitNamespace {
    if (!ChatSockitNamespace.instance) {
      ChatSockitNamespace.instance = new ChatSockitNamespace(token);
    }

    return ChatSockitNamespace.instance;
  }

  private bindEvents(token: string): void {
    const userProfile = useUserProfileStore().getUserProfile;

    this.sockit.io.on("reconnect_attempt", (attempt) => {
      if (attempt === 1) {
        toastWarn(
          `Erreur lors de la connexion au serveur de messagerie, tentative de reconnection en cours`
        );
      } else if (attempt === 10) {
        toastWarn(`Incapable de se reconnecter au serveur de messagerie`);
      }
    });

    this.sockit.on("disconnect", () => {
      toastWarn(`Déconnecté du serveur de messagerie`);
    });

    this.sockit.io.on("reconnect", () => {
      toastSuccess("Connexion rétablie au serveur de messagerie");

      const groupRole = getGroupRoleFromUser(userProfile);
      const operation = useOperationStore().getOperation;

      if (operation.id) {
        const masterGroupId =
          getMasterGroupIdFromOperation(userProfile, operation) ??
          getGroupIdFromUser(userProfile);

        const roomOptions: OperationChatRoomOptions = {
          operation: {
            id: operation.id,
            idPromoteurOwner: operation.idPromoteurOwner,
            demandeList: operation.demandeList,
          },
          groupRole: groupRole,
          groupId:
            getUserGroupFromOperation(operation, userProfile)?.id ??
            getGroupIdFromUser(userProfile),
          idGroupMasterInternalChannel: masterGroupId,
          operationAccess: operation.operationAccessAsRole,
        };

        if (
          useKeycloakStore().isBanque &&
          operation.operationAccessAsRole === OperationAccessAsRole.BANQUE
        ) {
          roomOptions.idChefDeFile = masterGroupId;

          const poolGroupList = usePoolStore().getPoolOperation.participantList;
          roomOptions.idPoolGroupList = poolGroupList.map(
            (participant) => participant.participant.id
          );
        } else if (useUserProfileStore().isProfilePool) {
          roomOptions.idChefDeFile = operation.idBanqueList[0];
          roomOptions.idPoolGroupList = [masterGroupId];
        }

        this.sockit.emit(SockitEvent.JOIN_OPERATION_CHAT, roomOptions, token);
      }
    });

    this.sockit.on(SockitEvent.MESSAGE_RESPONSE, (message) => {
      useOperationChatStore().UpdateOperationMessageList(message);
    });

    this.sockit.on(
      SockitEvent.SEND_NOTIFICATION_MESSAGE,
      (messageNotification) => {
        useOperationChatStore().UpdateOperationMessageList(messageNotification);
      }
    );

    this.sockit.on(
      SockitEvent.ERROR,
      (message: OperationMessageCreationRequest) => {
        if (message.message.idAuthor === userProfile.id) {
          toastError("Erreur lors de l'envoi du message");
        }
      }
    );
  }

  public sendMessage(
    messageCreationRequest: OperationMessageCreationRequest
  ): boolean {
    if (this.sockit.connected) {
      this.sockit.emit(SockitEvent.SEND_MESSAGE, messageCreationRequest);
      return true;
    }
    return false;
  }

  public joinOperation(
    roomOptions: OperationChatRoomOptions,
    token: string | undefined
  ): void {
    this.sockit.emit(SockitEvent.JOIN_OPERATION_CHAT, roomOptions, token);
  }

  public leaveOperation(roomOptions: OperationChatRoomOptions): void {
    this.sockit.emit(SockitEvent.LEAVE_OPERATION_CHAT, roomOptions);
  }

  public joinRoom(room: string): void {
    this.sockit.emit(SockitEvent.JOIN_ROOM, room);
  }
}
