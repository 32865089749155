import { ToastType } from "@/domain/enum/toastType";
import { useToastStore } from "@/store/toastModule.pinia";

export const TOAST_BASE_TIME_TO_LIVE_MS = 5000;
export const TOAST_MULTIPLE_DOCUMENT_UPLOAD_INDEX = -8000;

export function getLabelFromToastType(toastType: ToastType): string {
  switch (toastType) {
    case ToastType.SUCCESS:
      return "Félicitations !";

    case ToastType.INFO:
      return "Information";

    case ToastType.WARN:
      return "Attention";

    case ToastType.ERROR:
      return "Erreur";

    default:
      return "Inconnu";
  }
}

export function toastSuccess(
  message: string,
  time = TOAST_BASE_TIME_TO_LIVE_MS
): void {
  toast(message, ToastType.SUCCESS, time);
}

export function toastError(
  message: string,
  time = TOAST_BASE_TIME_TO_LIVE_MS
): void {
  toast(message, ToastType.ERROR, time);
}

export function toastWarn(
  message: string,
  time = TOAST_BASE_TIME_TO_LIVE_MS
): void {
  toast(message, ToastType.WARN, time);
}

const toast = (message: string, type: ToastType, time: number): void => {
  useToastStore().push(type, message, time);
};
