import { OperationDocument } from "@domain/dto/operationDocument";
import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import documentApi from "@/api/documentApi";
import { computeCollaboraESGShareLink } from "@/utils/collaboraUtils";
import { DocumentEditionStatus } from "@/domain/enum/documentEditionStatus";
import { User } from "@domain/dto/user";

type DocumentESGEditStateType = {
  editedDocument: { link: string; document?: OperationDocument };
  hasBrouillon: boolean;
  token: string | undefined;
};

export const useDocumentESGEditStore = defineStore(ModuleType.EditESGDocument, {
  state: (): DocumentESGEditStateType => ({
    editedDocument: {
      link: "",
    },
    hasBrouillon: false,
    token: undefined,
  }),
  getters: {
    getEditedDocument(state): { link: string; document?: OperationDocument } {
      return state.editedDocument;
    },

    getEditionToken(state): string | undefined {
      return state.token;
    },
    getHasBrouillon(state): boolean {
      return state.hasBrouillon;
    },
  },

  actions: {
    // Mutations
    SetEditedDocumentLink(link: string) {
      this.editedDocument = { ...this.editedDocument, link };
    },

    SetToken(token: string) {
      this.token = token;
    },

    SetEditedDocument(document: OperationDocument) {
      this.editedDocument = { ...this.editedDocument, document };
    },
    ResetESGEdition(): void {
      this.hasBrouillon = false;
      this.token = "";
      this.editedDocument = {
        link: "",
      };
    },

    // Actions
    async editESGDocument(payload: {
      idOperation: string;
      idFormDocument: string;
      user: User;
    }): Promise<void> {
      const editLinkResponse = await documentApi.fetchESGEditLink(
        payload.idOperation,
        payload.idFormDocument
      );
      const { link, token, document } = editLinkResponse.data;
      const buildLink = computeCollaboraESGShareLink(
        link,
        token,
        payload.idOperation,
        payload.idFormDocument,
        payload.user
      );

      this.SetToken(token);
      this.SetEditedDocument(document);
      this.SetEditedDocumentLink(buildLink);
    },

    async editESGBrouillonDocument(payload: {
      idOperation: string;
      idFormDocument: string;
      user: User;
    }): Promise<void> {
      const editLinkResponse = await documentApi.fetchESGBrouillonEditLink(
        payload.idOperation,
        payload.idFormDocument
      );
      const { link, token, document } = editLinkResponse.data;
      const buildLink = computeCollaboraESGShareLink(
        link,
        token,
        payload.idOperation,
        payload.idFormDocument,
        payload.user,
        { isBrouillon: true }
      );
      this.SetToken(token);
      this.SetEditedDocument(document);
      this.SetEditedDocumentLink(buildLink);
    },

    async checkEditOperationESGBrouillonDocument(payload: {
      idOperation: string;
      idFormDocument: string;
    }): Promise<DocumentEditionStatus> {
      const documentEditionBrouillonStatus =
        await documentApi.operationESGDocumentCheckBrouillon(
          payload.idOperation,
          payload.idFormDocument
        );
      this.hasBrouillon =
        documentEditionBrouillonStatus.data === DocumentEditionStatus.DRAFT;
      return documentEditionBrouillonStatus.data;
    },
  },
});
