<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i_18011_53702)">
      <circle cx="16" cy="16" r="16" fill="white" />
    </g>
    <circle cx="16" cy="16" r="15.35" stroke="white" stroke-width="1.3" />
    <g style="mix-blend-mode: multiply">
      <circle cx="16" cy="16" r="10" fill="#2FA743" />
    </g>
    <path
      d="M13.8648 18.4843L11.5294 16.2576C11.406 16.1386 11.2378 16.0716 11.0623 16.0716C10.8868 16.0716 10.7185 16.1386 10.5952 16.2576C10.335 16.5058 10.335 16.9002 10.5952 17.1483L13.391 19.8139C13.6513 20.062 14.0716 20.062 14.3319 19.8139L21.4049 13.0767C21.6651 12.8286 21.6651 12.4342 21.4049 12.1861C21.2815 12.067 21.1133 12 20.9378 12C20.7622 12 20.594 12.067 20.4707 12.1861L13.8648 18.4843Z"
      fill="#F5FAF5"
    />
    <defs>
      <filter
        id="filter0_i_18011_53702"
        x="0"
        y="0"
        width="32"
        height="37.2"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.2" />
        <feGaussianBlur stdDeviation="2.6" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.827451 0 0 0 0 0.850745 0 0 0 0 0.933333 0 0 0 0.72 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_18011_53702"
        />
      </filter>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>
