import { ProfileUser } from "@/domain/dto/profileUser";
import userApi from "@/api/userApi";
import uniqBy from "lodash/uniqBy";
import { ModuleType } from "./moduleType.pinia";
import { defineStore } from "pinia";

type ReferentStoreState = {
  userProfileImageList: ProfileUser[];
};

export const useReferentStore = defineStore(ModuleType.Referent, {
  state: (): ReferentStoreState => ({
    userProfileImageList: [],
  }),
  getters: {
    getUserProfileImageList(state): ProfileUser[] {
      return state.userProfileImageList;
    },
    findUserProfile(state): Function {
      return (idUser: string) => {
        return state.userProfileImageList.find((item) => {
          return item.idUser === idUser;
        });
      };
    },
    findUserProfileList(state): Function {
      return (idUserList: string[]) => {
        return state.userProfileImageList.filter((item) => {
          return idUserList.includes(item.idUser);
        });
      };
    },
  },
  actions: {
    // Mutations
    AddUserProfileImageList(userProfileImageList: ProfileUser[]): void {
      this.userProfileImageList = uniqBy(
        [...userProfileImageList, ...this.userProfileImageList],
        "idUser"
      );
    },
    // Actions
    async fetchProfileImagesByUserIdList(
      userIdList: string[]
    ): Promise<ProfileUser[]> {
      const userIdListToBeFetched = userIdList.filter(
        (userId) =>
          !this.userProfileImageList
            .map((profileUser) => profileUser.idUser)
            .includes(userId)
      );

      if (userIdListToBeFetched.length) {
        const profileImageList = await userApi
          .fetchProfileImages(userIdListToBeFetched)
          .then((result) => result.data);
        this.AddUserProfileImageList(profileImageList);
        return profileImageList;
      }
      this.AddUserProfileImageList([]);
      return [];
    },
  },
});
