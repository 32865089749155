import { defineStore } from "pinia";
import { OperationMessageHeavy } from "@/domain/operationMessageHeavy";
import { OperationChatFilter } from "@/domain/operationChatFilter";
import { TaggableUser } from "@domain/dto/taggableUser";
import { ModuleType } from "@/store/moduleType.pinia";
import { OperationMessageCanal } from "@domain/enum/operationMessageCanal";
import {
  filterAllPoolParticipantMessage,
  filterOperationChatMembers,
  filterOperationChatMessages,
  filterOperationDemandeList,
  filterOperationDocumentList,
  getLastCreatedAtByPoolGroup,
} from "@/utils/operationChatUtils";
import { useOperationStore } from "@/store/operationModule.pinia";
import operationChatApi from "@/api/operationChatApi";
import { useUserProfileStore } from "@/store/userProfileModule.pinia";
import { OperationRoom } from "@domain/dto/operationRoom";
import { TaggableDemande } from "@domain/dto/taggableDemande";
import { TaggableDocument } from "@domain/dto/taggableDocument";

type OperationChatType = {
  operationMessages: OperationMessageHeavy[];
  filter: OperationChatFilter;
  operationRoomList: OperationRoom[];
  isChatMaximized: boolean;
};
export const useOperationChatStore = defineStore(ModuleType.OperationChat, {
  state: (): OperationChatType => ({
    operationMessages: [],
    filter: { canal: OperationMessageCanal.INTERNE },
    operationRoomList: [],
    isChatMaximized: false,
  }),
  getters: {
    getDisplayedOperationMessages(state): OperationMessageHeavy[] {
      return filterOperationChatMessages(
        state.operationMessages,
        state.filter,
        useUserProfileStore().getUserProfile
      );
    },
    getLastDisplayedOperationMessagesByIdPoolGroup(
      state
    ): Map<string, OperationMessageHeavy> {
      const poolMessageList = state.operationMessages.filter(
        (message) => message.canal === OperationMessageCanal.POOL
      );

      return getLastCreatedAtByPoolGroup(poolMessageList);
    },
    getAllPoolParticipantMessages(state): OperationMessageHeavy[] {
      return filterAllPoolParticipantMessage(
        state.operationMessages,
        useUserProfileStore().getUserProfile
      );
    },

    getChatMemberList(state): TaggableUser[] {
      const user = useUserProfileStore().getUserProfile;

      return filterOperationChatMembers(
        state.operationRoomList,
        state.filter,
        user
      );
    },
    getChatDemandeList(state): TaggableDemande[] {
      const user = useUserProfileStore().getUserProfile;

      return filterOperationDemandeList(
        state.operationRoomList,
        state.filter,
        user
      );
    },
    getChatDocumentList(state): TaggableDocument[] {
      const user = useUserProfileStore().getUserProfile;

      return filterOperationDocumentList(
        state.operationRoomList,
        state.filter,
        user
      );
    },
    getChatMemberListWithCurrentUserUnfiltred(state): TaggableUser[] {
      const user = useUserProfileStore().getUserProfile;

      return filterOperationChatMembers(
        state.operationRoomList,
        state.filter,
        user,
        true
      );
    },
    getOperationChatFilter(state): OperationChatFilter {
      return state.filter;
    },
    getOperationRoomList(state): OperationRoom[] {
      return state.operationRoomList;
    },

    getIsChatMaximized(state): boolean {
      return state.isChatMaximized;
    },
  },
  actions: {
    SetOperationMessages(operationMessages: OperationMessageHeavy[]): void {
      this.operationMessages = operationMessages;
    },

    SetChatOperationFilter(filter: OperationChatFilter): void {
      this.filter = filter;
    },

    SetOperationRoomList(roomList: OperationRoom[]): void {
      this.operationRoomList = roomList;
    },

    UpdateOperationMessageList(operationMessage: OperationMessageHeavy): void {
      this.operationMessages.push(operationMessage);
    },

    SetToggleChatMaximized(isChatMaximized: boolean): void {
      this.isChatMaximized = isChatMaximized;
    },

    async fetchOperationMessageList(): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      const { data } =
        await operationChatApi.fetchMessageListByOperation(idOperation);
      this.SetOperationMessages(data);
    },

    async fetchOperationRoomList(): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;

      const { data } =
        await operationChatApi.fetchOperationMessageRoomList(idOperation);
      this.SetOperationRoomList(data);
    },
  },
});
