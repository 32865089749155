import { apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { UserCreationStatus } from "@domain/dto/userCreationStatus";
import { HommeDeArtCreationRequest } from "@domain/dto/hommeDeArtCreationRequest";
import { Demande } from "@/domain/demande";

export default {
  addHommeDeArt(
    hommeDeArtCreationRequest: HommeDeArtCreationRequest,
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<UserCreationStatus>> {
    return apiRequestThrottle.post(
      `/operations/${idOperation}/demandes/${idDemande}/homme-de-art`,
      hommeDeArtCreationRequest
    );
  },
  validateMainlevee(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<void>> {
    return apiRequestThrottle.put(
      `/operations/${idOperation}/demandes/${idDemande}/validate-main-levee`
    );
  },

  updateDemandeComplementaire(
    idOperation: string,
    idDemande: string,
    demandeComplementaire: string
  ): Promise<ApiResponse<Demande>> {
    return apiRequestThrottle.put(
      `/operations/${idOperation}/demandes/${idDemande}/refuse-main-levee`,
      {
        demandeComplementaire,
      }
    );
  },
};
