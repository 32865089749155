<template>
  <Modal @close-modal="confirm">
    <template #header>
      <span>Êtes-vous encore là?</span>
    </template>
    <template #body>
      <div class="instruction" data-test="confirm-modal-message">
        <span>Sans action de votre la part, la session sera fermée</span>
      </div>
    </template>
    <template #footer>
      <button class="primary" data-test="confirm-button" @click.stop="confirm">
        Prolonger la session
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import Modal from "@/components/shared/ModalComponent.vue";
import { NeomiEvent } from "@/domain/neomiEvent";

import { defineComponent } from "vue";

export default defineComponent({
  components: {
    Modal,
  },
  setup(_props, context) {
    function confirm(): void {
      context.emit(NeomiEvent.VALIDATED);
    }

    return {
      // Methods
      confirm,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";
@import "@/sass/spinner.scss";
</style>
