export enum NemmoMimeType {
  PDF = "application/pdf",
  ZIP = "application/zip",
  EXCEL = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  DOC = "application/msword",
  ODT = "application/vnd.oasis.opendocument.text",
  PAGES = "application/x-iwork-pages-sffpages",
  XLS = "application/excel",
  CSV = "text/csv",
  PPT = "application/mspowerpoint",
  PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  SVG = "image/svg+xml",
  PNG = "image/png",
  GIF = "image/gif",
  JPG = "image/jpeg",
  XML = "application/xml",
  TXT = "text/plain",
}
