import { EventEmitter } from "events";
import config from "@/config";
import { debounce, DebouncedFunc } from "lodash";
import { NeomiEvent } from "@/domain/neomiEvent";
import { toastError, toastSuccess } from "@/utils/toastUtils";
import { downloadFromURL } from "./downloadFileUtils";

enum EventCollabora {
  ON_START = "App_LoadingStatus",
  ON_SAVE_BUTTON = "UI_Save",
  ON_DOCUMENT_SAVED = "Action_Save_Resp",
  ON_DOWNLOAD_AS = "Download_As",
  CLOSE = "Close",
  MODIFIED_STATUS = "Doc_ModifiedStatus",
}

/**
 * Commandes LibreOffice sous-jacentes utilisées par Collabora
 * @see https://wiki.documentfoundation.org/Development/DispatchCommands
 * */
enum LibreOfficeUnoCommand {
  TRACK_CHANGES = ".uno:TrackChanges",
}

enum ActionTypeCollabora {
  START_COLLABORA_IFRAME = "Host_PostmessageReady",
  DISABLE_DEFAULT_SAVE_ACTION = "Disable_Default_UIAction",
  SAVE = "Action_Save",
  CLOSE = "Action_Close",
  SEND_UNO_COMMAND = "Send_UNO_Command",
}

type ActionCollabora = {
  [key: string]: string | boolean | object;
};

type MessageCollabora = {
  MessageId: string;
  SendTime: number;
  Values: ActionCollabora;
};

export class CollaboraApplication extends EventEmitter {
  public isModified: boolean;
  private readonly iframe: HTMLFormElement;
  private readonly boundHandleMessage: (this: Window, ev: MessageEvent) => any;
  private debouncedSave: DebouncedFunc<() => Promise<any>> = debounce(() => {
    this.emit(NeomiEvent.SAVE_EDITING_DOCUMENT);
  }, 200);

  constructor(iframe: HTMLFormElement) {
    super();
    this.iframe = iframe;
    this.isModified = false;
    this.boundHandleMessage = this.handleMessage.bind(this);

    this.removeWelcomeScreen();
    window.addEventListener("message", this.boundHandleMessage, false);
  }

  removeWelcomeScreen(): void {
    if (config.COLLABORA_VERSION !== "") {
      localStorage.setItem("WSDWelcomeVersion", config.COLLABORA_VERSION);
    }
  }

  close(): void {
    window.removeEventListener("message", this.boundHandleMessage, false);
  }

  closeModal(): void {
    this.sendMessage(ActionTypeCollabora.CLOSE, {
      Notify: false,
    });
  }

  persistDocument(): void {
    this.sendMessage(ActionTypeCollabora.SAVE, { Notify: true });
  }

  saveBrouillon(): void {
    this.sendMessage(ActionTypeCollabora.SAVE, {
      Notify: false,
      ExtendedData: JSON.stringify(this.prepareBrouillonHeaderData()),
    });
  }

  private prepareBrouillonHeaderData(): { [key: string]: any } {
    return {
      type: "brouillon",
    };
  }

  private handleMessage(event: MessageEvent): void {
    try {
      const data = JSON.parse(event.data);

      if (!data.MessageId) return;

      if (data.MessageId === EventCollabora.ON_START) {
        this.configureCollabora();
      } else if (data.MessageId === EventCollabora.ON_SAVE_BUTTON) {
        this.onSaveButton();
      } else if (data.MessageId === EventCollabora.ON_DOCUMENT_SAVED) {
        this.onDocumentSaved(data);
      } else if (data.MessageId === EventCollabora.ON_DOWNLOAD_AS) {
        this.onDownloadAs(data);
      } else if (data.MessageId === EventCollabora.CLOSE) {
        this.closeModal();
      } else if (data.MessageId === EventCollabora.MODIFIED_STATUS) {
        this.onModification(data);
      }
    } catch (err) {
      return;
    }
  }

  private configureCollabora(): void {
    this.sendMessage(ActionTypeCollabora.START_COLLABORA_IFRAME);
    this.sendMessage(ActionTypeCollabora.DISABLE_DEFAULT_SAVE_ACTION, {
      action: EventCollabora.ON_SAVE_BUTTON,
      disable: true,
    });
    this.sendMessage(ActionTypeCollabora.SEND_UNO_COMMAND, {
      Command: LibreOfficeUnoCommand.TRACK_CHANGES,
      Args: {
        TrackChanges: {
          type: "boolean",
          value: true,
        },
      },
    });
  }

  private sendMessage(type: string, values: ActionCollabora = {}): void {
    const iframe = this.iframe;
    if (!iframe) return close();

    const messageContent: MessageCollabora = {
      MessageId: type,
      SendTime: Date.now(),
      Values: values,
    };
    iframe.contentWindow.postMessage(
      JSON.stringify(messageContent),
      config.ROOT_URL
    );
  }

  private onSaveButton(): void {
    this.debouncedSave.cancel();
    this.debouncedSave();
  }

  private onDocumentSaved(message: { Values: { success: boolean } }): void {
    if (message.Values.success) {
      toastSuccess("Document publié avec succès");
      this.emit(NeomiEvent.SAVED_EDITING_DOCUMENT);
    } else {
      toastError("Erreur lors de la publication du document");
    }
  }

  private onModification(message: { Values: { Modified: boolean } }): void {
    this.isModified = message.Values.Modified;
    if (message.Values.Modified) {
      this.emit(NeomiEvent.EDIT_DOCUMENT_MODIFIED);
    }
  }

  private onDownloadAs(message: { Values: { URL: string } }): void {
    downloadFromURL(message.Values.URL);
  }
}
