import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import { v4 as uuid } from "uuid";
import type { Loader, LoaderMetadata } from "@/domain/loader";

type LoaderStoreType = {
  loaderList: Loader[];
};

export const useLoaderStore = defineStore(ModuleType.Loader, {
  state: (): LoaderStoreType => ({
    loaderList: [],
  }),
  actions: {
    findLoader(metadata: LoaderMetadata): Loader | undefined {
      return this.loaderList.find((loader) => {
        return Object.entries(loader.metadata).every(([key, value]) => {
          if (key === "idDocument") return true;
          return metadata[key] === value;
        });
      });
    },
    findLoaderByIdDocument(idDocument: string): Loader | undefined {
      return this.loaderList.find((loader) => {
        if (loader.metadata?.idDocument) {
          return loader.metadata.idDocument === idDocument;
        }
      });
    },
    addLoader(metadata?: LoaderMetadata): Loader {
      const loader: Loader = {
        id: uuid(),
        isActive: false,
        metadata: metadata || {},
      };
      this.loaderList.push(loader);
      return loader;
    },
    addMetadata(idLoader: string, metadata: LoaderMetadata): void {
      const loader = this.loaderList.find((loader) => loader.id === idLoader);
      if (loader) {
        loader.metadata = { ...loader.metadata, ...metadata };
      }
    },
    runLoader(idLoader: string): void {
      const loader = this.loaderList.find((loader) => loader.id === idLoader);
      if (loader) {
        loader.isActive = true;
      }
    },
    stopLoader(idLoader: string): void {
      const loader = this.loaderList.find((loader) => loader.id === idLoader);
      if (loader) {
        loader.isActive = false;
      }
    },
    removeLoader(idLoader: string): void {
      this.loaderList = this.loaderList.filter(
        (loader) => loader.id !== idLoader
      );
    },
    removeLoaderByMetadata(metadata: LoaderMetadata): void {
      const loader = this.findLoader(metadata);
      if (loader) {
        this.removeLoader(loader.id);
      }
    },
    removeLoaderByIdDocument(idDocument: string): void {
      const loader = this.findLoaderByIdDocument(idDocument);
      if (loader) {
        this.removeLoader(loader.id);
      }
    },
    isActive(metadata: LoaderMetadata): boolean {
      const loader = this.findLoader(metadata);
      return loader ? loader.isActive : false;
    },
  },
});
