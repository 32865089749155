import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { OperationDocumentCheck } from "@/domain/operationDocumentCheck";
import { OperationDocumentCheckUpdateRequest } from "@/domain/dto/demandeDocumentCheckUpdateRequest";

export default {
  fetchAll(
    idOperation: string
  ): Promise<ApiResponse<OperationDocumentCheck[]>> {
    return apiRequest.get(`/operations/${idOperation}/document-checks`);
  },

  update(
    idOperation: string,
    demandeDocumentCheckUpdateRequest: OperationDocumentCheckUpdateRequest
  ): Promise<ApiResponse<OperationDocumentCheck>> {
    return apiRequestThrottle.patch(
      `/operations/${idOperation}/document-checks`,
      demandeDocumentCheckUpdateRequest
    );
  },
};
