<template>
  <svg
    :width="props.height"
    :height="props.height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.33333 12C3.33333 12.3667 3.63333 12.6667 4 12.6667H12C12.3667 12.6667 12.6667 12.3667 12.6667 12V8.66667C12.6667 8.3 12.9667 8 13.3333 8C13.7 8 14 8.3 14 8.66667V12.6667C14 13.4 13.4 14 12.6667 14H3.33333C2.6 14 2 13.4 2 12.6667V3.33333C2 2.6 2.59333 2 3.33333 2H7.33333C7.7 2 8 2.3 8 2.66667C8 3.03333 7.7 3.33333 7.33333 3.33333H4C3.63333 3.33333 3.33333 3.63333 3.33333 4V12ZM10 3.33333C9.63333 3.33333 9.33333 3.03333 9.33333 2.66667C9.33333 2.3 9.63333 2 10 2H13.3333C13.7 2 14 2.3 14 2.66667V6C14 6.36667 13.7 6.66667 13.3333 6.66667C12.9667 6.66667 12.6667 6.36667 12.6667 6V4.27333L6.58 10.36C6.32 10.62 5.9 10.62 5.64 10.36C5.38 10.1 5.38 9.68 5.64 9.42L11.7267 3.33333H10Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts" setup>
const props = defineProps<{
  height: number;
}>();
</script>
