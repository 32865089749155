<template>
  <div class="banner-specific-container">
    <div class="icon-container">
      <img
        alt="icône ajouter document"
        class="banner-icon"
        src="@/assets/event/AddDoc.svg"
      />
    </div>
    <div class="content mini">
      <span class="semi-bold">
        {{ notification.payload.authorFirstName }}
        {{ notification.payload.authorLastName }}
      </span>
      <template v-if="notification.payload.authorGroup">
        de
        <span class="semi-bold">
          {{ notification.payload.authorGroup }}
        </span>
      </template>
      a déposé un nouveau projet de
      <span class="semi-bold">
        {{ getAccordCommercialLabelType }}
      </span>
      dans l'opération
      <span class="semi-bold">
        {{ notification.payload.operationName }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Notification } from "@/domain/notification";
import { getPoolAccordCommercialTypeLabel } from "@/utils/poolAccordCommercialUtils";
import { computed } from "vue";

const props = defineProps<{
  notification: Notification;
}>();

const getAccordCommercialLabelType = computed((): string =>
  getPoolAccordCommercialTypeLabel(
    props.notification.payload.accordCommercialType
  ).toLowerCase()
);
</script>

<style lang="scss" scoped>
.banner-specific-container {
  display: flex;
}
</style>
