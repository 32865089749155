export enum MainleveeStatus {
  PROMOTEUR_SENT_TO_BANQUE = "PROMOTEUR_SENT_TO_BANQUE",
  PROMOTEUR_SENT_TO_HOMME_DE_ART = "PROMOTEUR_SENT_TO_HOMME_DE_ART",
  HOMME_DE_ART_SENT_TO_BANQUE = "HOMME_DE_ART_SENT_TO_BANQUE",
  HOMME_DE_ART_SENT_TO_PROMOTEUR = "HOMME_DE_ART_SENT_TO_PROMOTEUR",
  PROMOTEUR_DEMANDE_COMPLEMENTAIRE = "PROMOTEUR_DEMANDE_COMPLEMENTAIRE",
  HOMME_DE_ART_DEMANDE_COMPLEMENTAIRE = "HOMME_DE_ART_DEMANDE_COMPLEMENTAIRE",
  HOMME_DE_ART_INVITED = "HOMME_DE_ART_INVITED",
  COMPLETED = "COMPLETED",
}
