import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import { AnnuaireUser } from "@domain/dto/annuaireUser";
import userApi from "@/api/userApi";
import { toastError } from "@/utils/toastUtils";

type UserOperationStateType = {
  users: AnnuaireUser[];
};
export const useUserOperationStore = defineStore(ModuleType.UserOperation, {
  state: (): UserOperationStateType => ({
    users: [],
  }),
  getters: {
    getOperationMembers(state): AnnuaireUser[] {
      return state.users;
    },
  },
  actions: {
    SetOperationMembers(users: AnnuaireUser[]): void {
      this.users = users;
    },
    ResetOperationMembers(): void {
      this.users = [];
    },
    async fetchOperationMembers(idOperation: string): Promise<void> {
      await userApi
        .fetchAll(idOperation)
        .then((response) => {
          this.SetOperationMembers(response.data);
        })
        .catch(() => {
          toastError(
            "Erreur, il n'y a pas d'utilisateur lié à cette opération"
          );
          this.ResetOperationMembers();
        });
    },
  },
});
