import { AccordCommercialType } from "@domain/enum/accordCommercialType";
import { PoolAccordCommercial } from "@/domain/poolAccordCommercial";
import uniqBy from "lodash/uniqBy";
import { Group } from "@domain/dto/group";
import { PoolOperationParticipant } from "@domain/dto/poolOperationParticipant";
import { PoolFormaliseParticipant } from "@/domain/poolFormaliseParticipant";
import { sum } from "./numberUtils";
import { PoolFormalise } from "@/domain/poolFormalise";

export function getPourcentageParticipationFromList(
  accordList: PoolAccordCommercial[],
  defaultPourcentage: number
): number {
  if (!accordList || accordList.length === 0) {
    return defaultPourcentage;
  }
  const filteredAccordList = uniqBy(accordList, "idParticipant");
  return filteredAccordList.reduce(
    (previous, current) => previous + current.pourcentage,
    0
  );
}

export function getPourcentageParticipationFromListByIdParticipant(
  accordList: PoolAccordCommercial[],
  idParticipant: string,
  defaultPourcentage: number
): number {
  const accordListForParticipant = accordList.filter(
    (accord) => accord.idParticipant === idParticipant
  );
  return getPourcentageParticipationFromList(
    accordListForParticipant,
    defaultPourcentage
  );
}

export function formatPourcentageParticipation(pourcentage: number): string {
  if (pourcentage <= 0 || pourcentage > 100) {
    return "";
  }

  const formattedPourcentage =
    pourcentage < 10
      ? pourcentage.toString().padStart(2, "0")
      : `${pourcentage}`;

  return `- ${formattedPourcentage}%`;
}

export function getPoolAccordCommercialTypeLabel(
  accordCommercialType: AccordCommercialType | undefined
): string {
  if (!accordCommercialType) return "";
  switch (accordCommercialType) {
    case AccordCommercialType.OFFRE_PARTICIPATION:
      return "Offre de participation";
    case AccordCommercialType.CONVENTION_SOUS_PARTICIPATION:
      return "Convention de sous-participation";
    case AccordCommercialType.AUTRE:
      return "Autre document";
    default:
      return "";
  }
}

function getPartRisqueForParticipantInPoolFormalise(
  operationParticipantList: PoolOperationParticipant[],
  idParticipant: string
): number {
  const poolFormaliseParticipant = operationParticipantList.find(
    (operationParticipant) =>
      operationParticipant.participant.id === idParticipant
  );
  if (!poolFormaliseParticipant) {
    return 0;
  }

  return poolFormaliseParticipant.defaultPartRisquePercent;
}

export function getFormatedGroupPourcentage(
  operationParticipantGroupList: PoolOperationParticipant[],
  accordList: PoolAccordCommercial[],
  poolGroup: Group
): string {
  return formatPourcentageParticipation(
    getGroupPourcentage(operationParticipantGroupList, accordList, poolGroup)
  );
}

function getGroupPourcentage(
  operationParticipantGroupList: PoolOperationParticipant[],
  accordList: PoolAccordCommercial[],
  poolGroup: Group
): number {
  return getPourcentageParticipationFromList(
    accordList.filter((accord) => accord.idParticipant === poolGroup.id),
    getPartRisqueForParticipantInPoolFormalise(
      operationParticipantGroupList,
      poolGroup.id
    )
  );
}

export function getFormatedSommeGroupPourcentage(
  operationParticipantGroupList: PoolOperationParticipant[],
  accordList: PoolAccordCommercial[],
  poolGroupList: Group[]
): string {
  let totalPourcentage = 0;
  for (const poolGroup of poolGroupList) {
    totalPourcentage += getGroupPourcentage(
      operationParticipantGroupList,
      accordList,
      poolGroup
    );
  }
  return formatPourcentageParticipation(totalPourcentage);
}

export function computeSommePartRisqueAccordCommerciaux(
  accordCommercialList: PoolAccordCommercial[],
  participantList: PoolOperationParticipant[]
): number {
  const currentPoolGroupIdList = participantList.map(
    (group) => group.participant.id
  );

  const currentAccordCommercialList = accordCommercialList.filter((accord) =>
    currentPoolGroupIdList.includes(accord.idParticipant)
  );

  return getPourcentageParticipationFromList(currentAccordCommercialList, 0);
}

export function computeSommePartRisquePoolFormalise(
  poolFormalise: PoolFormalise
): number {
  return computeSommePartRisqueParticipantList(poolFormalise.participantList);
}

export function computeSommePartRisqueParticipantList(
  participantList: PoolFormaliseParticipant[]
): number {
  return sum(participantList.map((participant) => participant.partsRisque));
}
