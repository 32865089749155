import { apiRequest, ApiResponse } from "@/apiRequest";
import { OperationSuiviCommercialisationResponse } from "@domain/dto/operationSuiviCommercialisationResponse";

export default {
  fetchForOperation(
    idOperation: string
  ): Promise<ApiResponse<OperationSuiviCommercialisationResponse>> {
    return apiRequest.get(
      `/operations/${idOperation}/suivi-commercialisation`,
      {
        validateStatus(status) {
          return status === 200 || status === 409;
        },
      }
    );
  },
};
