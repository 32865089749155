import { LettreAccordStep } from "@domain/enum/lettreAccordStep";
import { LettreAccordHeavy } from "@domain/dto/lettreAccordHeavy";
import { LettreAccordStatus } from "@domain/enum/lettreAccordStatus";
import { LettreAccordSignataireCreateRequest } from "@domain/dto/lettreAccordSignataireCreateRequest";
import { LettreAccordSignataireCreate } from "@domain/dto/lettreAccordSignataireCreate";
import { SignataireHeavy } from "@domain/dto/signataireHeavy";
import { LettreAccordLight } from "@domain/dto/lettreAccordLight";
import { ValidationStatus } from "@domain/enum/validationStatus";
import { SignMethod } from "@domain/enum/signMethod";
import { SignatairePosition } from "@/domain/enum/signatairePosition";
import { SortingOrder } from "@domain/enum/sortingOrder";
import { orderBy } from "lodash";
import { AnnuaireUser } from "@domain/dto/annuaireUser";
import { Role } from "@domain/enum/role";
import { LettreAccordUserRoom } from "@domain/dto/lettreAccordUserRoom";
import { User } from "@domain/dto/user";
import { getGroupRoleFromUser } from "./groupUtils";
import { SignataireType } from "@domain/enum/signataireType";

export function createLettreAccordHeavy(): LettreAccordHeavy {
  return {
    id: "",
    idOperation: "",
    idBanque: "",
    name: "",
    step: LettreAccordStep.CO_CONSTRUCTION,
    status: LettreAccordStatus.CO_CONSTRUCTION,
    createdAt: new Date(),
    updatedAt: new Date(),
    signatairePromoteurCount: 1,
    signataireBanqueCount: 1,
    signataireList: [],
    statusPromoteur: ValidationStatus.UNCHECKED,
    statusBanque: ValidationStatus.UNCHECKED,
    signMethod: SignMethod.UNCHECKED,
    isSignatureOrdered: false,
  };
}

export function statusColor(status: LettreAccordStatus): string {
  switch (status) {
    case LettreAccordStatus.CO_CONSTRUCTION:
      return "yellow";
    case LettreAccordStatus.EN_ATTENTE_DE_SIGNATURE:
      return "orange";
    case LettreAccordStatus.SIGNE:
      return "green";
    case LettreAccordStatus.DECLINED:
      return "red";
  }
}

export function lettreAccordStatusLabel(status: LettreAccordStatus): string {
  switch (status) {
    case LettreAccordStatus.CO_CONSTRUCTION:
      return "Co-construction";
    case LettreAccordStatus.EN_ATTENTE_DE_SIGNATURE:
      return "En attente de signature";
    case LettreAccordStatus.SIGNE:
      return "Signé";
    case LettreAccordStatus.DECLINED:
      return "Refusé";
  }
}

const LettreAccordStepNumber = new Map([
  [LettreAccordStep.CO_CONSTRUCTION, 1],
  [LettreAccordStep.SIGNATURE, 2],
  [LettreAccordStep.DECLINED, 2],
  [LettreAccordStep.COMPLETED, 3],
]);

export function lettreAccordStepToNumber(
  lettreAccordStep: LettreAccordStep
): number {
  const category = LettreAccordStepNumber.get(lettreAccordStep);
  return category || 0;
}

export function buildLettreAccordNewSignataireBanqueCountCreateRequest(
  lettreAccord: LettreAccordHeavy,
  signataireBanqueCount: number
): LettreAccordSignataireCreateRequest {
  const signataireCreateList: LettreAccordSignataireCreate[] =
    lettreAccord.signataireList
      .filter(
        (signataire) =>
          signataireBanqueCount === 2 ||
          (signataireBanqueCount === 1 &&
            signataire.idRecipient !== SignatairePosition.BANQUE_SECOND) ||
          (signataireBanqueCount === 0 &&
            signataire.idRecipient !== SignatairePosition.BANQUE_FIRST &&
            signataire.idRecipient !== SignatairePosition.BANQUE_SECOND)
      )
      .map((signataire) =>
        buildLettreAccordSignataireCreate(lettreAccord, signataire)
      );

  return {
    idLettreAccord: lettreAccord.id,
    signatairePromoteurCount: lettreAccord.signatairePromoteurCount,
    signataireBanqueCount,
    signataireCreateList,
  };
}

export function buildLettreAccordNewSignatairePromoteurCountCreateRequest(
  lettreAccord: LettreAccordHeavy,
  signatairePromoteurCount: number
): LettreAccordSignataireCreateRequest {
  const signataireCreateList: LettreAccordSignataireCreate[] =
    lettreAccord.signataireList
      .filter(
        (signataire) =>
          signatairePromoteurCount === 2 ||
          (signatairePromoteurCount === 1 &&
            signataire.idRecipient !== SignatairePosition.PROMOTEUR_SECOND)
      )
      .map((signataire) =>
        buildLettreAccordSignataireCreate(lettreAccord, signataire)
      );

  return {
    idLettreAccord: lettreAccord.id,
    signatairePromoteurCount,
    signataireBanqueCount: lettreAccord.signataireBanqueCount,
    signataireCreateList,
  };
}

function buildLettreAccordSignataireCreate(
  lettreAccord: LettreAccordHeavy,
  signataire: SignataireHeavy
): LettreAccordSignataireCreate {
  return {
    idLettreAccord: lettreAccord.id,
    idSignataire: signataire.user.id,
    idRecipient: signataire.idRecipient,
    type: SignataireType.INTERNE,
  };
}

export function getLettreAccordListByIdBanque(
  lettreAccordList: LettreAccordLight[]
): { [key: string]: LettreAccordLight[] } {
  const lettreAccordListForBanque: {
    [key: string]: LettreAccordLight[];
  } = {};

  for (const lettreAccord of lettreAccordList) {
    if (!lettreAccordListForBanque[lettreAccord.idBanque]) {
      lettreAccordListForBanque[lettreAccord.idBanque] = [];
    }

    lettreAccordListForBanque[lettreAccord.idBanque].push(lettreAccord);
  }

  for (const idBanque of Object.keys(lettreAccordListForBanque)) {
    lettreAccordListForBanque[idBanque] = orderBy(
      lettreAccordListForBanque[idBanque],
      "createdAt",
      SortingOrder.DESCENDING
    );
  }

  return lettreAccordListForBanque;
}

export function buildLettreAccordSignataireCreateRequest(
  lettreAccord: LettreAccordHeavy,
  newSignataire: AnnuaireUser,
  idRecipient: number
): LettreAccordSignataireCreateRequest {
  const signataireCreateList: LettreAccordSignataireCreate[] =
    lettreAccord.signataireList
      .filter(
        (signataire) =>
          signataire.idRecipient !== idRecipient &&
          signataire.user.id !== newSignataire.id
      )
      .map((signataire) =>
        buildLettreAccordSignataireCreate(lettreAccord, signataire)
      );
  signataireCreateList.push({
    idLettreAccord: lettreAccord.id,
    idSignataire: newSignataire.id,
    idRecipient: idRecipient,
    type: SignataireType.INTERNE,
  });

  return {
    idLettreAccord: lettreAccord.id,
    signatairePromoteurCount: lettreAccord.signatairePromoteurCount,
    signataireBanqueCount: lettreAccord.signataireBanqueCount,
    signataireCreateList,
  };
}

export function lettreAccordStatusColor(status: LettreAccordStatus): string {
  switch (status) {
    case LettreAccordStatus.CO_CONSTRUCTION:
      return "yellow";
    case LettreAccordStatus.EN_ATTENTE_DE_SIGNATURE:
      return "orange";
    case LettreAccordStatus.SIGNE:
      return "green";
    default:
      return "yellow";
  }
}

export function isLettreAccordSigned(lettreAccord: LettreAccordLight): boolean {
  return lettreAccord.status === LettreAccordStatus.SIGNE;
}

export function isLettreAccordSignedInList(
  lettreAccordList: LettreAccordLight[]
): boolean {
  return lettreAccordList.some(
    (lettreAccord) => lettreAccord.status === LettreAccordStatus.SIGNE
  );
}

export function shouldBlockLettreAccordEdition(
  payload: { idLettreAccord: string; role: Role; idUploader: string },
  lettreAccordRoomUserList: LettreAccordUserRoom[],
  userProfile: User
): boolean {
  const myGroupRole = getGroupRoleFromUser(userProfile);

  const myLetttreAccordUserRoom = lettreAccordRoomUserList.find(
    (user) => user.idUser === userProfile.id
  );
  const uploaderLettreAccordUserRoom = lettreAccordRoomUserList.find(
    (user) => user.idUser === payload.idUploader
  );

  if (!myLetttreAccordUserRoom || !uploaderLettreAccordUserRoom) {
    return false;
  }

  const isBanqueOrPromoteurFromMyGroup =
    [Role.PROMOTEUR, Role.BANQUE].includes(myLetttreAccordUserRoom.role) &&
    myGroupRole === payload.role;

  return isBanqueOrPromoteurFromMyGroup;
}
