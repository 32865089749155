import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import documentApi from "@/api/documentApi";
import { computeCollaboraSureteShareLink } from "@/utils/collaboraUtils";
import { User } from "@domain/dto/user";
import { DocumentEditionStatus } from "@/domain/enum/documentEditionStatus";
import { useUserProfileStore } from "./userProfileModule.pinia";
import { getGroupRole } from "@/utils/groupUtils";
import { SureteDocument } from "@domain/dto/sureteDocument";
import { SureteUserRoom } from "@domain/dto/sureteUserRoom";

type SureteDocumentEditStateType = {
  editedDocument: { link: string; document?: SureteDocument };
  userListInSureteRoom: SureteUserRoom[];
  documentEditionBlocked: boolean;
  token?: string;
};

export const useEditSureteDocumentStore = defineStore(
  ModuleType.EditSureteDocument,
  {
    state: (): SureteDocumentEditStateType => ({
      editedDocument: { link: "" },
      userListInSureteRoom: [],
      documentEditionBlocked: false,
      token: undefined,
    }),
    getters: {
      getEditedDocument(state): { link: string; document?: SureteDocument } {
        return state.editedDocument;
      },
      isDocumentEditionBlocked(state): boolean {
        return state.documentEditionBlocked;
      },
      getEditionToken(state): string | undefined {
        return state.token;
      },
      getEditingUserList(state): SureteUserRoom[] {
        return state.userListInSureteRoom;
      },
      getPartnerSideDocumentEditorList(state): SureteUserRoom[] {
        const profile = useUserProfileStore().getUserProfile;
        const myGroupRole = getGroupRole(profile.roles);

        return state.userListInSureteRoom.filter((user) => {
          const isInMyGroup = user.role === myGroupRole;
          return !isInMyGroup && user.isEditing;
        });
      },
      getMySideDocumentEditorList(state): SureteUserRoom[] {
        const profile = useUserProfileStore().getUserProfile;
        const myGroupRole = getGroupRole(profile.roles);

        return state.userListInSureteRoom.filter((user) => {
          const isInMyGroup = user.role === myGroupRole;
          return isInMyGroup && user.isEditing;
        });
      },
    },

    actions: {
      // Mutations
      SetEditedDocumentLink(link: string) {
        this.editedDocument = { ...this.editedDocument, link };
      },

      SetToken(token: string) {
        this.token = token;
      },

      SetEditedDocument(document: SureteDocument) {
        this.editedDocument = { ...this.editedDocument, document };
      },

      SetEditingUsers(users: SureteUserRoom[]) {
        this.userListInSureteRoom = users;
      },

      SetIsDocumentEditionBlocked(value: boolean): void {
        this.documentEditionBlocked = value;
      },

      AddEditingUser(user: SureteUserRoom) {
        const isAlreadyInRoom = this.userListInSureteRoom.some(
          (userRoom) => userRoom.idUser === user.idUser
        );
        if (!isAlreadyInRoom) {
          this.userListInSureteRoom.push(user);
        }
      },

      RemoveEditingUser(idUser: string) {
        this.userListInSureteRoom = this.userListInSureteRoom.filter(
          (userRoom) => userRoom.idUser !== idUser
        );
      },

      UpdateEditingUser(payload: { idUser: string; isEditing: boolean }) {
        const userToUpdate = this.userListInSureteRoom.find(
          (userRoom) => userRoom.idUser === payload.idUser
        );
        if (userToUpdate) {
          userToUpdate.isEditing = payload.isEditing;
        }
      },

      // Actions
      async editSureteDocument(payload: {
        idSocieteSupport: string;
        idSurete: string;
        user: User;
        isBrouillon: boolean;
      }): Promise<void> {
        const editLinkResponse = payload.isBrouillon
          ? await documentApi.sureteBrouillonDocumentEditLink(
              payload.idSocieteSupport,
              payload.idSurete
            )
          : await documentApi.sureteDocumentEditLink(
              payload.idSocieteSupport,
              payload.idSurete
            );

        const { link, token, document } = editLinkResponse.data;
        const buildLink = computeCollaboraSureteShareLink(
          link,
          token,
          payload.idSocieteSupport,
          payload.idSurete,
          payload.user,
          { isBrouillon: payload.isBrouillon }
        );

        this.SetToken(token);
        this.SetEditedDocument(document);
        this.SetEditedDocumentLink(buildLink);
      },

      async checkEditSureteBrouillonDocument(payload: {
        idSocieteSupport: string;
        idSurete: string;
      }): Promise<DocumentEditionStatus> {
        const documentEditionBrouillonStatus =
          await documentApi.sureteDocumentCheckBrouillon(
            payload.idSocieteSupport,
            payload.idSurete
          );
        return documentEditionBrouillonStatus.data;
      },

      async fetchUserEditingDocument(payload: {
        idSocieteSupport: string;
        idSurete: string;
      }): Promise<SureteUserRoom[]> {
        const { data } = await documentApi.fetchUserEditingSureteDocument(
          payload.idSocieteSupport,
          payload.idSurete
        );
        this.SetEditingUsers(data);
        return data;
      },
    },
  }
);
