import { apiRequest, ApiResponse } from "@/apiRequest";
import { HommeDeArtDemandeHeavy } from "@/domain/hommeDeArtDemandeHeavy";
import { HommeDeArtOperation } from "@/domain/hommeDeArtOperation";

export default {
  fetchOperationList(): Promise<ApiResponse<HommeDeArtOperation[]>> {
    return apiRequest.get("/homme-de-art/operations");
  },
  fetchDemandeHeavy(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<HommeDeArtDemandeHeavy>> {
    return apiRequest.get(
      `/homme-de-art/operations/${idOperation}/demandes/${idDemande}`
    );
  },
};
