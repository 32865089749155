<template>
  <TransitionGroup id="toast-wrapper" name="toast-wrapper" tag="div">
    <ToastComponent
      v-for="toast of toastList"
      :key="toast.index"
      :toast="toast"
      :class="[toast.type]"
    />
  </TransitionGroup>
</template>

<script lang="ts">
import { useToastStore } from "@/store/toastModule.pinia";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import ToastComponent from "./ToastComponent.vue";

export default defineComponent({
  components: { ToastComponent },
  setup() {
    const toastStore = useToastStore();
    const { toastList } = storeToRefs(toastStore);
    return {
      // Data
      toastList,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/sass/variables";

#toast-wrapper {
  position: fixed;
  bottom: 15px;
  right: 15px;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-end;
  gap: 4px;

  z-index: 999;
}

.toast-wrapper-enter-active,
.toast-wrapper-leave-active {
  transition: all 0.5s ease;
}
.toast-wrapper-enter-from,
.toast-wrapper-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
