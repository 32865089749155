<template>
  <Modal @close-modal="cancel">
    <template #header>
      <span slot="header">{{ title }}</span>
    </template>
    <template #body>
      <div class="instruction" data-test="confirm-modal-message">
        <span style="white-space: pre-line">
          {{ message }}
        </span>
      </div>
      <slot name="additional-content"></slot>
    </template>
    <template #footer>
      <div>
        <button
          class="secondary"
          data-test="cancel-button"
          @click.stop="cancel"
        >
          Retour
        </button>
        <div v-if="loading" class="spinner-container">
          <Spinner :size="'30px'" class="validate-spinner" />
        </div>
        <button
          v-else
          class="primary"
          data-test="confirm-button"
          @click.stop="confirm"
        >
          Valider
        </button>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import Modal from "@/components/shared/ModalComponent.vue";
import Spinner from "@/components/shared/Spinner.vue";
import { NeomiEvent } from "@/domain/neomiEvent";

import { defineComponent } from "vue";

export default defineComponent({
  name: "ConfirmModal",
  components: {
    Modal,
    Spinner,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  emits: [NeomiEvent.VALIDATED, NeomiEvent.CANCELLED],
  setup(_props, context) {
    function confirm(): void {
      context.emit(NeomiEvent.VALIDATED);
    }

    function cancel(): void {
      context.emit(NeomiEvent.CANCELLED);
    }

    return {
      // Methods
      confirm,
      cancel,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";
@import "@/sass/spinner.scss";
</style>
