import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import { useDemandeStore } from "@/store/demandeModule.pinia";
import { findUserWithoutPhoneNumber } from "@/utils/userUtils";
import { useLettreAccordStore } from "@/store/lettreAccordModule.pinia";
import { useSureteStore } from "@/store/sureteModule.pinia";
import docusignApi from "@/api/docusignApi";

export const useSignatureStore = defineStore(ModuleType.Signature, {
  getters: {
    getDemandeSignataireListWithoutPhoneNumber(): string[] {
      const demandeStore = useDemandeStore();

      const demande = demandeStore.getDemande;
      return findUserWithoutPhoneNumber(demande.signataireList);
    },
    getLettreAccordSignataireListWithoutPhoneNumber(): string[] {
      const lettreAccord = useLettreAccordStore().getLettreAccord;
      return findUserWithoutPhoneNumber(lettreAccord.signataireList);
    },
    getSureteSignataireListWithoutPhoneNumber(): string[] {
      const surete = useSureteStore().getSurete;
      return findUserWithoutPhoneNumber(surete.signataireList);
    },
  },
  actions: {
    async unlockEnvelope(idEnvelope: string): Promise<void> {
      await docusignApi.unlockEnvelope(idEnvelope);
    },
  },
});
