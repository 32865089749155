<template>
  <div class="banner-specific-container">
    <div class="icon-container">
      <img
        alt="icône ajouter document"
        class="banner-icon"
        src="@/assets/event/AddDoc.svg"
      />
    </div>
    <div class="content mini">
      {{ message }}
      <span class="semi-bold" data-test="operation-name">
        {{ notification.payload.operationName }}
      </span>
      !
    </div>
  </div>
</template>

<script lang="ts">
import { Notification } from "@/domain/notification";
import {
  computeMultipleDocumentUploadedNotificationLabel,
  computeSingleDocumentUploadedNotificationLabel,
} from "@/utils/notificationUtils";
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    notification: {
      type: Object as () => Notification,
      required: true,
    },
  },
  setup(props) {
    const message = computed((): string => {
      return props.notification.payload.numberUploadedDocuments &&
        props.notification.payload.numberUploadedDocuments > 1
        ? messageMultipleNotifications.value
        : messageSingleNotification.value;
    });

    const messageSingleNotification = computed((): string => {
      return computeSingleDocumentUploadedNotificationLabel(props.notification);
    });

    const messageMultipleNotifications = computed((): string => {
      return computeMultipleDocumentUploadedNotificationLabel(
        props.notification
      );
    });

    return {
      message,
      messageSingleNotification,
      messageMultipleNotifications,
    };
  },
});
</script>

<style lang="scss" scoped>
.banner-specific-container {
  display: flex;
}
</style>
