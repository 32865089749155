import * as Sentry from "@sentry/vue";

export function range(end: number): number[] {
  return Array.from(Array(end).keys());
}

export function rangeFromStart(start: number, end: number): number[] {
  const array = [];
  for (let i = start; i <= end; i++) {
    array.push(i);
  }
  return array;
}

// n in [0;1]
export function toPercent(n: number): number {
  return Math.floor(n * 100);
}

export function sum(valueList: number[]): number {
  return valueList.reduce((acc, currentValue) => acc + currentValue, 0);
}

export function isNumber(a: number): boolean {
  return Number.isFinite(a);
}

export function convertStringAmountToCentime(euroAmount: string): number {
  const replacedEuroAmount = euroAmount.replace(/\s/g, "").replace(",", ".");
  const number = Number(replacedEuroAmount);
  const truncatedNumber = number.toFixed(2);
  return Number(truncatedNumber.toString().replace(".", ""));
}

export function convertEuroToCentime(
  euroAmount: number,
  isKEuro = false
): number {
  if (isKEuro) {
    euroAmount *= 1000;
  }

  return convertStringAmountToCentime(euroAmount.toString());
}

export function convertCentimeAmountToEuroAmount(
  centimeAmount: number,
  isKEuro = false
): number {
  if (isKEuro) {
    return centimeAmount / 100 / 1000;
  }
  return centimeAmount / 100;
}

export function convertCentimeAmountToEuroLabel(centimeAmount: number): string {
  return `${convertCentimeAmountToEuroAmount(centimeAmount)}`;
}

export function formatNumberAsDecimal(number: number): string {
  return String(number).padStart(2, "0");
}

export function formatCentimeAmountToFrenchString(
  number: number | undefined | null
): string {
  if (!number && number !== 0) {
    return "";
  }
  const euroAmount = number / 100;
  return formatNumberToFrenchString(euroAmount);
}

export function formatNumberToFrenchString(
  number: number | undefined | null
): string {
  if (!number && number !== 0) {
    return "";
  }
  const formattedNumber = new Intl.NumberFormat("fr-FR").format(number);
  return number < 0 ? "-" + formattedNumber.slice(1) : formattedNumber;
}

export function calculateProgressBarPercent(
  currentValue: number,
  maxValue: number
): number {
  // Check to make sure the input is within the expected range
  const MINIMUM_VALUE = 0;
  if (currentValue < MINIMUM_VALUE || currentValue > maxValue) {
    const err = "le nombre de jours restants doit être entre 0 et 15 jours.";
    console.error(err);
    Sentry.captureException(err);

    return 0;
  }

  return Math.floor((-100 / maxValue) * currentValue + 100);
}

export function formatCurrency(value: number): string {
  const formatter = new Intl.NumberFormat("fr-FR", {
    maximumFractionDigits: 1,
  });

  if (value >= 1e6) {
    return `${formatter.format(value / 1e6)} M€`;
  } else if (value >= 1e3) {
    return `${formatter.format(value / 1e3)} k€`;
  } else {
    return `${value} €`;
  }
}
