<template>
  <div class="banner-specific-container">
    <div class="icon-container">
      <img
        alt="icône ajouter document"
        class="banner-icon"
        src="@/assets/event/AddDoc.svg"
      />
    </div>
    <div class="content mini">
      <span class="semi-bold" data-test="user-name-group">
        {{ notificationNameAndGroup }}
      </span>
      a déposé un nouveau
      <span class="semi-bold">projet de {{ demandeCategory }}</span>
      dans la demande
      <span class="semi-bold" data-test="link-demande">{{
        notification.payload.demandeName
      }}</span>
      sur l'opération
      <span class="semi-bold">{{ notification.payload.operationName }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Notification } from "@/domain/notification";
import { toCategory } from "@/utils/notificationUtils";
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    notification: {
      type: Object as () => Notification,
      required: true,
    },
  },
  setup(props) {
    const standardNotificationNameAndGroup = `${props.notification.payload.authorFirstName} ${props.notification.payload.authorLastName} - ${props.notification.payload.authorGroup} `;

    const tiersNotificationNameAndGroup = `${props.notification.payload.authorFirstName} ${props.notification.payload.authorLastName} `;

    const notificationNameAndGroup = computed<string>(() => {
      return props.notification.payload.authorGroup === "group-tiers"
        ? tiersNotificationNameAndGroup
        : standardNotificationNameAndGroup;
    });

    const demandeCategory = computed((): string => {
      const demandeType = props.notification.payload.demandeType;
      return demandeType ? toCategory(demandeType) : "inconnu";
    });

    return {
      notificationNameAndGroup,
      demandeCategory,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";
@import "@/sass/notification.scss";
.banner-specific-container {
  display: flex;
}
</style>
