import config from "@/config";
import { User } from "@domain/dto/user";
import { Role } from "@domain/enum/role";
import {
  getGroupRole,
  getGroupRoleFromUser,
  hasRole,
} from "@/utils/groupUtils";
import { DemandeUtilisateurTiers } from "@/domain/demandeUtilisateurTiers";
import { TiersInvitingGroupRole } from "@/domain/tiersInvitingGroupRole";
import { Group } from "@domain/dto/group";
import { DemandeUserRoom } from "@domain/dto/demandeUserRoom";
import { Document } from "@domain/dto/document";

export function shouldShowEditionModal<T extends Document>(editionDocument: {
  link: string;
  document?: T;
}): boolean {
  return editionDocument.link !== "";
}

export function getEditionLink<T extends Document>(editionDocument: {
  link: string;
  document?: T;
}): string {
  return editionDocument.link;
}

// https://sdk.collaboraonline.com/docs/Step_by_step_tutorial.html?highlight=wopisrc
export function computeCollaboraShareLink(
  originalLink: string,
  token: string,
  idOperation: string,
  idDemande: string,
  user: User,
  options?: {
    isBrouillon: boolean;
    invitingGroupRole?: Role.PROMOTEUR | Role.BANQUE;
  }
): string {
  let editingRoom = "";
  if (
    hasRole(user.roles, Role.PROMOTEUR) ||
    options?.invitingGroupRole === Role.PROMOTEUR
  ) {
    editingRoom = "promoteur";
  } else if (
    hasRole(user.roles, Role.BANQUE) ||
    options?.invitingGroupRole === Role.BANQUE
  ) {
    editingRoom = "banque";
  }

  const WOPISrc = new URL(
    `${config.COLLABORA_BACKEND_URL}/api/operations/${idOperation}/demandes/${idDemande}/project-${editingRoom}/wopi`
  );

  if (options?.isBrouillon === true) {
    WOPISrc.searchParams.set("brouillon", "true");
  }

  const uiDefaultSettings = "UIMode=tabbed;TextSidebar=false";

  const uri = new URL(originalLink);
  uri.searchParams.set("access_token", token);
  uri.searchParams.set("ui_defaults", uiDefaultSettings);
  uri.searchParams.set("lang", "fr");
  uri.searchParams.set("WOPISrc", WOPISrc.toString());

  return uri.toString();
}

export function getTiersInvitingGroupRole(
  operationGroupList: Group[],
  demandeUtilisateurTiersList: DemandeUtilisateurTiers[],
  idUser: string
): TiersInvitingGroupRole | undefined {
  const demandeTiers = demandeUtilisateurTiersList.find(
    (demandeTier) => demandeTier.idUserTiers === idUser
  );
  if (!demandeTiers) {
    return undefined;
  }

  const invitingGroup = operationGroupList.find(
    (group) => group.id === demandeTiers.idInvitingGroup
  );

  if (!invitingGroup) {
    return undefined;
  }

  const isBanqueInvitingGroup = invitingGroup.roles.includes(Role.BANQUE);

  if (isBanqueInvitingGroup) {
    return Role.BANQUE;
  }

  const isPromoteurInvitingGroup = invitingGroup.roles.includes(Role.PROMOTEUR);

  if (isPromoteurInvitingGroup) {
    return Role.PROMOTEUR;
  }

  return undefined;
}

export function shouldBlockDemandeDocumentEdition(
  payload: { idDemande: string; role: Role; idUploader: string },
  demandeRoomUserList: DemandeUserRoom[],
  userProfile: User
): boolean {
  const myGroupRole = getGroupRoleFromUser(userProfile);

  const myDemandeUserRoom = demandeRoomUserList.find(
    (user) => user.idUser === userProfile.id
  );
  const uploaderDemandeUserRoom = demandeRoomUserList.find(
    (user) => user.idUser === payload.idUploader
  );

  if (!myDemandeUserRoom) {
    return false;
  }
  if (!uploaderDemandeUserRoom) {
    return false;
  }

  const isTiersWithSameInvitingGroup =
    myDemandeUserRoom.role === Role.TIERS &&
    myDemandeUserRoom.tiersInvitedBy === uploaderDemandeUserRoom.tiersInvitedBy;
  const isBanqueOrPromoteurFromMyInvitingGroup =
    myDemandeUserRoom.role === Role.TIERS &&
    myDemandeUserRoom.tiersInvitedBy === payload.role;

  const shouldBlockForTiers =
    isTiersWithSameInvitingGroup || isBanqueOrPromoteurFromMyInvitingGroup;

  const isBanqueOrPromoteurFromMyGroup =
    [Role.PROMOTEUR, Role.BANQUE].includes(myDemandeUserRoom.role) &&
    myGroupRole === payload.role;
  const isTiersFromMyInvitingGroup =
    [Role.PROMOTEUR, Role.BANQUE].includes(myDemandeUserRoom.role) &&
    payload.role === Role.TIERS &&
    uploaderDemandeUserRoom.tiersInvitedBy === myGroupRole;

  const shouldBlockForBanqueOrPromoteur =
    isBanqueOrPromoteurFromMyGroup || isTiersFromMyInvitingGroup;

  return shouldBlockForTiers || shouldBlockForBanqueOrPromoteur;
}

export function getMySideEditorUserList(
  demandeRoomUserList: DemandeUserRoom[],
  userProfile: User
): DemandeUserRoom[] {
  const myGroupRole = getGroupRole(userProfile.roles);

  if (myGroupRole === Role.TIERS) {
    const myDemandeUserRoom = demandeRoomUserList.find(
      (user) => user.idUser === userProfile.id
    );

    if (!myDemandeUserRoom) {
      return [];
    }

    return demandeRoomUserList.filter((user) => {
      const isInMyInvitingGroup =
        user.role === myDemandeUserRoom.tiersInvitedBy;
      const isTiersWithSameInvitingGroup =
        user.role === Role.TIERS &&
        user.tiersInvitedBy === myDemandeUserRoom.tiersInvitedBy;
      return (
        (isInMyInvitingGroup || isTiersWithSameInvitingGroup) && user.isEditing
      );
    });
  }

  if ([Role.BANQUE, Role.PROMOTEUR].includes(myGroupRole)) {
    return demandeRoomUserList.filter((user) => {
      const isInMyGroup = user.role === myGroupRole;
      const isTiersInvitedByMyGroup =
        user.role === Role.TIERS && user.tiersInvitedBy === myGroupRole;
      return (isInMyGroup || isTiersInvitedByMyGroup) && user.isEditing;
    });
  }

  return [];
}

export function getPartnerSideEditorUserList(
  demandeRoomUserList: DemandeUserRoom[],
  userProfile: User
): DemandeUserRoom[] {
  const myGroupRole = getGroupRole(userProfile.roles);

  if (myGroupRole === Role.TIERS) {
    const myDemandeUserRoom = demandeRoomUserList.find(
      (user) => user.idUser === userProfile.id
    );

    if (!myDemandeUserRoom) {
      return [];
    }

    return demandeRoomUserList.filter((user) => {
      const isInMyInvitingGroup =
        user.role === myDemandeUserRoom.tiersInvitedBy;
      const isTiersWithSameInvitingGroup =
        user.role === Role.TIERS &&
        user.tiersInvitedBy === myDemandeUserRoom.tiersInvitedBy;
      return (
        !isInMyInvitingGroup && !isTiersWithSameInvitingGroup && user.isEditing
      );
    });
  }

  if ([Role.BANQUE, Role.PROMOTEUR].includes(myGroupRole)) {
    return demandeRoomUserList.filter((user) => {
      const isInMyGroup = user.role === myGroupRole;
      const isTiersInvitedByMyGroup =
        user.role === Role.TIERS && user.tiersInvitedBy === myGroupRole;
      return !isInMyGroup && !isTiersInvitedByMyGroup && user.isEditing;
    });
  }

  return [];
}

export function computeCollaboraESGShareLink(
  originalLink: string,
  token: string,
  idOperation: string,
  idFormDocument: string,
  user: User,
  options?: {
    isBrouillon: boolean;
  }
): string {
  let editingRoom = "";
  if (hasRole(user.roles, Role.PROMOTEUR)) {
    editingRoom = "promoteur";
  } else if (hasRole(user.roles, Role.BANQUE)) {
    editingRoom = "banque";
  }

  const WOPISrc = new URL(
    `${config.COLLABORA_BACKEND_URL}/api/operations/${idOperation}/esg/${idFormDocument}/project-${editingRoom}/wopi`
  );
  if (options?.isBrouillon === true) {
    WOPISrc.searchParams.set("brouillon", "true");
  } else {
    WOPISrc.searchParams.set("userEdited", "true");
  }

  const uiDefaultSettings = "UIMode=tabbed;TextSidebar=false";

  const uri = new URL(originalLink);
  uri.searchParams.set("access_token", token);

  uri.searchParams.set("ui_defaults", uiDefaultSettings);
  uri.searchParams.set("lang", "fr");
  uri.searchParams.set("WOPISrc", WOPISrc.toString());

  return uri.toString();
}

export function computeCollaboraLettreAccordShareLink(
  originalLink: string,
  token: string,
  idOperation: string,
  idLettreAccord: string,
  user: User,
  options?: Partial<{
    isBrouillon: boolean;
  }>
): string {
  let editingRoom = "";
  if (hasRole(user.roles, Role.PROMOTEUR)) {
    editingRoom = "promoteur";
  } else if (hasRole(user.roles, Role.BANQUE)) {
    editingRoom = "banque";
  }

  const WOPISrc = new URL(
    `${config.COLLABORA_BACKEND_URL}/api/operations/${idOperation}/lettre-accord/${idLettreAccord}/project-${editingRoom}/wopi`
  );

  if (options?.isBrouillon === true) {
    WOPISrc.searchParams.set("brouillon", "true");
  }

  const uiDefaultSettings = "UIMode=tabbed;TextSidebar=false";

  const uri = new URL(originalLink);
  uri.searchParams.set("access_token", token);
  uri.searchParams.set("ui_defaults", uiDefaultSettings);
  uri.searchParams.set("lang", "fr");
  uri.searchParams.set("WOPISrc", WOPISrc.toString());

  return uri.toString();
}

export function computeCollaboraSureteShareLink(
  originalLink: string,
  token: string,
  idSocieteSupport: string,
  idSurete: string,
  user: User,
  options?: Partial<{
    isBrouillon: boolean;
  }>
): string {
  let editingRoom = "";
  if (hasRole(user.roles, Role.PROMOTEUR)) {
    editingRoom = "promoteur";
  } else if (hasRole(user.roles, Role.BANQUE)) {
    editingRoom = "banque";
  }

  const WOPISrc = new URL(
    `${config.COLLABORA_BACKEND_URL}/api/societes-support/${idSocieteSupport}/suretes/${idSurete}/project-${editingRoom}/wopi`
  );

  if (options?.isBrouillon === true) {
    WOPISrc.searchParams.set("brouillon", "true");
  }

  const uiDefaultSettings = "UIMode=tabbed;TextSidebar=false";

  const uri = new URL(originalLink);
  uri.searchParams.set("access_token", token);
  uri.searchParams.set("ui_defaults", uiDefaultSettings);
  uri.searchParams.set("lang", "fr");
  uri.searchParams.set("WOPISrc", WOPISrc.toString());

  return uri.toString();
}

export enum PreviewCollaboraType {
  OPERATION_GED = "OPERATION_GED",
  SOCIETE_SUPPORT_GED = "SOCIETE_SUPPORT_GED",
  DEMANDE = "DEMANDE",
  LETTRE_ACCORD = "LETTRE_ACCORD",
  SURETE = "SURETE",
  ACCORD_COMMERCIAL = "ACCORD_COMMERCIAL",
}
export interface ComputeCollaboraShareLinkParams {
  type: PreviewCollaboraType;
  originalLink: string;
  token: string;
  idDocument: string;
  idOperation?: string;
  idSocieteSupport?: string;
  idDemande?: string;
  idLettreAccord?: string;

  idSurete?: string;
  idAccordCommercial?: string;
}
export interface PreviewDocumentParams {
  type: PreviewCollaboraType;
  idDocument: string;
  idOperation?: string;
  idSocieteSupport?: string;
  idDemande?: string;
  idLettreAccord?: string;

  idSurete?: string;
  idAccordCommercial?: string;
}
export function computeCollaboraGenericPreviewShareLink(
  payload: ComputeCollaboraShareLinkParams
): string {
  let WOPISrc: URL = getCollaboraUrl(payload);

  const uiDefaultSettings = "UIMode=tabbed;TextSidebar=false";

  const uri = new URL(payload.originalLink);
  uri.searchParams.set("access_token", payload.token);
  uri.searchParams.set("ui_defaults", uiDefaultSettings);
  uri.searchParams.set("lang", "fr");
  uri.searchParams.set("WOPISrc", WOPISrc.toString());

  return uri.toString();
}

function getCollaboraUrl(payload: ComputeCollaboraShareLinkParams): URL {
  let WOPISrc: URL;
  switch (payload.type) {
    case PreviewCollaboraType.OPERATION_GED:
      WOPISrc = new URL(
        `${config.COLLABORA_BACKEND_URL}/api/operations/${payload.idOperation}/documents/${payload.idDocument}/wopi`
      );
      break;
    case PreviewCollaboraType.SOCIETE_SUPPORT_GED:
      WOPISrc = new URL(
        `${config.COLLABORA_BACKEND_URL}/api/societes-support/${payload.idSocieteSupport}/documents/${payload.idDocument}/wopi`
      );
      break;
    case PreviewCollaboraType.DEMANDE:
      WOPISrc = new URL(
        `${config.COLLABORA_BACKEND_URL}/api/operations/${payload.idOperation}/demandes/${payload.idDemande}/documents/${payload.idDocument}/wopi`
      );
      break;
    case PreviewCollaboraType.LETTRE_ACCORD:
      WOPISrc = new URL(
        `${config.COLLABORA_BACKEND_URL}/api/operations/${payload.idOperation}/lettre-accord/${payload.idLettreAccord}/documents/${payload.idDocument}/wopi`
      );

      break;
    case PreviewCollaboraType.SURETE:
      WOPISrc = new URL(
        `${config.COLLABORA_BACKEND_URL}/api/societes-support/${payload.idSocieteSupport}/suretes/${payload.idSurete}/documents/${payload.idDocument}/wopi`
      );
      break;
    case PreviewCollaboraType.ACCORD_COMMERCIAL:
      WOPISrc = new URL(
        `${config.COLLABORA_BACKEND_URL}/api/operations/${payload.idOperation}/accords-commerciaux/${payload.idAccordCommercial}/documents/${payload.idDocument}/wopi`
      );
      break;
  }
  return WOPISrc;
}
