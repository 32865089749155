<template>
  <div class="banner-specific-container">
    <div class="icon-container">
      <ChartUpIcon />
    </div>
    <div class="content mini">
      <span class="semi-bold">
        {{ notification.payload.authorFirstName }}
        {{ notification.payload.authorLastName }}
        -
        {{ notification.payload.authorGroup }}
      </span>
      a mis à jour le suivi de commercialisation de
      {{ notification.payload.operationCount }}
      {{ operationPluralize }}
    </div>
  </div>
</template>

<script lang="ts">
import { Notification } from "@/domain/notification";
import { computed, defineComponent } from "vue";
import ChartUpIcon from "../icons/ChartUpIcon.vue";
export default defineComponent({
  components: { ChartUpIcon },
  props: {
    notification: {
      type: Object as () => Notification,
      required: true,
    },
  },
  setup(props) {
    const operationPluralize = computed(
      (): string =>
        `opération${(props.notification.payload.operationCount ?? 0) > 0 ? "s" : ""}`
    );

    return {
      operationPluralize,
    };
  },
});
</script>

<style lang="scss" scoped>
.banner-specific-container {
  display: flex;
}
</style>
