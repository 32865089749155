import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import { Notification } from "@/domain/notification";
import useKeycloakStore from "@/store/keycloakModule.pinia";
import { NotificationDto } from "@/domain/dto/notificationDto";
import {
  getUniqueUserIdListFromNotificationList,
  isUserNotificationReferent,
  notificationPayloadHandler,
  playSoundOnNewNotification,
} from "@/utils/notificationUtils";
import { User } from "@domain/dto/user";
import notificationApi from "@/api/notificationApi";
import { ProfileUser } from "@/domain/dto/profileUser";
import userApi from "@/api/userApi";
import { useUserProfileStore } from "./userProfileModule.pinia";
import { useReferentStore } from "./referentModule.pinia";
import uniqBy from "lodash/uniqBy";

type NotificationStateType = {
  notifications: Notification[];
  bannerNotifications: Notification[];
  myOperationNotificationList: Notification[];
  lastClickedNotification?: Notification;
  sessionBeginDate: Date;
};
export const useNotificationStore = defineStore(ModuleType.Notification, {
  state: (): NotificationStateType => ({
    notifications: [],
    bannerNotifications: [],
    myOperationNotificationList: [],
    lastClickedNotification: undefined,
    sessionBeginDate: new Date(),
  }),
  getters: {
    getNotifications(state): Notification[] {
      return state.notifications;
    },

    getMyOperationNotificationList(state): Notification[] {
      return state.myOperationNotificationList;
    },

    getBannerNotifications(state): Notification[] {
      return state.bannerNotifications;
    },

    getLastClickedNotification(state): Notification | undefined {
      return state.lastClickedNotification;
    },
  },
  actions: {
    // Mutation
    SetNotifications(notifications: Notification[]): void {
      this.notifications = notifications;
    },

    AddNotifications(notifications: Notification[]): void {
      this.notifications = uniqBy(
        [...notifications, ...this.notifications],
        "id"
      );
    },

    ResetBannerNotification(): void {
      this.bannerNotifications = [];
    },

    SetBannerNotifications(notifications: Notification[]): void {
      this.bannerNotifications = notifications;
    },

    AddBannerNotifications(notifications: Notification[]): void {
      // dirty hack pour éviter les notifications en doubles dans l'app
      for (const notification of notifications) {
        if (
          !this.bannerNotifications.some(
            (bannerNotification) => bannerNotification.id === notification.id
          )
        )
          this.bannerNotifications.push(notification);
      }
    },

    CloseNotificationBanner(idNotification: string): void {
      this.bannerNotifications = this.bannerNotifications.filter(
        (notification: Notification) => notification.id !== idNotification
      );
    },

    SetNotificationRead(idNotification: string): void {
      const notification = this.notifications.find(
        (notif) => notif.id === idNotification
      );
      if (notification) {
        notification.read = true;
      }
    },

    SetAllNotificationsRead(): void {
      this.notifications.forEach((notification) => {
        notification.read = true;
      });
    },

    DeleteNotification(idNotification: string): void {
      this.notifications = this.notifications.filter(
        (notification) => notification.id !== idNotification
      );
    },

    SetMyOperationNotificationList(notificationList: Notification[]): void {
      this.myOperationNotificationList = notificationList;
    },
    SetLastClickedNotification(notification: Notification): void {
      this.lastClickedNotification = notification;
    },

    // Actions

    async registerLastClickedNotification(
      notification: Notification
    ): Promise<void> {
      this.SetLastClickedNotification(notification);
    },

    async registerNotifications(
      notificationsDto: NotificationDto[]
    ): Promise<void> {
      const notifications = notificationPayloadHandler(notificationsDto);

      const newNotificationList = notifications.filter(
        (notif) =>
          !notif.delivered &&
          this.sessionBeginDate.getTime() < new Date(notif.createdAt).getTime()
      );
      if (newNotificationList.length > 0) {
        this.AddBannerNotifications(newNotificationList);
        const user = useUserProfileStore().getUserProfile;
        if (user.enabledSoundNotification) {
          await playSoundOnNewNotification();
        }
        setTimeout(() => {
          this.ResetBannerNotification();
        }, 5000);
      }

      this.AddNotifications(notifications);
    },

    async markNotificationAsRead(idNotification: string): Promise<void> {
      await notificationApi
        .markNotificationAsRead(idNotification)
        .then(() => this.SetNotificationRead(idNotification));
    },

    async markAllUserNotificationsAsRead(referentOnly: boolean): Promise<void> {
      await notificationApi
        .markAllUserNotificationsAsRead(referentOnly)
        .then(() => {
          if (referentOnly) {
            const user: User = useKeycloakStore().getProfile as unknown as User;
            const notifications: Notification[] = this.getNotifications;
            const markedNotifications = notifications.map((notification) => {
              if (isUserNotificationReferent(user, notification))
                return { ...notification, read: true };
              else {
                return notification;
              }
            });
            const referentMarkedNotification = markedNotifications.filter(
              (notification) => isUserNotificationReferent(user, notification)
            );
            this.SetNotifications(markedNotifications);

            this.SetMyOperationNotificationList(referentMarkedNotification);
          } else {
            this.SetAllNotificationsRead();
          }
        });
    },

    async deleteAllUserNotifications(referentOnly: boolean): Promise<void> {
      await notificationApi
        .deleteAllUserNotifications(referentOnly)
        .then(() => {
          if (referentOnly) {
            const user = useKeycloakStore().getProfile as unknown as User;
            const notifications: Notification[] = this.getNotifications;

            this.SetNotifications(
              notifications.filter(
                (notification) =>
                  !isUserNotificationReferent(user, notification)
              )
            );
            this.SetMyOperationNotificationList([]);
          } else {
            this.SetNotifications([]);
          }
        });
    },

    async deleteNotification(idNotification: string): Promise<void> {
      await notificationApi
        .deleteNotification(idNotification)
        .then(() => this.DeleteNotification(idNotification));
    },

    async fetchProfileImages(): Promise<void> {
      const userIdList = getUniqueUserIdListFromNotificationList(
        this.getNotifications
      ).filter(
        (userId) =>
          !useReferentStore()
            .getUserProfileImageList.map(
              (referent: ProfileUser) => referent.idUser
            )
            .includes(userId)
      );
      if (userIdList.length) {
        const newProfileImageList = await userApi
          .fetchProfileImages(userIdList)
          .then((result) => result.data);
        useReferentStore().AddUserProfileImageList(newProfileImageList);
      }
    },

    async fetchMyOperationNotificationList(): Promise<Notification[]> {
      return notificationApi
        .fetchMyOperationNotificationList()
        .then((result) => {
          const notificationList: Notification[] = result.data.map(
            (notification) => {
              return {
                ...notification,
                payload: JSON.parse(notification.payload),
              };
            }
          );
          this.SetMyOperationNotificationList(notificationList);
          return notificationPayloadHandler(result.data);
        });
    },
  },
});
