import { apiRequest, ApiResponse } from "@/apiRequest";
import { ReportingData } from "@domain/dto/reportingData";

export default {
  fetchReportingData(): Promise<ApiResponse<ReportingData[]>> {
    return apiRequest.get("/reporting");
  },
  fetchReportingDataForPoolParticipant(): Promise<
    ApiResponse<ReportingData[]>
  > {
    return apiRequest.get("/reporting/pool");
  },
};
