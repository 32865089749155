<template>
  <transition name="modal">
    <div v-if="shouldShow" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <div class="carousel">
              <div class="title"><h1>Les nouveautés</h1></div>
              <div class="subtitle">
                Dernière mise à jour : {{ releaseNoteStartDateString }}
              </div>
              <div class="carousel-slide">
                <img
                  :src="releaseImagePath(releaseNoteImageList[currentSlide])"
                  :alt="`${currentSlide}`"
                />
              </div>
              <div
                v-if="currentSlide !== 0"
                class="prev-button"
                @click="prevSlide"
              >
                <ChevronRight
                  class="summary-icon-details"
                  color="tile-details"
                  size="40"
                />
              </div>
              <div
                v-if="currentSlide !== releaseNoteImageList.length - 1"
                class="next-button"
                @click="nextSlide"
              >
                <ChevronRight
                  class="summary-icon-details"
                  color="tile-details"
                  size="40"
                />
              </div>
              <div></div>
              <div class="close" @click="close">
                <CloseIcon size="30" class-color="black" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, Ref } from "vue";
import config from "@/config";
import ChevronRight from "@/components/icons/ChevronRight.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import { getSetupContext } from "@/utils/vueUtils";

export default defineComponent({
  components: { ChevronRight, CloseIcon },

  setup() {
    const { isPromoteur, isBanque } = getSetupContext();

    const currentVersion = config.RELEASE_NOTE_VERSION;
    const releaseNoteStartDateString = config.RELEASE_NOTE_START_DATE;
    const shouldShow: Ref<boolean> = ref(false);
    const lastReleaseNoteSeen = localStorage.getItem("lastReleaseNoteSeen");
    const currentSlide = ref(0);

    if (
      (!lastReleaseNoteSeen ||
        (lastReleaseNoteSeen && lastReleaseNoteSeen !== currentVersion)) &&
      currentVersion &&
      isPromoteur.value
    ) {
      localStorage.setItem("lastReleaseNoteSeen", currentVersion);

      shouldShow.value = true;
    } else {
      shouldShow.value = false;
    }

    const getReleaseNoteImageList = (): string[] => {
      const banqueDir = "banque";
      const promoteurDir = "promoteur";

      const subDir = isPromoteur.value
        ? promoteurDir
        : isBanque.value
          ? banqueDir
          : "";

      const releaseNoteImageCount = isPromoteur.value ? 1 : 0;

      const rnList = [];
      for (let i = 1; i <= releaseNoteImageCount; i++) {
        rnList.push(`${currentVersion}/${subDir}/${i}.png`);
      }

      return rnList;
    };

    const releaseNoteImageList = getReleaseNoteImageList();

    const releaseImagePath = (link: string) => {
      return `/RN/${link}`;
    };

    const nextSlide = () => {
      currentSlide.value =
        (currentSlide.value + 1) % releaseNoteImageList.length;
    };

    const close = () => {
      shouldShow.value = false;
    };

    const prevSlide = () => {
      currentSlide.value =
        (currentSlide.value - 1 + releaseNoteImageList.length) %
        releaseNoteImageList.length;
    };

    return {
      nextSlide,
      prevSlide,
      releaseImagePath,
      close,
      releaseNoteImageList,
      currentSlide,
      shouldShow,
      releaseNoteStartDateString,
      isBanque,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../sass/variables";
@import "../sass/media";
.carousel {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.subtitle {
  color: $grey;
  font-weight: 500;
}
.carousel-slide {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel img {
  height: auto;
  width: 77vw;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  background-color: $white;
  border-radius: 50%;
  color: $white;
  cursor: pointer;
  outline: none;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: $shadow-5;
}

.prev-button {
  left: -45px;
  transform: rotate(180deg);
}

.next-button {
  right: -42px;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.modal-mask {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  max-height: 675px;
  max-width: 1000px;
}

.modal-container {
  background-color: $white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 10px;
  width: 80%;
  max-height: 675px;
  max-width: 1000px;
  .modal-header {
    height: 64px;
    border-bottom: solid 1px $primary-main;
    display: flex;
    align-items: center;
    box-shadow: $shadow-1;

    h5 {
      margin: 0 24px;
    }
  }

  .modal-body {
    padding: 24px;

    .instruction {
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .modal-footer {
    display: flex;
    height: 73px;
    justify-content: flex-end;
    padding: 12px 24px;
    border-top: solid 1px $border-color-3;

    > div {
      display: flex;

      .spinner-container {
        width: 53px;
        display: flex;
        align-content: center;
        justify-content: center;
      }

      button {
        margin: 0 8px;
      }
    }
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.1);
}
</style>
