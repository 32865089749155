import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { NotificationDto } from "@/domain/dto/notificationDto";

export default {
  fetchAll(): Promise<ApiResponse<NotificationDto[]>> {
    return apiRequest.get("/notifications");
  },

  markNotificationAsRead(idNotification: string): Promise<ApiResponse<string>> {
    return apiRequestThrottle.patch(
      `/notifications/markAsRead/${idNotification}`
    );
  },

  markAllUserNotificationsAsRead(referentOnly: boolean) {
    return referentOnly
      ? apiRequestThrottle.patch("/notifications/markAsRead?referent_only=true")
      : apiRequestThrottle.patch("/notifications/markAsRead");
  },

  deleteNotification(idNotification: string) {
    return apiRequest.delete(`/notifications/${idNotification}`);
  },

  deleteAllUserNotifications(referentOnly: boolean) {
    return referentOnly
      ? apiRequest.delete("/notifications?referent_only=true")
      : apiRequest.delete("/notifications");
  },

  fetchMyOperationNotificationList(): Promise<ApiResponse<NotificationDto[]>> {
    return apiRequest.get("/notifications/referent");
  },
};
