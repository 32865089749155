import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import esgApi from "@/api/esgApi";
import { toastError, toastSuccess } from "@/utils/toastUtils";
import { useOperationStore } from "./operationModule.pinia";

export const useESGStore = defineStore(ModuleType.ESG, {
  actions: {
    async initializeESGForm(idOperation: string): Promise<void> {
      esgApi
        .initializeESGForm(idOperation)
        .then((response) => {
          if (response.status === 201) {
            toastSuccess("Formulaire IFPIMM en cours d'initialisation");
          } else {
            toastError("Erreur lors de l'initialisation du formulaire IFPIMM");
          }
        })
        .catch(() => {
          toastError("Erreur lors de l'initialisation du formulaire IFPIMM");
        });
    },
    async askPromoteurForEsgForm(idOperation: string): Promise<void> {
      esgApi
        .askPromoteurForEsgForm(idOperation)
        .then((response) => {
          useOperationStore().fetchOperationDetailsById(idOperation);

          if (response.status === 201) {
            toastSuccess("La demande de formulaire ESG a été envoyé");
          } else {
            toastError("La demande de formulaire ESG n'a pas pu être envoyé");
          }
        })
        .catch(() => {
          toastError("Erreur lors de la demande de formulaire ESG");
        });
    },
  },
});
