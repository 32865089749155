import { apiRequest, ApiResponse } from "@/apiRequest";
import { OperationMessageHeavy } from "@/domain/operationMessageHeavy";
import { OperationRoom } from "@domain/dto/operationRoom";

export default {
  fetchMessageListByOperation(
    idOperation: string
  ): Promise<ApiResponse<OperationMessageHeavy[]>> {
    return apiRequest.get(`/operations/${idOperation}/messages`);
  },
  fetchOperationMessageRoomList(
    idOperation: string
  ): Promise<ApiResponse<OperationRoom[]>> {
    return apiRequest.get(`/operations/${idOperation}/chat-rooms`);
  },
};
