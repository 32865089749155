<template>
  <div
    v-if="getBannerNotifications && getBannerNotifications.length > 0"
    class="notification-banners-container"
  >
    <div
      v-for="notificationBanner in getBannerNotifications"
      :key="notificationBanner.id"
    >
      <NotificationBannerListItem :notification="notificationBanner" />
    </div>
  </div>
</template>

<script lang="ts">
import NotificationBannerListItem from "@/components/notification/NotificationBannerListItem.vue";
import { Notification } from "@/domain/notification";

import { computed, defineComponent } from "vue";
import { getSetupContext } from "@/utils/vueUtils";

export default defineComponent({
  components: {
    NotificationBannerListItem,
  },
  setup(_props) {
    const { notificationStore } = getSetupContext();

    const getBannerNotifications = computed((): Notification[] => {
      return notificationStore.getBannerNotifications;
    });

    return {
      getBannerNotifications,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";

.notification-banners-container {
  position: absolute;
  width: 336px;
  height: auto;
  display: flex;
  flex-direction: column;
  top: 85px;
  right: 70px;
}
</style>
