import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { SuiviTravaux } from "@/domain/suiviTravaux";
import { SuiviTravauxStepCreationRequest } from "@domain/dto/suiviTravauxStepCreationRequest";
import { SuiviTravauxCreationRequest } from "@domain/dto/suiviTravauxCreationRequest";
import { SuiviTravauxStep } from "@/domain/SuiviTravauxStep";
import { SuiviTravauxUpdateRequest } from "@domain/dto/suiviTravauxUpdateRequest";
import { SuiviTravauxConfiguration } from "@domain/dto/suiviTravauxConfiguration";

export default {
  fetchSuiviTravauxByIdOperation(
    idOperation: string
  ): Promise<ApiResponse<SuiviTravaux[]>> {
    return apiRequest.get(`/operations/${idOperation}/suivi-travaux`);
  },

  async createSuiviTravaux(
    idOperation: string,
    suiviTravauxCreationRequest: SuiviTravauxCreationRequest
  ): Promise<ApiResponse<SuiviTravaux[]>> {
    return apiRequestThrottle.post(
      `/operations/${idOperation}/suivi-travaux`,
      suiviTravauxCreationRequest
    );
  },

  async updateSuiviTravaux(
    idOperation: string,
    idSuiviTravaux: string,
    suiviTravauxUpdateRequest: SuiviTravauxUpdateRequest
  ): Promise<ApiResponse<SuiviTravaux>> {
    return apiRequestThrottle.patch(
      `/operations/${idOperation}/suivi-travaux/${idSuiviTravaux}`,
      suiviTravauxUpdateRequest,
      {
        validateStatus(status) {
          return status === 200;
        },
      }
    );
  },

  async deleteSuiviTravaux(
    idOperation: string,
    idSuiviTravaux: string
  ): Promise<ApiResponse<void>> {
    return apiRequest.delete(
      `/operations/${idOperation}/suivi-travaux/${idSuiviTravaux}`,
      {
        validateStatus(status) {
          return status === 200;
        },
      }
    );
  },

  async createSuiviTravauxStep(
    idOperation: string,
    suiviTravauxStepCreationRequest: SuiviTravauxStepCreationRequest
  ): Promise<ApiResponse<SuiviTravauxStep>> {
    return apiRequestThrottle.post(
      `/operations/${idOperation}/suivi-travaux-step`,
      suiviTravauxStepCreationRequest
    );
  },

  async addAttestationSuiviTravauxStep(
    idOperation: string,
    idSuiviStep: string,
    attestationFinTravaux: File
  ): Promise<ApiResponse<unknown>> {
    const formData: FormData = new FormData();
    formData.append("file", attestationFinTravaux);

    return apiRequestThrottle.post(
      `/operations/${idOperation}/suivi-travaux-step/${idSuiviStep}/attestation`,
      formData
    );
  },

  async saveImportConfiguration(
    configuration: SuiviTravauxConfiguration
  ): Promise<ApiResponse<SuiviTravauxConfiguration>> {
    return apiRequestThrottle.post(
      `/suivi-travaux/configuration`,
      configuration
    );
  },

  async findImportConfiguration(): Promise<
    ApiResponse<SuiviTravauxConfiguration>
  > {
    return apiRequest.get(`/suivi-travaux/configuration`);
  },
};
