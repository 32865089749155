import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { User } from "@domain/dto/user";
import { KeycloakUserAttributes } from "@domain/dto/keycloakUserAttributes";
import { ProfileUser } from "@/domain/dto/profileUser";
import { UserCreationRequest } from "@domain/dto/userCreationRequest";
import { UserCreationStatus } from "@domain/dto/userCreationStatus";
import { UserUpdateRequest } from "@domain/dto/userUpdateRequest";
import { UserUpdateAdminRequest } from "@domain/dto/userUpdateAdminRequest";
import { UserAbsence } from "@domain/dto/userAbsence";
import { AnnuaireUser } from "@domain/dto/annuaireUser";
import { UserProfileMetaData } from "@/domain/userProfileMetaData";
import { UserWithAbsence } from "@domain/dto/userWithAbsence";
import { MaintenanceMessageResponse } from "@domain/dto/maintenanceMessageResponse";

export default {
  fetchAll(idOperation: string): Promise<ApiResponse<AnnuaireUser[]>> {
    return apiRequest.get(`/operations/${idOperation}/team`);
  },
  fetchUserProfile(idUser: string): Promise<ApiResponse<UserWithAbsence>> {
    return apiRequest.get(`/profile/${idUser}`);
  },
  updateAttributes(
    idUser: string,
    attributes: KeycloakUserAttributes
  ): Promise<ApiResponse<User>> {
    return apiRequestThrottle.put(
      `/profile/${idUser}?${btoa(JSON.stringify(attributes))}`,
      attributes,
      {}
    );
  },
  updateAbsence(
    idUser: string,
    absence: Partial<UserAbsence>
  ): Promise<ApiResponse<UserAbsence>> {
    return apiRequestThrottle.put(`/profile/${idUser}/absence`, absence);
  },
  fetchUserProfileImage(idImage: string): Promise<ApiResponse<string>> {
    return apiRequest.get(`/profile/image/${idImage}`);
  },

  deleteUserProfileImage(
    idUser: string,
    idImage: string
  ): Promise<ApiResponse<string>> {
    return apiRequest.delete(`/profile/${idUser}/image/${idImage}`);
  },

  updateUserProfileImage(
    idUser: string,
    file: Blob
  ): Promise<ApiResponse<string>> {
    const formData: FormData = new FormData();
    formData.append("profileImage", file);
    return apiRequestThrottle.post(`/profile/image/${idUser}`, formData);
  },

  fetchProfileImages(
    userIdList: string[]
  ): Promise<ApiResponse<ProfileUser[]>> {
    return apiRequestThrottle.post("/profiles/images", {
      userIdList,
    });
  },

  fetchMaintenanceMessageList(): Promise<
    ApiResponse<MaintenanceMessageResponse[]>
  > {
    return apiRequest.get("/users/maintenances");
  },

  addUsers(
    userCreationRequestList: UserCreationRequest[]
  ): Promise<ApiResponse<UserCreationStatus[]>> {
    return apiRequestThrottle.post("users", userCreationRequestList);
  },
  updateUserInformation(
    userUpdateRequest: UserUpdateRequest
  ): Promise<ApiResponse<UserWithAbsence>> {
    return apiRequestThrottle.put(`/users/first-login`, userUpdateRequest);
  },
  adminUpdateUserInformation(
    userUpdateAdminRequest: UserUpdateAdminRequest
  ): Promise<ApiResponse<string>> {
    return apiRequestThrottle.put(`/users`, userUpdateAdminRequest);
  },
  resendEmailInvitation(userId: string): Promise<void> {
    return apiRequestThrottle.post(`users/${userId}/send-invitation-mail`);
  },
  disableUser(userId: string): Promise<void> {
    return apiRequestThrottle.put(`users/disable/${userId}`);
  },
  deleteUser(idUser: string): Promise<void> {
    return apiRequest.delete(`users/${idUser}`);
  },
  fetchUsersByIdList(
    userIdList: string[]
  ): Promise<ApiResponse<UserProfileMetaData[]>> {
    return apiRequest.get("users/meta-data", {
      params: {
        userIdList,
      },
    });
  },
};
